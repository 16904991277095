<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-11 10:17:08
 * @Description: 
-->
<template>
  <div v-if="info?.category_list?.length" class="tl omit3">
    <template v-if="info?.category_list?.length < 3">
      <el-tag
        v-for="(item, index) in info.category_list"
        :key="index"
        class="cp mb4"
      >
        <!-- <el-tooltip :content="item.merge" effect="dark" placement="top">
          <span>
            {{ item.merge }}
          </span>
        </el-tooltip> -->
        {{ item.title }}
      </el-tag>
    </template>
    <template v-else>
      <el-popover
        :persistent="false"
        placement="right"
        trigger="hover"
        :width="300"
      >
        <template #reference>
          <div>
            <el-tag
              v-for="(item, index) in info.category_list"
              :key="index"
              class="cp mb4"
            >
              {{ item.title }}
            </el-tag>
          </div>
        </template>
        <el-table :data="info.categories" max-height="500">
          <el-table-column :label="t('分类')" prop="merge" width="500" />
        </el-table>
      </el-popover>
    </template>
  </div>
  <span v-else>-</span>
</template>

<script>
  // 基础产品车型组件
  import { defineComponent } from 'vue'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'BaseCategory',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
    },
    setup() {
      return {
        t,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
