/*
 * @Author: WGL
 * @Date: 2023-11-30 15:56:58
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-01-26 16:08:51
 * @Description:
 */
import { getRequest, postRequest } from './api'

const MODULE_ALBB = 'albb' // 阿里巴巴
const MODULE_THIRD = 'third-shop' // 阿里巴巴

/**
 *获取阿里巴巴店铺授权链接
 * @param {*} params
 * @returns
 */
export function getShopAuthorizeUrl(type, param = {}) {
  const url = `${MODULE_THIRD}/getAuthorizeUrl`
  const params = {
    type,
    ...param,
  }
  return getRequest(url, params)
}

/**
 * 上架产品
 * @param {*} params
 * @returns
 */
export function albbPushProduct(params = {}) {
  const url = `${MODULE_ALBB}/pushProduct`
  return postRequest(url, params)
}

/**
 * 获取产品规则
 * @param {*} params
 * @returns
 */
export function getAlbbProductRule(params = {}) {
  const url = `${MODULE_ALBB}/getProductRule`
  return getRequest(url, params)
}
/**
 * 获取商品分类
 * @param {*} params
 * @returns
 */
export function getAlbbProductCategoryTree(params = {}) {
  const url = `${MODULE_ALBB}/getCategory`
  return getRequest(url, params)
}

/**
 * 获取通用查询
 * @param {*} params
 * @returns
 */
export function getAlbbApiGeneral(params = {}) {
  const url = `${MODULE_ALBB}/general`
  return postRequest(url, params)
}

/**
 * 分类搜索
 * @param {*} params
 * @returns
 */
export function getAlbbSearchCategory(params = {}) {
  const url = `${MODULE_ALBB}/searchCategory`
  return getRequest(url, params)
}

/**
 * 买家保障服务
 * @param {*} params
 * @returns
 */
export function getAlbbProductGuarantee(params = {}) {
  const url = `${MODULE_ALBB}/getProductGuarantee`
  return getRequest(url, params)
}

/**
 * 获取运费列表
 * @param {*} params
 * @returns
 */
export function getAlbbFreightList(params = {}) {
  const url = `${MODULE_ALBB}/getFreightList`
  return getRequest(url, params)
}

/**
 * 获取关联子组件规则
 * @param {*} params
 * @returns
 */
export function getAlbbProductSubRule(params = {}) {
  const url = `${MODULE_ALBB}/getProductSubRule`
  return postRequest(url, params)
}
/**
 * 获取产品相册
 * @param {*} params
 * @returns
 */
export function getAlbbProductAlbum(params = {}) {
  const url = `${MODULE_ALBB}/getPhotoBank`
  return getRequest(url, params)
}

/**
 * 获取产品相册图片
 * @param {*} params
 * @returns
 */
export function getAlbbProductAlbumPhoto(params = {}) {
  const url = `${MODULE_ALBB}/getPhotoList`
  return getRequest(url, params)
}
