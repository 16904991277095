<template>
  <el-drawer
    v-model="visible"
    destroy-on-close
    direction="ltr"
    :modal="showModal"
    :show-close="true"
    :size="showModal ? '28%' : '28%'"
    :with-header="false"
    @close="handleClose"
  >
    <div
      class="system-user-container rel"
      :class="[showModal ? '' : 'pr15 pt15']"
    >
      <!-- 防止右侧输入框不能聚焦 -->
      <el-input
        ref="hideValRef"
        v-model="hideVal"
        class="hideValRef"
        clearable
        placeholder=""
        size="small"
      />

      <base-table
        ref="refTable"
        :config="tableConfig"
        table-height="72vh"
        @header-action="handleHeaderAction"
        @table-action="handleTableAction"
      >
        <template #table-before>
          <div class="b fs16 pb5">{{ info?.product_info?.title }}</div>
          <div>
            {{ t('客户最新报价') }}：
            <span class="cd b">
              {{ CURRENCY_UNIT[info.currency] }}{{ info.expect_price }}
            </span>
          </div>
        </template>
        <template #operation="{ scope }">
          <el-button
            :disabled="info?.audit_status === 10"
            size="default"
            type="text"
            @click="handleUseProductPirce(scope)"
          >
            {{ t('复用价格') }}
          </el-button>
        </template>
      </base-table>
      <el-button
        v-if="!showModal"
        class="add-product-close-btn"
        :icon="Close"
        link
        @click.stop="handleCloseDrawer"
      />
    </div>
  </el-drawer>
</template>

<script>
  // 客户报价历史
  import {
    defineComponent,
    reactive,
    ref,
    toRefs,
    onMounted,
    computed,
    onUnmounted,
  } from 'vue'
  import { quoteDetailEdit } from '@/api/quote'
  import { CURRENCY_UNIT } from '@/config/variable.config'
  import { Close } from '@element-plus/icons-vue'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'QuoteCustomerHistoryDrawer',
    components: {},
    props: {
      info: { require: true, type: Object, default: () => {} },
      showModal: { type: Boolean, default: false }, // 是否显示遮罩层
    },
    emits: ['use-price', 'close', 'batch-add'],
    setup(props, { emit }) {
      console.log('props :>> QuoteCustomerHistoryDrawer', props)
      const refTable = ref(null)
      const hideValRef = ref(null)
      const MODULE = 'sale-order-detail'
      const state = reactive({
        selectList: [],
        visible: true,
        init: true,
        hideVal: '',
      })
      let focusTimer = null
      const tableData = computed(() => {
        return props.info?.log_list
      })
      // 表格数据
      const tableColumns = [
        {
          label: '时间',
          prop: 'create_time',
        },
        {
          label: '我的报价',
          prop: 'price',
          width: 100,
          // sortable: true,
          valFun: (row) => {
            return `${CURRENCY_UNIT[row.currency]}${row.price}`
          },
        },
        {
          label: '客户报价',
          prop: 'price',
          width: 100,
          // sortable: true,
          valFun: (row) => {
            return `${CURRENCY_UNIT[row.currency]}${row.expect_price}`
          },
        },
        {
          // hide: !props.addNow,
          width: 80,
          label: '操作',
          prop: 'operation',
          fixed: 'right',
        },
      ]

      let tableConfig = reactive({
        headerActions: {
          list: [''],
        },
        tableColumn: tableColumns,
        tableAttr: {
          'row-key': 'id',
        },
        tableEvents: {
          // select: handleTableSelect,
        },
        tableData,
        tableListApi: ``,
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          product_id: props?.info?.product_id,
          customer_id: '',
        },
        tableDeleteParams: {},
        tableButtonNum: 3,
        useField: false,
        tableSearch: {},
        showPagination: false,
      })

      const handleTableAction = (action, row) => {
        console.log('action :>> ', action)
        console.log('row :>> ', row)
        // if (action.code === 'AddProduct') {
        //   handleAddProductReq(row.id)
        // }
      }

      const handleHeaderAction = (action) => {
        console.log('action :>> ', action)
        // if (action.code === 'AddProduct') {
        //   const productIds = []
        //   refTable.value.jwtTableRef.selectionRow.forEach((item) => {
        //     if (!item.added) {
        //       productIds.push(item.id)
        //     }
        //   })
        //   if (productIds.length) {
        //     addProduct(productIds)
        //   }
        // }
      }

      // 复用价格
      const handleUseProductPirce = (row) => {
        console.log('row :>> ', row)
        const params = {
          id: props?.info?.id,
          price: row.expect_price,
        }

        quoteDetailEdit(params).then(() => {
          emit('use-price')
          inputFocus()
        })
      }

      const showDrawer = () => {
        state.visible = true
      }

      const handleCloseDrawer = () => {
        state.visible = false
      }

      const handleClose = () => {
        emit('close')
      }

      const inputFocus = () => {
        console.log('hideValRef.value :>> ', hideValRef.value)
        hideValRef?.value?.focus()
        focusTimer && clearTimeout(focusTimer)
        focusTimer = setTimeout(() => {
          hideValRef?.value?.blur()
        }, 100)
      }
      onMounted(() => {
        setTimeout(() => {
          inputFocus()
        }, 500)
      })
      onUnmounted(() => {
        focusTimer && clearTimeout(focusTimer)
      })
      return {
        CURRENCY_UNIT,
        refTable,
        hideValRef,
        Close,
        tableConfig,
        ...toRefs(state),
        handleTableAction,
        handleHeaderAction,
        showDrawer,
        handleCloseDrawer,
        handleClose,
        handleUseProductPirce,
        t,
      }
    },
  })
</script>

<style lang="scss">
  .hideValRef {
    position: absolute !important;
    top: -100px;
    left: 0;
    width: 10px;
    opacity: 0;
  }
</style>
