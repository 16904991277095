<!--
 * @Author: WGL
 * @Date: 2023-06-19 11:38:40
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-11 09:27:33
 * @Description:
-->
<template>
  <el-popover
    :persistent="false"
    placement="bottom"
    popper-class="upload-img-dialog-wrapper"
    :visible="visible"
    :width="290"
  >
    <template #reference>
      <div class="fxmc cp mw0" @click="visible = true">
        <jwt-icon class="g6" icon="image-add-line" />
        <div v-if="appModel.length" class="cb mw0 wbka">
          {{ t('已上传') }}{{ appModel.length }}{{ t('张图片') }}
        </div>
        <div v-else class="mw0 wbka">{{ t('上传图片') }}</div>
      </div>
    </template>
    <div>
      <div class="g6 fs14">
        {{ t('共') }}{{ appModel.length }}{{ t('张图片') }}
      </div>
      <jwt-upload-image
        v-model="appModel"
        class="upload-img-dialog-wrapper-img"
        :fill-url="fillUrl"
        multiple
        :translate-title="translateTitle"
        :upload-url="uploadUrl"
      />
      <div class="tr">
        <el-button type="primary" @click="handleSubmit">
          {{ t('确定') }}
        </el-button>
      </div>
    </div>
  </el-popover>
</template>

<script>
  import { defineComponent, reactive, toRefs, watchEffect } from 'vue'
  import { fillUrl } from '@/common/js/utils'
  import { translateTitle } from '@/utils/i18n'
  import { uploadUrl } from '@/config/index'
  // import { ElMessage, ElMessageBox } from 'element-plus'
  // 表格数据

  import { t } from '@/utils/i18n'
  export default defineComponent({
    name: 'UploadImg',
    props: {
      imgs: { type: [String, Array], default: '' },
    },
    emits: ['change'],
    setup(props, { emit }) {
      const state = reactive({
        visible: false,
        appModel: '',
      })

      const handleSubmit = () => {
        state.visible = false
        emit('change', state.appModel)
      }

      watchEffect(() => {
        state.appModel = props.imgs
      })

      return {
        uploadUrl,
        ...toRefs(state),
        fillUrl,
        translateTitle,
        handleSubmit,
        t,
      }
    },
  })
</script>

<style lang="scss" sc>
  .upload-img-dialog-wrapper {
    width: 100%;
    $width: 80px;
    .upload-img-dialog-wrapper-img {
      .el-upload-list--picture-card {
        .el-upload--picture-card {
          width: $width;
          height: $width;
        }
        .el-upload-list__item {
          width: $width;
          height: $width;
        }
      }
    }
  }
</style>
