<!--
 * @Author: WGL
 * @Date: 2024-07-22 10:45:33
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-01-17 16:28:01
 * @Description: 
-->
<template>
  <qrcode-vue
    v-if="showImg"
    class="mr4"
    level="H"
    :size="35"
    :value="qrcodeUrl"
    @click="show"
  />
  <el-dialog v-model="showDialog" center :title="title" width="30%">
    <div class="fxmc ptb20 fv">
      <qrcode-vue
        id="qrcodeRef"
        ref="qrcodeRef"
        level="H"
        :size="400"
        :value="qrcodeUrl"
      />
      <div>
        <el-button
          class="mt10"
          size="default"
          type="primary"
          @click="handleDownloadQR"
        >
          下载
        </el-button>
        <el-button
          v-if="showPrint"
          class="mt10"
          size="default"
          type="primary"
          @click="handlePrint"
        >
          打印
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
  import { ref, defineExpose, defineProps, defineEmits, computed } from 'vue'
  import QrcodeVue from 'qrcode.vue'
  import { handlePageDetailQrcode } from '@/utils/business'
  const showDialog = ref(false)
  const qrcodeRef = ref(null)
  const props = defineProps({
    type: { type: String, default: '' },
    title: { type: String, default: '' },
    id: { type: [String, Number], default: '' },
    showImg: { type: Boolean, default: true },
    showPrint: { type: Boolean, default: false },
  })

  const emit = defineEmits(['print'])
  // 生成二维码
  const qrcodeUrl = computed(() => {
    console.log('props :>> ', props)
    const url = handlePageDetailQrcode(props.type, props.id)
    console.log('url :>> ', url)
    return url
  })

  function show() {
    showDialog.value = true
  }
  function hide() {
    showDialog.value = false
  }

  /**
   * 下载二维码
   */
  function handleDownloadQR() {
    const canvas = document.getElementById('qrcodeRef')
    console.log('canvas :>> ', canvas)
    const imgUrl = canvas.toDataURL('image/png')
    // 创建a标签用于下载图片
    const a = document.createElement('a')
    a.href = imgUrl
    a.download = `${props.title}.png`
    document.body.appendChild(a) // 确保 a 标签被添加到 document.body 中
    a.click()
    document.body.removeChild(a)
  }

  /**
   * 打印二维码
   */
  function handlePrint() {
    emit('print')
  }

  defineExpose({
    show: show,
    hide: hide,
  })
</script>
<script>
  export default {
    name: 'BaseDetailQrcode',
  }
</script>
<style scoped></style>
