<template>
  <div class="jwt-drawer-page-wrapper-content">
    <!-- 抽屉 -->
    <el-drawer
      v-bind="drawerConfig.attrs"
      id="jwt-drawer-page-wrapper"
      v-model="drawerConfig.visible"
      append-to-body
      :before-close="handleBeforeClose"
      class="jwt-drawer-page-wrapper"
      destroy-on-close
      :modal="true"
      :with-header="false"
      @close="handleCloseDrawer"
    >
      <template #default>
        <component
          :is="currentComponent"
          ref="componentRef"
          :current-page-drawer-config="currentPageDrawerConfig"
          :info="currentComponentInfo"
          :params="currentComponentParams"
          :ref-componment-url="refComponmentUrl"
          :row="currentComponentRow"
        />
        <el-button
          class="close-btn"
          :icon="CloseBold"
          type="danger"
          @click.stop="handleCloseDrawer"
        />
      </template>
    </el-drawer>
    <slot></slot>

    <!-- 产品添加弹窗 -->
    <AddProductDrawer
      v-if="showAddProductDrawer"
      ref="productDrawer"
      :add-now="addProductDrawerAddNow"
      :info="currentComponentInfo"
      :module="showAddProductDrawer"
      :show-modal="false"
      @add-product="handleAddProductSuccess"
      @batch-add="handleBatchAdd($event, model)"
      @close="handleClose"
    />
    <!-- 历史报价 -->
    <QuoteHistoryDrawer
      v-if="productInfoQuoteHistory?.id"
      ref="quoteHistoryDrawer"
      :info="productInfoQuoteHistory"
      @close="handleCloseQuoteHistory"
      @use-price="handleAddProductSuccess"
    />

    <!-- 产品客户历史报价弹窗 -->
    <QuoteCustomerHistoryDrawer
      v-if="productInfoCustomerQuoteHistory?.id"
      ref="quoteCustomerHistoryDrawer"
      :info="productInfoCustomerQuoteHistory"
      @close="handleCloseQuoteHistory"
      @use-price="handleAddProductSuccess"
    />

    <!-- 价格策略 -->
    <ProductPriceStrategyDrawer
      v-if="pageLeftDrawerInfo?.id"
      ref="refProductPriceStrategyDrawer"
      :info="pageLeftDrawerInfo?.info"
      :params="pageLeftDrawerInfo?.params"
      @close="handleClosePageLeft"
    />
  </div>
</template>

<script>
  import {
    computed,
    defineComponent,
    nextTick,
    reactive,
    ref,
    toRefs,
    watchEffect,
    defineAsyncComponent,
  } from 'vue'
  import { useStore } from 'vuex'
  // import { ElMessageBox } from 'element-plus'
  import { CloseBold } from '@element-plus/icons-vue'
  import { translateTitle } from '@/utils/i18n'

  import AddProductDrawer from '@/componentsBusiness/addProductDrawer.vue' // 添加产品弹窗

  import QuoteHistoryDrawer from '@/views/sale/components/quoteHistoryDrawer.vue' // 历史报价弹窗
  import QuoteCustomerHistoryDrawer from '@/views/sale/components/quoteCustomerHistoryDrawer.vue' // 产品客户历史报价弹窗

  import ProductPriceStrategyDrawer from '@/views/product/components/productPriceStrategyDrawer.vue'
  import {
    PRODUCT_PATH,
    CUSTOMER_PATH,
    SALEORDER_PATH,
    DELIVERYPLAN_PATH,
    TASK_PATH,
    MONITOR_PATH,
    DECISIONAIDS_PATH,
    PRODUCT_ATLAS_PATH,
  } from '@/config/business.config'

  export default defineComponent({
    name: 'PageDetailComponent',
    components: {
      // AddProductDrawer: defineAsyncComponent(() =>
      //   import('@/componentsBusiness/addProductDrawer.vue')
      // ), // 添加产品弹窗
      // QuoteHistoryDrawer: defineAsyncComponent(() =>
      //   import('@/views/sale/components/quoteHistoryDrawer.vue')
      // ), // 历史报价弹窗
      // QuoteCustomerHistoryDrawer: defineAsyncComponent(() =>
      //   import('@/views/sale/components/quoteCustomerHistoryDrawer.vue')
      // ), // 产品客户历史报价弹窗
      AddProductDrawer,
      QuoteHistoryDrawer,
      QuoteCustomerHistoryDrawer,
      ProductPriceStrategyDrawer,
      productDetail: defineAsyncComponent(() =>
        import('@/views/product/components/productDetailDraw.vue')
      ), // 产品
      productAtlaDetail: defineAsyncComponent(() =>
        import('@/views/product/components/productAtlaDetailDraw.vue')
      ), // 产品图册
      productPackageDetail: defineAsyncComponent(() =>
        import('@/views/product/components/productPackageDetailDraw.vue')
      ), // 产品包
      clueDetail: defineAsyncComponent(() =>
        import('@/views/customer/components/clueDetailDraw.vue')
      ), // 线索
      customerDetail: defineAsyncComponent(() =>
        import('@/views/customer/components/customerDetailDraw.vue')
      ), // 客户
      businessDetail: defineAsyncComponent(() =>
        import('@/views/sale/components/businessDetailDraw.vue')
      ), // 商机
      quoteDetail: defineAsyncComponent(() =>
        import('@/views/sale/components/quoteDetailDraw.vue')
      ), //报价单
      orderDetail: defineAsyncComponent(() =>
        import('@/views/sale/components/orderDetailDraw.vue')
      ), //订单
      purchaseDetail: defineAsyncComponent(() =>
        import('@/views/purchase/components/purchaseDetailDraw.vue')
      ), // 采购单
      supplierDetail: defineAsyncComponent(() =>
        import('@/views/purchase/components/supplierDetailDraw.vue')
      ), // 供应商
      supplierDeliveryDetail: defineAsyncComponent(() =>
        import(
          '@/views/repository/supplier/components/supplierDeliveryDetailDraw.vue'
        )
      ), //发货单
      receiveSupplierDeliveryDetail: defineAsyncComponent(() =>
        import(
          '@/views/repository/receiveSupplier/components/supplierDeliveryDetailDraw.vue'
        )
      ), //收货单
      stockOutDetail: defineAsyncComponent(() =>
        import('@/views/repository/supplier/components/stockOutDetailDraw.vue')
      ), // 商品代发
      salesReturnDetail: defineAsyncComponent(() =>
        import(
          '@/views/repository/supplier/components/salesReturnDetailDraw.vue'
        )
      ), // 商品退货
      salesClaimDetail: defineAsyncComponent(() =>
        import(
          '@/views/repository/supplier/components/salesClaimDetailDraw.vue'
        )
      ), // 商品快递索赔
      deliveryPlanDetail: defineAsyncComponent(() =>
        import('@/views/repository/components/deliveryPlanDetailDraw.vue')
      ), //发货计划
      todayPlanDetail: defineAsyncComponent(() =>
        import('@/views/repository/components/todayPlanDetailDraw.vue')
      ), //今日计划
      repositoryInRecordDetail: defineAsyncComponent(() =>
        import('@/views/repository/components/repositoryRecordDetailDraw.vue')
      ), // 出入库
      repositoryOutRecordDetail: defineAsyncComponent(() =>
        import('@/views/repository/components/repositoryRecordDetailDraw.vue')
      ), // 出入库
      cartonsDetailDraw: defineAsyncComponent(() =>
        import('@/views/repository/components/cartonsDetailDraw.vue')
      ), // 箱单
      inventoryDetail: defineAsyncComponent(() =>
        import('@/views/repository/components/inventoryDetailDraw.vue')
      ), // 库存盘点
      transferDetail: defineAsyncComponent(() =>
        import('@/views/repository/components/transferDetailDraw.vue')
      ), // 产品调拨
      assembleDetail: defineAsyncComponent(() =>
        import('@/views/repository/components/assembleDetailDraw.vue')
      ), // 产品组装拆卸
      furnitureOrderDetail: defineAsyncComponent(() =>
        import(
          '@/views/repository/furnitureTrade/components/furnitureOrderDetailDraw.vue'
        )
      ), // 家具发货订单详情
      billPlanDetail: defineAsyncComponent(() =>
        import('@/views/finance/components/billPlanDetailDraw.vue')
      ), // 回款计划
      billDetail: defineAsyncComponent(() =>
        import('@/views/finance/components/billDetailDraw.vue')
      ), // 回款详情
      paymentPlanDetail: defineAsyncComponent(() =>
        import('@/views/finance/components/paymentPlanDetailDraw.vue')
      ), // 付款计划
      invoicePlan: defineAsyncComponent(() =>
        import('@/views/finance/components/invoicePlanDetailDraw.vue')
      ), // 开票计划
      invoiceDetail: defineAsyncComponent(() =>
        import('@/views/finance/components/invoiceDetailDraw.vue')
      ), // 开票详情
      paymentDetail: defineAsyncComponent(() =>
        import('@/views/finance/components/paymentDetailDraw.vue')
      ), // 付款明细
      taskDetail: defineAsyncComponent(() =>
        import('@/views/task/components/taskDetailDrawer.vue')
      ), // 任务详情
      enterpriseDetail: defineAsyncComponent(() =>
        import('@/views/customs/components/enterpriseDetailDraw.vue')
      ), // 海关企业详情
      customsCustomerDetai: defineAsyncComponent(() =>
        import('@/views/customs/components/customsCustomerDetailDraw.vue')
      ), // AB客-海关客户详情
      channelDetail: defineAsyncComponent(() =>
        import('@/views/marketing/components/channelDetailDraw.vue')
      ), // 渠道详情
      competeChannelDetail: defineAsyncComponent(() =>
        import('@/views/marketing/components/competeChannelDetailDraw.vue')
      ), // 竞对渠道详情
      storageVolumeDetail: defineAsyncComponent(() =>
        import('@/views/logsList/storageVolume/components/volumeDetailDraw.vue')
      ), // 仓储体积详情
      bomDetail: defineAsyncComponent(() =>
        import('@/views/factory/bom/components/bomDetailDraw.vue')
      ), // BOM详情
      processDetail: defineAsyncComponent(() =>
        import('@/views/factory/technology/components/processDetailDraw.vue')
      ), // 工序详情
      technologyDetail: defineAsyncComponent(() =>
        import('@/views/factory/technology/components/technologyDetailDraw.vue')
      ), // 工艺详情
      proPlanDetail: defineAsyncComponent(() =>
        import('@/views/factory/production/components/proPlanDetailDraw.vue')
      ), // 生产计划详情
      proOrderDetail: defineAsyncComponent(() =>
        import('@/views/factory/production/components/proOrderDetailDraw.vue')
      ), // 生产工单详情
      proProcessDetail: defineAsyncComponent(() =>
        import(
          '@/views/factory/productionExecute/components/processDetailDraw.vue'
        )
      ), // 生产工序详情
      workTicketDetail: defineAsyncComponent(() =>
        import(
          '@/views/factory/productionExecute/components/workTicketDetailDraw.vue'
        )
      ), // 报工单详情
      matPickDetail: defineAsyncComponent(() =>
        import('@/views/factory/materiel/components/pickingDetailDraw.vue')
      ), // 领料单详情
      entrustOrderDetail: defineAsyncComponent(() =>
        import('@/views/factory/entrust/components/entrustOrderDetailDraw.vue')
      ), // 委外订单详情
      entrustProcessDetail: defineAsyncComponent(() =>
        import('@/views/factory/entrust/components/entrustOrderDetailDraw.vue')
      ), // 委外工序详情
      qcDetail: defineAsyncComponent(() =>
        import('@/views/factory/qualityControl/components/qcDetailDraw.vue')
      ), // 质检详情
      expressDetail: defineAsyncComponent(() =>
        import(
          '@/views/repository/expressDelivery/components/expressDeliveryDetailDraw.vue'
        )
      ), // 快递详情
      periodCustomerDetail: defineAsyncComponent(() =>
        import('@/views/finance/components/periodCustomerDetailDraw.vue')
      ), // 客户账单详情
      deviceDetail: defineAsyncComponent(() =>
        import('@/views/device/components/deviceDetailDraw.vue')
      ), // 模具详情
      mouldGroupDetail: defineAsyncComponent(() =>
        import('@/views/device/components/mouldGroupDetailDraw.vue')
      ), // 模具组详情
      mouldSupplierDetail: defineAsyncComponent(() =>
        import('@/views/device/components/mouldSupplierDetailDraw.vue')
      ), // 模具供应商详情
    },
    props: {},
    emits: ['add-product'],
    setup(props) {
      console.log('props :>> ', props)
      const productDrawer = ref(null)
      const quoteHistoryDrawer = ref(null)
      const quoteCustomerHistoryDrawer = ref(null)
      const refProductPriceStrategyDrawer = ref(null)
      const store = useStore()

      const t = (text) => {
        return translateTitle(text) || text || ''
      }

      // 抽屉弹窗默认属性信息
      const drawerDefaultAttrs = {
        'append-to-body': false,
        'destroy-on-close': true,
        // 'close-delay': 100,
        size: '80%',
      }
      const state = reactive({
        drawerConfig: {
          visible: false,
          attrs: {
            ...drawerDefaultAttrs,
          },
        },
        currentComponent: '',
      })

      const componentRef = ref(null)
      const pageDetailConfig = {
        // 产品
        productDetail: { url: PRODUCT_PATH },
        productAtlaDetail: { url: PRODUCT_ATLAS_PATH },
        productPackageDetail: { url: '/product/productPackageList' },
        // 线索
        clueDetail: { url: '/customer/clueList' },
        // 客户
        customerDetail: { url: CUSTOMER_PATH },
        // 商机
        businessDetail: { url: '/sale/businessOpportunityList' },
        // 报价单
        quoteDetail: { url: '/sale/quoteList' },
        // 订单
        orderDetail: { url: SALEORDER_PATH },
        // 采购单
        purchaseDetail: { url: '/purchase/purchaseOrder' },
        // 供应商
        supplierDetail: { url: '/purchase/supplierList' },
        // 发货单
        supplierDeliveryDetail: { url: '/repository/supplierDelivery' },
        // 收货单
        receiveSupplierDeliveryDetail: { url: '/repository/receiveDelivery' },
        // 商品代发
        stockOutDetail: { url: '/repository/stockOut' },
        // 商品退货
        salesReturnDetail: { url: '/repository/salesReturn' },
        // 商品快递索赔
        salesClaimDetail: { url: '/repository/salesClaim' },
        // 发货计划
        deliveryPlanDetail: { url: DELIVERYPLAN_PATH },
        // 发货计划
        todayPlanDetail: { url: '/repository/todayPlan' },
        // 入库单
        repositoryInRecordDetail: { url: '/repository/repositoryRecord' },
        // 出库单
        repositoryOutRecordDetail: { url: '/repository/repositoryRecord' },
        // 箱单
        cartonsDetailDraw: { url: '/repository/repositoryRecord' },
        // 库存盘点
        inventoryDetail: { url: '/repository/record/inventory' },
        // 产品调拨
        transferDetail: { url: '/repository/record/transfer' },
        // 产品组装拆卸
        assembleDetail: { url: '/repository/record/assemble' },
        // 回款计划
        billPlanDetail: { url: '/finance/plan' },
        // 回款明细
        billDetail: { url: '/finance/receivables' },
        // 付款计划
        paymentPlanDetail: { url: '/finance/paymentPlan' },
        // 付款明细
        paymentDetail: { url: '/finance/paymentDetail' },
        // 开票计划
        invoicePlan: { url: '/finance/invoicePlan' },
        // 开票明细
        invoiceDetail: { url: '/finance/invoice' },
        // 任务
        taskDetail: { url: TASK_PATH },
        // 海关企业详情
        enterpriseDetail: { url: MONITOR_PATH },
        // AB客-海关客户详情
        customsCustomerDetai: { url: DECISIONAIDS_PATH },
        // 渠道详情
        channelDetail: { url: '/marketing/channel' },
        // 竞对渠道详情
        competeChannelDetail: { url: '/marketing/channelCompete' },
        // 仓储体积详情
        storageVolumeDetail: { url: '/logs/storageVolume' },
        // bom详情
        bomDetail: { url: '/factory/bom/index' },
        // 工序详情
        processDetail: { url: '/factory/technology/process' },
        // 工艺详情
        technologyDetail: { url: '/factory/technology/index' },
        // 生产计划详情
        proPlanDetail: { url: '/factory/productionPlan' },
        // 生产工单详情
        proOrderDetail: { url: '/factory/productionOrder' },
        // 生产工序详情
        proProcessDetail: { url: '/factory/execute/process' },
        // 报工单详情
        workTicketDetail: { url: '/factory/execute/workticket' },
        // 领料单详情
        matPickDetail: { url: '/factory/materiel/picking' },
        // 委外单详情
        entrustOrderDetail: { url: '/factory/entrust/order' },
        // 委外单详情
        entrustProcessDetail: { url: '/factory/entrust/process' },
        //发货订单（家具）
        furnitureOrderDetail: { url: '/repository/deliveryOrder' },
        // 质检详情
        qcDetail: { url: '/factory/qc/list' },
        // 快递详情
        expressDetail: { url: '/repository/express' },
        // 账单详情
        periodCustomerDetail: { url: '/finance/reconciliation' },
        // 设备详情
        deviceDetail: { url: '/device/index' },
        // 模具组详情
        mouldGroupDetail: { url: '/device/group' },
        // 模具供应商详情
        mouldSupplierDetail: { url: '/device/supplier' },
      }

      // 添加产品弹窗
      const showAddProductDrawer = computed(
        () => store.getters['sys/showAddProductDrawer']
      )

      // 显示历史订单
      const productInfoQuoteHistory = computed(
        () => store.getters['sys/productQuoteHistory']
      )
      // 显示单个产品客户历史报价
      const productInfoCustomerQuoteHistory = computed(
        () => store.getters['sys/productCustomerQuoteHistory']
      )

      const pageLeftDrawerInfo = computed(
        () => store.getters['sys/pageLeftDrawerInfo']
      )
      // 添加产品弹窗 参数
      const addProductDrawerParams = computed(
        () => store.getters['sys/addProductDrawerParams']
      )

      // 添加产品弹窗 参数
      const addProductDrawerAddSuccess = computed(
        () => store.getters['sys/addProductDrawerAddSuccess']
      )

      // 刷新抽屉弹窗详情
      const refreshDrawerDetail = computed(
        () => store.getters['sys/refreshDrawerDetail']
      )
      console.log('addProductDrawerParams :>> ', addProductDrawerParams)
      // 打开的弹窗数据list
      const openPageDrawerConfigList = computed(
        () => store.getters['sys/openPageDrawerConfigList']
      )

      // 当前打开的数据配置
      const currentPageDrawerConfig = computed(() => {
        return openPageDrawerConfigList.value[
          openPageDrawerConfigList.value.length - 1
        ]
      })

      // 当前打开的数据的key
      const currentComponentName = computed(() => {
        return (
          currentPageDrawerConfig.value?.item?.publicDrawerConfig?.openName ||
          ''
        )
      })

      const detailDrawerSize = computed(
        () => store.getters['sys/detailDrawerSize']
      )
      // 是否立即添加产品
      const addProductDrawerAddNow = computed(() => {
        return addProductDrawerParams?.value?.addNow
      })

      // 当前打开的数据的组件info
      const currentComponentInfo = computed(() => {
        return (
          addProductDrawerParams.value?.info ||
          currentPageDrawerConfig.value?.item?.publicDrawerConfig?.info ||
          currentComponentRow.value ||
          {}
        )
      })
      // 当前打开的数据的组件row
      const currentComponentRow = computed(() => {
        return (
          addProductDrawerParams.value?.row ||
          currentPageDrawerConfig.value?.row ||
          {}
        )
      })
      // 当前打开的数据的组件params
      const currentComponentParams = computed(() => {
        return (
          addProductDrawerParams.value?.params ||
          currentPageDrawerConfig.value?.item?.publicDrawerConfig?.params ||
          {}
        )
      })
      const refComponmentUrl = computed(() => {
        return pageDetailConfig[currentComponentName.value]?.url || ''
      })

      const openDrawer = computed(() => store.getters['sys/hasOpenDrawer'])

      // 恢复弹窗size
      const restoreDrawerSize = () => {
        state.drawerConfig.attrs.size = drawerDefaultAttrs.size
      }
      // 恢复弹窗size
      const setDrawerSize = (size = '61.9%') => {
        state.drawerConfig.attrs.size = size
      }

      watchEffect(() => {
        console.log('watchEffect :>> ', openDrawer.value)
        // console.log('currentPageDrawerConfig :>> ', currentPageDrawerConfig)
        console.log(
          'openPageDrawerConfigList :>> watchEffect',
          openPageDrawerConfigList
        )
        // console.log('currentComponentName :>> ', currentComponentName.value)
        if (openDrawer.value) {
          state.drawerConfig.visible = true
          state.currentComponent = currentComponentName.value
        } else {
          state.drawerConfig.visible = false
          state.currentComponent = ''
        }
        // 产品添加侧边栏
        if (showAddProductDrawer.value) {
          nextTick(() => {
            productDrawer?.value?.showDrawer()
            setDrawerSize()
          })
        } else {
          productDrawer?.value?.handleCloseDrawer()
          restoreDrawerSize()
        }

        // 历史报价单 start
        if (productInfoQuoteHistory?.value?.id) {
          nextTick(() => {
            quoteHistoryDrawer?.value?.showDrawer()
            setDrawerSize()
          })
        } else {
          quoteHistoryDrawer?.value?.handleCloseDrawer()
          restoreDrawerSize()
        }
        // 历史报价单 end

        // 客户产品历史报价 start
        if (productInfoCustomerQuoteHistory?.value?.id) {
          nextTick(() => {
            // console.log(
            //   'quoteCustomerHistoryDrawer :>> ',
            //   quoteCustomerHistoryDrawer,
            //   productInfoCustomerQuoteHistory
            // )
            quoteCustomerHistoryDrawer?.value?.showDrawer()
            setDrawerSize('71.9%')
          })
        } else {
          quoteCustomerHistoryDrawer?.value?.handleCloseDrawer()
          restoreDrawerSize()
        }
        // 客户产品历史报价 end

        // 价格策略 start
        if (pageLeftDrawerInfo?.value?.type === 'priceStrategyDrawer') {
          if (pageLeftDrawerInfo?.value?.id) {
            nextTick(() => {
              refProductPriceStrategyDrawer?.value?.handleOpenDrawer()
              // setDrawerSize('71.9%')
              setDrawerSize('61.9%')
            })
          } else {
            setDrawerSize()
          }
        }
        // 价格策略 end

        // 刷新页面 start
        if (refreshDrawerDetail?.value) {
          nextTick(() => {
            handleRefreshDrawerDetail()
            store.commit('sys/setRefreshDrawerDetail', 0) // 置空
          })
        }
        // 刷新页面 end

        if (detailDrawerSize?.value) {
          setDrawerSize(detailDrawerSize?.value)
        } else {
          restoreDrawerSize()
        }
      })

      const handleCloseDrawer = () => {
        state.drawerConfig.visible = false
        if (state.refreshTable) {
          state.refreshTable = false
        }
        store.commit('sys/setOpenDrawer', false)
        store.commit('sys/clearOpenPageDrawerConfig')
        store.commit('sys/setAddProductDrawerSelect', [])
        handleClose()
      }

      // 关闭左侧添加
      const handleClose = () => {
        store.commit('sys/setShowAddProductDrawer', '')
      }

      // 关闭历史报价单
      const handleCloseQuoteHistory = () => {
        store.commit('sys/setProductQuoteHistory', null)
        store.commit('sys/setProductCustomerQuoteHistory', null)
      }

      // 关闭左侧弹窗
      const handleClosePageLeft = () => {
        store.commit('sys/setPageLeftDrawerInfo', null)
      }

      const handleBeforeClose = () => {
        console.log(
          'handleBeforeClose :>> ',
          t('当前页面可能存在未保存内容，直接离开内容将不被保存')
        )
        handleCloseDrawer()
        // ElMessageBox.confirm(
        //   t('当前页面可能存在未保存内容，直接离开内容将不被保存'),
        //   t('确认要离开本页面吗？'),
        //   {
        //     confirmButtonText: t('确定'),
        //     cancelButtonText: t('取消'),
        //     type: 'warning',
        //   }
        // )
        //   .then(() => {
        //     handleCloseDrawer()
        //   })
        //   .catch(() => {})
      }

      // 左侧添加产品成功
      const handleAddProductSuccess = () => {
        const data = addProductDrawerAddSuccess.value || 0
        store.commit('sys/setAddProductDrawerAddSuccess', data + 1)
      }

      // 刷新详情
      const handleRefreshDrawerDetail = () => {
        console.log('componentRef :>> handleRefreshDrawerDetail', componentRef)
        componentRef?.value?.handleGetDrawerDetail?.()
      }

      return {
        componentRef,
        CloseBold,
        currentComponentInfo,
        currentComponentParams,
        addProductDrawerAddNow,
        refComponmentUrl,
        currentPageDrawerConfig,
        currentComponentRow,
        productDrawer,
        quoteHistoryDrawer,
        productInfoCustomerQuoteHistory,
        quoteCustomerHistoryDrawer,
        showAddProductDrawer,
        productInfoQuoteHistory,
        pageLeftDrawerInfo,
        refProductPriceStrategyDrawer,
        ...toRefs(state),
        handleCloseDrawer,
        handleClose,
        handleCloseQuoteHistory,
        handleBeforeClose,
        handleAddProductSuccess,
        handleClosePageLeft,
      }
    },
  })
</script>

<style lang="scss">
  .jwt-drawer-page-wrapper-content {
    // pointer-events: none;
    // width: 80%;
    // height: 100%;
    .el-overlay {
      // 遮罩层点击穿透
      // pointer-events: none;
      .jwt-drawer-page-wrapper {
        // 内容默认浏览器行为
        // pointer-events: auto;
        overflow: visible !important;
      }
    }
  }

  .jwt-drawer-page-wrapper {
    overflow: visible !important;
    & > .el-drawer__body {
      padding: 0 !important;
    }
    .close-btn {
      position: absolute;
      top: 153px;
      left: -40px;
      // z-index: 10000;
      height: 50px;
      width: 40px;
      font-size: 25px;
    }
  }
</style>
