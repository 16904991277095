<!--
 * @Author: WGL
 * @Date: 2024-05-30 14:43:06
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-08 09:32:58
 * @Description: 
-->
<template>
  <div
    v-infinite-scroll="loadMore"
    v-loading="state.ajaxLoading"
    style="max-height: 80vh; overflow: auto"
  >
    <template v-if="state.listData.length === 0">
      <el-empty />
    </template>
    <el-timeline v-else class="p10" style="max-width: 600px">
      <el-timeline-item
        v-for="(item, index) in state.listData"
        :key="index"
        :color="item.color"
        :hollow="item.hollow"
        :icon="item.icon"
        :size="item.size"
        :timestamp="item.create_time"
        :type="item.type"
      >
        <div class="fxm fxwp">
          <el-avatar :size="30" :src="item.admin_info?.avatar" />
          <el-text class="ml5" type="primary">
            {{ item.admin_info?.name }}
          </el-text>
          <span class="plr2"></span>
          <span>{{ item.content }}</span>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script setup>
  import { reactive, defineProps } from 'vue'
  import { getRequest } from '@/api/api'
  const props = defineProps({
    info: { require: true, type: Object, default: () => {} },
    params: { type: Object, default: () => {} },
  })
  const state = reactive({
    page: 1,
    ajaxLoading: false,
    pageSize: 5,
    listData: [],
    pagination: {
      current_page: 1,
      last_page: 10,
    },
  })
  // 获取操作日志
  function getOperationLog() {
    if (
      state.pagination?.current_page === state.pagination?.last_page ||
      state.ajaxLoading
    )
      return
    const params = {
      per_page: state.pageSize,
      page: state.page,
      module: props.params?.module,
      ref_id: props.info?.id,
      order: 'desc',
      sort: 'id',
    }
    state.ajaxLoading = true
    getRequest('/admin-operation-log/index', params)
      .then((res) => {
        console.log('res :>> ', res)
        state.listData = state.listData.concat(res?.data)
        if (res.current_page != res.last_page) {
          state.page++
        }
        state.pagination = {
          current_page: res.current_page,
          last_page: res.last_page,
          total: res.total,
          per_page: res.per_page,
        }
        state.ajaxLoading = false
      })
      .catch(() => {
        state.ajaxLoading = false
      })
  }
  function loadMore() {
    getOperationLog()
  }
</script>

<style lang="scss" scoped></style>
