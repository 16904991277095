<template>
  <div class="fx fxwp">
    <div
      v-for="(item, index) in selectColorList"
      :key="index"
      class="mr10 mb10"
    >
      <el-popover
        :persistent="false"
        placement="right"
        trigger="click"
        :width="700"
      >
        <template #reference>
          <div class="fxmc">
            <div
              class="fxmc bor plr10 r4px bcf"
              @click="handleColorLeftSelect(item, item.parentIndex)"
            >
              <div
                v-if="item.bg"
                class="wh20 r4px bor mr5"
                :style="{ backgroundColor: item.bg }"
              ></div>
              <input
                v-model="item.text"
                class="w150"
                placeholder="请输入或选择颜色"
              />
            </div>
            <el-button
              link
              type="primary"
              @click="handleDelete(item)"
              v-if="!item.isLast"
            >
              删除
            </el-button>
          </div>
        </template>
        <div class="fx">
          <div class="left bg">
            <div
              v-for="(item, index) in colorConfig"
              :key="index"
              class="fxm ptb10 pl10 pr30 cpd"
              :class="[hoverIndex === index ? 'bcf' : '']"
              @mouseenter="handleColorLeftSelect(item, index)"
            >
              <div
                class="wh20 r4px"
                :style="{ backgroundColor: item.bg }"
              ></div>
              <div class="ml10">{{ item.text }}</div>
            </div>
          </div>
          <div class="right ex">
            <div class="grid-4 pl10">
              <div
                v-for="(item, index) in rightColorList"
                :key="index"
                class="fxm ptb10 cp hover plr5 r4px"
                @click="handleSelectColor(item, index)"
              >
                <div
                  class="wh20 r4px bor"
                  :style="{ backgroundColor: item.bg }"
                ></div>
                <div class="ml5">{{ item.text }}</div>
                <div v-if="item.isSelect" class="c0 bort fs10 r2px plr1 ml2">
                  已选
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref, defineEmits, nextTick } from 'vue'
  import { rgbToHex } from '@/utils/index'
  import { colorConfigDefault } from '../config'
  const emit = defineEmits(['selectColor'])
  const hoverIndex = ref(0)

  const colorConfig = ref([])

  const rightColorList = computed(() => {
    return colorConfig.value[hoverIndex.value]?.child || []
  })

  const selectColorList = computed(() => {
    let result = []
    colorConfig.value.forEach((item) => {
      item.child.forEach((child) => {
        if (child.isSelect) {
          result.push(child)
        }
      })
    })
    result.push({
      isLast: true,
    })
    return result
  })

  function handleColorLeftSelect(item, index) {
    hoverIndex.value = index
  }

  function emitData() {
    nextTick(() => {
      const result = []
      selectColorList.value.forEach((item) => {
        if (!item.isLast) {
          result.push({
            text: item.text,
            rgb: item.hex,
            value: item.value,
            imgUrl: '',
          })
        }
      })
      emit('selectColor', result)
    })
  }
  function handleSelectColor(item, index) {
    console.log('item', item)
    colorConfig.value[hoverIndex.value].child[index].isSelect = !item.isSelect
    emitData()
  }
  function handleDelete(item) {
    const { parentIndex, index } = item
    colorConfig.value[parentIndex].child[index].isSelect = !item.isSelect
    emitData()
  }

  onMounted(() => {
    colorConfig.value = colorConfigDefault.map((item, index) => {
      item.child = item.child.map((child, i) => {
        child.parentIndex = index
        child.index = i
        child.hex = rgbToHex(child.bg)
        child.value = -((index + 1) * 100000 + (i + 1))
        return child
      })
      return item
    })
  })
</script>

<style lang="scss" scoped></style>
