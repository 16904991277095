<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-01-20 11:36:58
 * @Description: 
-->
<template>
  <div>
    <!-- 建议售价 -->
    <span class="b cb">{{ currencyUnit }}{{ allPrice?.price || '-' }}</span>
    /
    <!-- 零售价格 -->
    <span class="b cb">{{ currencyUnit }}{{ allPrice?.ft_price || '-' }}</span>
    <div>
      <!-- 最低售价 -->
      <span class="b cy">
        {{ currencyUnit }}{{ allPrice?.rt_price || '-' }}
      </span>
      /
      <!-- 成本价 -->
      <span class="b cd">{{ currencyUnit }}{{ allPrice?.cost || '-' }}</span>
      <jwt-icon
        v-if="hasPriceStrategy"
        class="c0 ml5 cp"
        icon="stack-fill"
        size="16"
        title="价格策略"
        @click="handleShowPriceStrategy"
      />
    </div>
  </div>
</template>

<script>
  // 基础价格组件
  import { computed, defineComponent, reactive, toRefs } from 'vue'
  import { CURRENCY_UNIT } from '@/config/variable.config'
  import { exchangeCurrency } from '@/utils/business'
  import { useStore } from 'vuex'
  import { PRODUCT_PATH } from '@/config/business.config'

  export default defineComponent({
    name: 'BasePrice',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
      currency: { type: String, default: '' },
      showStrategy: { type: Boolean, default: false },
    },
    setup(props) {
      const state = reactive({})
      const store = useStore()
      // 货币单位
      const currencyUnit = computed(() => {
        const currency = props.currency || props.info?.currency
        return currency ? CURRENCY_UNIT[currency] : ''
      })

      // 当前用户可展示的字段
      const productFieldList = computed(() => {
        // 用户字段显示控制
        const userFieldList = store.getters['sys/userFieldList']
        const userMenusAuthObj = store.getters['routes/menusObj']
        const productUrlId = userMenusAuthObj[PRODUCT_PATH]?.id
        if (productUrlId) {
          return userFieldList[productUrlId] || {}
        }
        return {}
      })
      // console.log('productFieldList :>> ', productFieldList)
      const allPrice = computed(() => {
        if (props.currency) {
          return {
            price: productFieldList.value?.advisePrice
              ? exchangeCurrency(
                  props.info?.currency,
                  props.info?.price,
                  props.currency
                )
              : '*',
            rt_price: productFieldList.value?.rt_price
              ? exchangeCurrency(
                  props.info?.currency,
                  props.info?.rt_price,
                  props.currency
                )
              : '*',
            ft_price: productFieldList.value?.retailPrice
              ? exchangeCurrency(
                  props.info?.currency,
                  props.info?.ft_price,
                  props.currency
                )
              : '*',
            cost: productFieldList.value?.cost
              ? exchangeCurrency(
                  props.info?.currency,
                  props.info?.cost,
                  props.currency
                )
              : '*',
          }
        }
        return {
          price: productFieldList.value?.advisePrice
            ? props.info?.price || ''
            : '*',
          rt_price: productFieldList.value?.rt_price
            ? props.info?.rt_price || ''
            : '*',
          ft_price: productFieldList.value?.retailPrice
            ? props.info?.ft_price || ''
            : '*',
          cost: productFieldList.value?.cost ? props.info?.cost || '' : '*',
        }
      })

      // 是否有价格策略
      const hasPriceStrategy = computed(() => {
        return !!props?.info?.policy_list?.length
      })

      /**
       * @description 显示价格策略
       * @param {Object} info 价格策略信息
       */
      const handleShowPriceStrategy = () => {
        const data = {
          type: 'priceStrategyDrawer',
          id: 1,
          info: props.info,
          params: {
            from: 'productList',
            refIdKey: 'product_id',
            searchKey: 'product_id',
            typeVal: 1,
          },
        }
        store.commit('sys/setPageLeftDrawerInfo', data)
      }

      return {
        CURRENCY_UNIT,
        currencyUnit,
        allPrice,
        productFieldList,
        hasPriceStrategy,
        ...toRefs(state),
        handleShowPriceStrategy,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
