<!--
 * @Author: WGL
 * @Date: 2023-10-31 10:15:08
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-11 09:45:34
 * @Description: 
-->
<template>
  <el-tooltip
    v-if="hasShopAuth"
    content="电商网站"
    effect="dark"
    :persistent="false"
    placement="bottom"
  >
    <a :href="shopUrl" target="_blank">
      <vab-icon icon="global-line" @click="click" />
    </a>
  </el-tooltip>
</template>

<script>
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'VabShopSite',
    setup() {
      const store = useStore()

      const hasShopAuth = computed(() => store.getters['routes/hasShopAuth'])
      const shopUrl = computed(() => {
        const websiteConfing = store.getters['sys/websiteConfing']
        if (websiteConfing.shop_url) {
          return websiteConfing.shop_url
        }
        const webOrigin = window.location.origin
        return webOrigin.replace('erp', 'shop')
      })
      const click = () => {}

      return {
        click,
        hasShopAuth,
        shopUrl,
      }
    },
  })
</script>
