<!--
 * @Author: WGL
 * @Date: 2024-04-25 10:42:28
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-11 09:29:30
 * @Description: 
-->
<template>
  <div>
    <el-popover
      v-if="totalNum"
      :persistent="false"
      placement="top"
      title="库存详情"
      trigger="hover"
      :width="150"
    >
      <template #reference>
        <span class="cp b">{{ totalNum }}</span>
      </template>
      <div>
        <div v-for="(val, key) in info" :key="key">
          <el-text>{{ stockObj[key] }}：</el-text>
          <el-text class="b">{{ val }}</el-text>
        </div>
      </div>
    </el-popover>
    <span v-else class="cp b">{{ totalNum }}</span>
  </div>
</template>

<script>
  // 家具库存
  import { computed, defineComponent } from 'vue'

  // 各个仓库
  const stockObj = {
    stock_1_count: '国内仓',
    stock_2_count: '荔枝角',
    stock_3_count: '深水埗',
  }
  export default defineComponent({
    name: 'BaseFurnitureStock',
    props: {
      info: { type: Object, default: () => {} },
    },
    setup(props) {
      // 库存总数量
      const totalNum = computed(() => {
        let total = 0
        for (let key in props.info) {
          total += props.info[key]
        }
        return total
      })

      return {
        stockObj,
        totalNum,
      }
    },
  })
</script>

<style scoped></style>
