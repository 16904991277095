<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-11 10:19:14
 * @Description: 
-->
<template>
  <div v-if="info?.vehicles?.length" class="w">
    <el-popover
      :persistent="false"
      placement="right"
      trigger="hover"
      :width="600"
    >
      <template #reference>
        <div>
          <template v-for="(item, index) in info.vehicles" :key="index">
            <div v-if="index < 3" class="cp w fxm">
              <div class="ex omit">
                {{ item.vehicle }}
              </div>
              /
              <div v-if="item.start_year || item.end_year" class="w100">
                {{ item.start_year }} - {{ item.end_year }}
              </div>
              <div v-else>-</div>
            </div>
          </template>
        </div>
      </template>
      <el-table :data="info.vehicles" max-height="500">
        <el-table-column :label="t('品牌')" prop="make" width="180" />
        <el-table-column :label="t('车型')" min-width="240" prop="vehicle" />
        <el-table-column :label="t('年份')" prop="year" width="150">
          <template #default="{ row }">
            <el-tag v-if="row.start_year || row.end_year">
              {{ row.start_year }} - {{ row.end_year }}
            </el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </el-popover>
    <!-- </template> -->
  </div>
  <span v-else>-</span>
</template>

<script>
  // 基础产品车型组件
  import { defineComponent } from 'vue'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'BaseVehicles',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
    },
    setup() {
      return {
        t,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
