<!--
 * @Author: WGL
 * @Date: 2023-12-07 10:37:10
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-11 09:42:19
 * @Description: 
-->
<template>
  <el-input-number
    class="input-number-tips"
    :class="inputTipsClass"
    :controls="true"
    controls-position="right"
    :max="999999"
    :min="0"
    :model-value="priceD"
    :placeholder="placeholder"
    :precision="2"
    :step="1"
    @blur="handleInputBlur"
    @change="handleInputChange"
  />
  <el-tooltip
    v-if="showTips"
    :content="tipsText"
    effect="dark"
    :persistent="false"
    placement="top"
  >
    <jwt-icon class="cy ml5 fs18 cp" icon="information-fill" />
  </el-tooltip>
</template>

<script setup>
  import { defineProps, defineEmits, computed } from 'vue'
  import { exchangeCurrency } from '@/utils/business'
  import { CURRENCY_UNIT } from '@/config/variable.config'
  import { useStore } from 'vuex'
  const props = defineProps({
    priceD: { type: [String, Number], default: null }, // 值,
    priceDanger: { type: Boolean, default: false }, // 值,
    row: { type: Object, default: () => {} }, // 行数据
    currency: { type: String, default: null }, // 币种
    type: { type: String, default: 'sale' },
    info: { type: Object, default: () => {} }, // 详情信息
  })
  const emit = defineEmits([
    'update:priceD',
    'update:priceDanger',
    'change',
    'blur',
  ])

  const minPrice = computed(() => {
    if (props.type === 'sale') {
      return exchangeCurrency(
        props.row?.currency,
        props.row?.rt_price,
        props.currency
      )
    } else if (props.type === 'purchase') {
      let price =
        props.info.price_type === 'exw'
          ? +props.row?.exw_price || 0
          : +props.row?.fob_price || 0
      // 含税
      if (props.info?.is_contain_tax) {
        if (props.row?.tax_rate) {
          return (
            +((price * +props.row?.tax_rate) / 100 + price).toFixed(2) ||
            Infinity
          )
        }
        return price || Infinity
      }
      return price || Infinity
    }
    return ''
  })

  function handleCheckPice(val) {
    if (props.type === 'sale') {
      return +val < +minPrice.value
    } else if (props.type === 'purchase') {
      return +val > +minPrice.value
    }
    return false
  }
  // console.log('props BasePriceInput', props)
  const showTips = computed(() => {
    return handleCheckPice(props.priceD)
  })
  const store = useStore()
  // 销售模式
  const saleQuoteMode = computed(() => store.getters['sys/saleQuoteMode'])
  const purchaseQuoteMode = computed(
    () => store.getters['sys/purchaseQuoteMode']
  )

  const tipsText = computed(() => {
    if (props.type === 'sale') {
      return saleQuoteMode.value
        ? '产品报价低于最低售价不可提交,请调整!'
        : `产品报价低于最低售价 ${
            props.currency ? CURRENCY_UNIT[props.currency] : ''
          }${minPrice.value} !`
    } else if (props.type === 'purchase') {
      return purchaseQuoteMode.value
        ? '产品报价高于最低采购价不可提交,请调整!'
        : `'产品报价高于最低采购价 ${
            props.currency ? CURRENCY_UNIT[props.currency] : ''
          }${minPrice.value} !'`
    }
    return ''
  })

  const inputTipsClass = computed(() => {
    let result = ''
    if (showTips.value) {
      if (props.type === 'sale') {
        result = saleQuoteMode.value
          ? 'input-danger-tips'
          : 'input-warning-tips'
      } else if (props.type === 'purchase') {
        result = purchaseQuoteMode.value
          ? 'input-danger-tips'
          : 'input-warning-tips'
      }
    }
    if (props.type === 'sale') {
      if (+props.priceD === +props?.row?.buy_price) {
        // 历史报价
        result += ' input-price-history'
      } else if (+props.priceD === +props?.row?.price) {
        // 建议售价
        result += ' input-price-advise'
      }
    }
    return result
  })

  function handleInputBlur(e) {
    const value = e.target.value
    const belowMinPrice = handleCheckPice(value)
    if (props.type === 'sale') {
      emit('blur', +value, belowMinPrice && saleQuoteMode.value)
    } else if (props.type === 'purchase') {
      emit('blur', +value, belowMinPrice && purchaseQuoteMode.value)
    }
  }

  function handleInputChange(value) {
    emit('update:priceD', +value)
    const belowMinPrice = handleCheckPice(value)
    if (
      (props.type === 'sale' && saleQuoteMode.value) ||
      (props.type === 'purchase' && purchaseQuoteMode.value)
    ) {
      emit('update:priceDanger', belowMinPrice)
    }
  }
</script>
<script>
  export default {
    name: 'BasePriceInput',
  }
</script>
<style lang="scss">
  $warning-color: #ff9900;
  $primary-color: #1890ff;
  $history-color: #67c23a;
  $danger-color: #ff4949;
  .input-number-tips {
    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
    .el-input__wrapper {
      padding-right: 15px !important;
      .el-input__inner {
        min-width: 70px;
      }
    }
    &.input-price-advise {
      .el-input__inner {
        color: $primary-color !important;
        font-size: 16px;
        font-weight: bold;
      }
    }
    &.input-price-history {
      .el-input__inner {
        color: $history-color !important;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .input-danger-tips {
    .el-input {
      .el-input__wrapper {
        box-shadow: 0 0 0 1px $danger-color inset;
        background-color: lighten($danger-color, 30%);
      }
    }
  }
  .input-warning-tips {
    .el-input-number__decrease,
    .el-input-number__increase {
      display: none;
    }
    .el-input {
      .el-input__wrapper {
        box-shadow: 0 0 0 1px $warning-color inset;
        background-color: lighten($warning-color, 30%);
      }
    }
  }
</style>
