<template>
  <vab-icon
    class="fold-unfold"
    :icon="collapse ? 'menu-unfold-line' : 'menu-fold-line'"
    @click="toggleCollapse"
  />
  <el-popover
    :persistent="false"
    placement="bottom-start"
    trigger="hover"
    :width="1050"
  >
    <template #default>
      <div class="box">
        <div class="head">
          <span class="head-title">最近访问</span>
          <span
            v-for="item of recentRoutes"
            :key="item.id"
            class="head-item"
            @click="go2(item.path)"
          >
            {{ item.title }}
          </span>
        </div>
        <div class="body oy" style="max-height: 90vh">
          <div v-for="item of menus" :key="item.title" class="body-box">
            <div class="body-box-title">
              <vab-icon :icon="item.icon" />
              {{ item.title }}
            </div>
            <div class="body-box-content">
              <div
                v-for="child of item.children"
                :key="child.title"
                class="body-box-content-item"
                @click="go2(child.path)"
              >
                <div>{{ child.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #reference>
      <a class="overview">
        {{ t('总览') }}
        <vab-icon icon="arrow-down-s-line" @click="toggleCollapse" />
      </a>
    </template>
  </el-popover>
</template>

<script>
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'VabFold',
    setup() {
      const recentRoutes =
        JSON.parse(localStorage.getItem('recent-routes')) || []
      const store = useStore()
      const router = useRouter()
      const routes = computed(() =>
        store.getters['routes/routes'].filter(
          (_) => _ && _.children && _.children.length
        )
      )

      const menus = computed(() => {
        const _menus = []
        const loopChildren = (arr, cs) => {
          if (arr && arr.length) {
            for (let index = 0; index < arr.length; index++) {
              const { children, meta, path } = arr[index]
              if (children && children.length) {
                loopChildren(children, cs)
              } else {
                cs.push({ ...meta, path })
              }
            }
          }
          return cs
        }

        for (let index = 0; index < routes.value.length; index++) {
          const { meta, children, path } = routes.value[index]
          const item = { ...meta, path, children: loopChildren(children, []) }
          _menus.push(item)
        }
        return _menus
      })

      const go2 = (path) => {
        router.push({ path })
      }

      return {
        recentRoutes,
        menus,
        collapse: computed(() => store.getters['settings/collapse']),
        toggleCollapse: () => store.dispatch('settings/toggleCollapse'),
        go2,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .fold-unfold {
    color: #fff7e9;
    // color: var(--el-color-grey);
    cursor: pointer;
  }
  .overview {
    color: white;
    margin-left: -10px;
    margin-right: 15px;
    border: 1px solid white;
    padding: 1px 3px;
    border-radius: 3px;
    cursor: pointer;
    i {
      margin-left: -3px;
    }
  }
  .box {
    .head {
      margin: 5px 0 25px 0;
      &-title {
        font-size: 16px;
        color: #7d7c7c;
      }
      &-item {
        margin-left: 15px;
        color: #333;
        cursor: pointer;
        &:hover {
          color: #1746a2;
        }
      }
    }
    .body {
      display: flex;
      flex-wrap: wrap;
      &-box {
        // width: 150px;
        margin-bottom: 20px;
        margin-right: 20px;
        &-title {
          font-size: 18px;
          color: #7d7c7c;
          font-weight: bold;
        }
        &-content {
          width: 180px;
          height: 240px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          &-item {
            // padding-left: 12px;
            margin-top: 10px;
            cursor: pointer;
            color: #333;
            &:hover {
              color: #1746a2;
            }
          }
        }
      }
    }
  }
</style>
