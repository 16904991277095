<!--
 * @Author: WGL
 * @Date: 2023-06-19 09:06:21
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-11 09:45:24
 * @Description: 
-->
<template>
  <el-popover
    v-if="listData.length"
    :persistent="false"
    placement="bottom"
    trigger="hover"
    :width="240"
  >
    <div>
      <p class="tc fs14">{{ t('正在导出的文件') }}</p>
      <ul>
        <li v-for="(item, index) in listData" :key="index">
          <div class="omit">{{ item.fileName }}</div>
          <el-progress :percentage="item.percentage" />
        </li>
      </ul>
    </div>
    <template #reference>
      <el-badge :value="listData.length">
        <vab-icon icon="download-fill" />
      </el-badge>
    </template>
  </el-popover>
  <el-tooltip
    v-else
    :content="t('导出历史')"
    effect="dark"
    :persistent="false"
    placement="top"
  >
    <vab-icon icon="download-fill" @click="handleDownloadList" />
  </el-tooltip>
</template>

<script>
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    toRefs,
    watchEffect,
    onUnmounted,
  } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'

  import request from '@/utils/request'
  import { download } from '@/common/js/utils'
  import { t } from '@/utils/i18n'
  export default defineComponent({
    name: 'VabDownloadFile',
    setup() {
      const store = useStore()
      const router = useRouter()
      let timer = null
      // 正在导出的文件队列
      const asynExportFileList = computed(
        () => store.getters['sys/asynExportFileList']
      )
      const asynExportFileObj = computed(() => {
        const obj = {}
        asynExportFileList.value.forEach((item) => {
          obj[item.id] = item
        })
        return obj
      })

      const state = reactive({
        listData: [],
      })

      // 获取下载的任务
      const handleGetDownloadFile = () => {
        request({
          url: `/export-task/index`,
          // url: `/index/inspection`,
          method: 'get',
          params: {
            sort: 'id',
            order: 'desc',
            // 'status|<': 10,
            per_page: 100,
          },
        }).then((res) => {
          const result = res?.data?.filter((item) => {
            item.percentage = ((item.curr * 100) / item.total).toFixed(2)
            item.fileName = item?.title || ''
            if (asynExportFileObj.value[item.id]) {
              if (item.status === 10) {
                download(item.file_info.file_path, item.file_info.file_path, '')
                store.dispatch('sys/deleteAsynExportFileList', item.id)
              } else {
                return true
              }
            }
          })
          if (result.length) {
            if (timer) {
              clearTimeout(timer)
            }
            timer = setTimeout(() => {
              handleGetDownloadFile()
            }, 1000 * 3)
          }
          state.listData = result
        })
      }

      watchEffect(() => {
        if (asynExportFileList.value?.length) {
          handleGetDownloadFile()
        }
      })

      const handleDownloadList = () => {
        router.push({
          path: '/logs/export',
        })
      }

      onMounted(() => {})

      onUnmounted(() => {
        if (timer) {
          clearTimeout(timer)
        }
      })
      return {
        ...toRefs(state),
        t,
        handleGetDownloadFile,
        handleDownloadList,
      }
    },
  })
</script>
