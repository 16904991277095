<!--
 * @Author: WGL 958897276@qq.com
 * @Date: 2025-01-26 14:32:36
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-05 16:13:54
 * @FilePath: /erp-admin/src/componentBase/AlbbPublishProduct/components/photoAlbum.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-dialog
    v-model="dialogVisible"
    append-to-body
    center
    destroy-on-close
    :title="t('图片选择')"
    top="5vh"
    width="705"
  >
    <div>
      <div class="fxmj">
        <jwt-select-server
          v-if="dialogVisible"
          v-model="selectAlbum"
          v-bind="albmAttrs"
          @change="handleSelectAlbum"
        />
        <el-button type="primary" link>
          <a
            href="https://picman.1688.com/album/album_list.htm"
            target="_blank"
          >
            管理相册
          </a>
        </el-button>
      </div>
      <div v-if="albumPhotos.length">
        <div class="fx ptb10 fxwp">
          <div
            v-for="(item, index) in albumPhotos"
            :key="index"
            class="bor m5 fxmc r4px ovh rel"
            @click="handleSelectPhoto(item, index)"
          >
            <el-image
              :src="item.path"
              fit="contain"
              style="width: 100px; height: 100px"
            />
            <JwtMaterialItemMask
              v-if="item.url"
              :index="index"
              :item="item"
              @preview-img="
                (index, item) => handlePreviewImg(index, item, 'list')
              "
            />
          </div>
        </div>
        <div class="fx fxmr">
          <el-pagination
            v-model:current-page="pagination.pageNo"
            layout="total, prev, pager, next"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <el-empty v-else :description="t('暂无内容')" />
      <div class="ptb10">
        <div class="fxmj">
          <div>
            {{ t('要插入的图片') }}(
            <span class="cd">{{ checkedPhotosList.length }}</span>
            /{{ maxNum }})
          </div>
          <el-button
            :disabled="!checkedPhotosList.length"
            size="small"
            type="primary"
            @click="handleUseSelected"
          >
            {{ t('使用选中图片') }}
          </el-button>
        </div>
        <div class="ptb10">
          <VueDraggable
            v-model="checkedPhotosList"
            animation="150"
            class="fx ptb10 fxwp"
            ghost-class="ghost"
          >
            <div
              v-for="(item, index) in checkedPhotosList"
              :key="index"
              class="bor m5 fxmc r4px ovh rel"
            >
              <el-image
                :src="item.path"
                fit="contain"
                style="width: 100px; height: 100px"
              />
              <JwtMaterialItemMask
                v-if="item.url"
                :index="index"
                :item="item"
                :show-checked="false"
                show-delete
                @delete="handleDeletePhoto"
                @preview-img="
                  (index, item) => handlePreviewImg(index, item, 'select')
                "
              />
            </div>
          </VueDraggable>
        </div>
      </div>
      <!-- 图片预览 -->
      <el-image-viewer
        v-if="dialogPreviewVisible"
        :initial-index="previewImgIndex"
        :url-list="previewSrcList"
        :z-index="9999"
        @close="handleCloseImgViewer"
      />
    </div>
  </el-dialog>
</template>

<script setup>
  import {
    ref,
    defineProps,
    defineExpose,
    defineEmits,
    computed,
    nextTick,
    reactive,
  } from 'vue'
  import { ElMessage } from 'element-plus'
  import { VueDraggable } from 'vue-draggable-plus'
  import { getAlbbProductAlbum, getAlbbProductAlbumPhoto } from '@/api/third'
  import { t } from '@/utils/i18n'

  const props = defineProps({
    shopId: { require: true, type: [String, Number], default: '' },
    maxNum: { type: [String, Number], default: 5 }, // 图片数量限制
  })
  const emit = defineEmits(['use-selected'])

  console.log('props :>> PhotoAlbumRef', props)
  const dialogVisible = ref(false)
  const selectAlbum = ref('') // 相册选择
  const dialogPreviewVisible = ref(false)
  const previewImgIndex = ref(0)
  const previewImgType = ref('list') // 预览类型
  const albumList = ref([])
  const albumPhotos = ref([])
  const checkedPhotosList = ref([])
  const pagination = reactive({
    pageNo: 1,
    pageSize: 12,
    total: 0,
  })

  const albmAttrs = computed(() => {
    return {
      clearable: true,
      filterable: true,
      //   listApi: `/albb/getPhotoBank`,
      searchKey: 'title',
      placeholder: '请选择相册',
      // alwaysAdd: true,
      valueType: '',
      // emitSelect: true,
      noPaging: true,
      labelKey: 'name',
      valueKey: 'albumID',
      options: albumList.value,
      exParams: {
        sort: 'title',
        order: 'asc',
        shop_id: props.shopId,
      },
    }
  })

  const previewSrcList = computed(() => {
    if (previewImgType.value === 'list') {
      return albumPhotos.value.map((item) => {
        return item.path
      })
    } else if (previewImgType.value === 'select') {
      return checkedPhotosList.value.map((item) => {
        return item.path
      })
    }
    return []
  })
  /**
   * 获取相册列表
   */
  function handleGetAlbbProductAlbum() {
    getAlbbProductAlbum({
      shop_id: props.shopId,
    }).then((res) => {
      albumList.value = res.albumInfos
      setTimeout(() => {
        const defaultAlbum = res.albumInfos.at(-1).albumID
        handleSelectAlbum(defaultAlbum)
      }, 500)
    })
  }

  function handleGetAlbbProductAlbumPhoto() {
    getAlbbProductAlbumPhoto({
      shop_id: props.shopId,
      albumID: selectAlbum.value,
      pageNo: pagination.pageNo,
      pageSize: pagination.pageSize,
    }).then((res) => {
      pagination.total = res.count
      // https://cbu01.alicdn.com/img/ibank/O1CN01oAkvQb2BraoTINOkZ_!!2215935598392-0-cib.summ.jpg
      albumPhotos.value = res.photoInfos.map((item) => {
        return {
          ...item,
          checked: checkedPhotosList.value.some((v) => v.id === item.id),
          path: `https://cbu01.alicdn.com/${item.url}`,
        }
      })
    })
  }
  /**
   * 关闭弹窗
   */
  function handleCloseDialog() {
    dialogVisible.value = false
  }
  /**
   * 打开弹窗
   */
  function handleOpenDialog() {
    dialogVisible.value = true
    handleGetAlbbProductAlbum()
  }
  /**
   * 选择相册
   * @param val
   */
  function handleSelectAlbum(val) {
    console.log('handleSelectAlbum :>> ', val)
    selectAlbum.value = val
    pagination.pageNo = 1
    nextTick(() => {
      handleGetAlbbProductAlbumPhoto()
    })
  }

  function handlePreviewImg(index, item, type) {
    console.log('index :>> ', index)
    console.log('item :>> ', item)
    console.log('type :>> ', type)
    dialogPreviewVisible.value = true
    previewImgIndex.value = index
    previewImgType.value = type
  }

  //  选中图片
  function handleSelectPhoto(item, index, prevent = true) {
    if (
      prevent &&
      !item.checked &&
      checkedPhotosList.value.length >= props.maxNum
    ) {
      ElMessage.error(`${t('图片选择超出最大数量')}${props.maxNum}`)
      return
    }
    const { id } = item
    const findIndex = checkedPhotosList.value.findIndex((v) => v.id === id)
    if (findIndex === -1) {
      checkedPhotosList.value.push(item)
    } else {
      checkedPhotosList.value.splice(findIndex, 1)
    }
    albumPhotos.value = albumPhotos.value.map((item) => {
      if (item.id === id) {
        item.checked = !item.checked
      }
      return item
    })
  }

  //  删除图片
  function handleDeletePhoto(index, item) {
    handleSelectPhoto(item, index, false)
  }

  /**
   * 切换页码
   * @param val
   */
  function handleCurrentChange(val) {
    pagination.pageNo = val
    nextTick(() => {
      handleGetAlbbProductAlbumPhoto()
    })
  }

  function handleCloseImgViewer() {
    dialogPreviewVisible.value = false
  }
  // 使用选择图片
  function handleUseSelected() {
    console.log('handleUseSelected', checkedPhotosList.value)
    emit('use-selected', checkedPhotosList.value)
    handleCloseDialog()
  }

  defineExpose({
    close: handleCloseDialog,
    open: handleOpenDialog,
  })
</script>

<style scoped></style>
