/*
 * @Author: WGL
 * @Date: 2023-06-16 11:10:02
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-11 09:51:18
 * @Description:
 */
import store from '@/store'
import { isKey } from '@/utils/validate'
import { t } from '@/utils/i18n'
import { isObject, cloneDeep } from 'lodash-es'

import { handleGetShortDate } from '@/utils/business'
// import { ElMessage } from 'element-plus'

import {
  AUDIT_STATUS,
  BUSINESS_OPPORTUNITY_STAGE,
  BUSINESS_OPPORTUNITY_END_STAGE,
  BUSINESS_TYPE,
  ORDER_AUDIT_STATUS,
  ORDER_PAY_STATUS,
  ORDER_DELIVERY_STATUS,
  PERFORMANCE_TYPE,
  PRODUCT_SOURCE,
  // NORMAL_STATUS,
  PAY_TYPE,
  QUOTE_STATUS,
  QUOTE_AUDIT_STATUS,
  REPOSITORY_RECEIPT_STATUS,
  REPOSITORY_LOG_TYPE,
  ROLE_VISIBLE_RANGE,
  BILL_PLAN_STATUS,
  INVOICE_PLAN_STATUS,
  PRIORITY_TYPE,
  TASK_STATUS,
  SHOP_PRODUCT_STATUS,
  ARTICLE_TYPE,
  COMPANY_STATUS,
  REVENUE_RANGE,
  EMPLOYEE_RANGE,
  FEEDBACK_TYPE,
  PRODUCT_SALE_CHANNEL_SUB_TYPE,
  PRODUCT_PROMOTION_CHANNEL_SUB_TYPE,
  YES_NO,
  STAFF_STATUS,
  SUPPLIER_SALE_RETURN_STATUS,
  SALE_ORDER_DETAIL_STATUS,
  CUSTOMER_PUBLIC,
  PRODUCT_NATURE,
  PRODUCTION_PLAN_STATUS,
  PRODUCTION_PLAN_PRIORITY,
  PRODUCTION_RECEIPT_STATUS,
  ENTRUST_STATUS,
  PRODUCTION_RECEIPT_PROCESS_STATUS,
  PRINTER_TYPE,
  MHL_HONGKONG_DISTRICT,
  MHL_DELIVERY_PLAN_STATUS,
  BILL_SHEET_TYPE,
  CLUE_END_STAGE,
  CURRENCY,
  ORDER_STOCK_STATUS,
  EQUIPMENT_GROUP_SAMPLE_STATUS,
  EQUIPMENT_GROUP_PRIORITY,
  EQUIPMENT_GROUP_STATUS,
  productSearchConfig,
  labelSearchConfig,
  // THIRD_SHOP_TYPE
} from '@/config/variable.config'
import { objToArr } from '@/utils/index'
import dayjs from 'dayjs'
const localAreaTree = SS.get('areaTree')

// 通用搜索
export const commonFiledObj = {
  // 标题
  title: {
    type: 'input',
    key: 'title',
    labelAttrs: {
      label: '标题',
    },
    formAttrs: {
      class: 'w',
      clearable: true,
      filterable: true,
      placeholder: '请输入标题',
    },
  },
  // 产品
  product: {
    type: 'selectServer',
    key: 'product_id',
    labelAttrs: {
      label: '产品名称',
    },
    formAttrs: {
      class: 'mw150 w',
      clearable: true,
      filterable: true,
      multiple: true,
      listApi: `/product/index`,
      searchKey: 'title',
      placeholder: '请搜索/选择产品',
      exParams: {
        nature: 0,
      },
    },
  },
  // 产品
  productSigle: {
    type: 'selectServer',
    key: 'product_id',
    labelAttrs: {
      label: '产品名称',
    },
    formAttrs: {
      class: 'mw150 w',
      clearable: true,
      filterable: true,
      listApi: `/product/index`,
      searchKey: 'title',
      placeholder: '请搜索/选择产品',
      exParams: {
        nature: 0,
      },
    },
  },
  // 仓库
  repository: {
    type: 'cascaderServer',
    key: 'repository_id',
    value: '',
    labelAttrs: {
      label: '仓库',
    },
    formAttrs: {
      placeholder: '请选择仓库',
      clearable: true,
      filterable: true,
      multiple: false,
      showAllLevels: true,
      listApi: '/repository/tree',
      exParams: {
        sort: 'title',
        order: 'asc',
      },
      props: {
        label: 'title',
        value: 'id',
        checkStrictly: true,
        multiple: false,
      },
    },
  },
  // 分类
  category_ids: {
    type: 'cascaderServer',
    key: 'category_ids',
    value: '',
    labelAttrs: {
      label: '产品分类',
    },
    formAttrs: {
      placeholder: '请选择产品分类',
      clearable: true,
      filterable: true,
      multiple: true,
      class: 'w',
      'show-all-levels': true,
      listApi: '/category/tree',
      props: {
        label: 'title',
        value: 'id',
        checkStrictly: false,
        multiple: true,
      },
    },
  },
  // 分类
  category_id: {
    type: 'cascaderServer',
    key: 'category_id',
    value: '',
    labelAttrs: {
      label: '产品分类',
    },
    formAttrs: {
      placeholder: '请选择产品分类',
      clearable: true,
      filterable: true,
      multiple: false,
      class: 'w',
      'show-all-levels': true,
      listApi: '/category/tree',
      props: {
        label: 'title',
        value: 'id',
        checkStrictly: true,
        multiple: false,
      },
    },
  },
  // 负责人
  owner_admin: {
    type: 'selectServer',
    key: 'owner_admin_id',
    labelAttrs: {
      label: '负责人',
    },
    formAttrs: {
      class: 'mw150 w',
      listApi: `/admin/index`,
      searchKey: 'name',
      labelKey: 'name',
      placeholder: '请搜索/选择负责人',
    },
  },
  // 负责人
  main_admin: {
    type: 'selectServer',
    key: 'main_admin_id',
    labelAttrs: {
      label: '负责人',
    },
    formAttrs: {
      class: 'mw150 w',
      listApi: `/admin/index`,
      searchKey: 'name',
      labelKey: 'name',
      placeholder: '请搜索/选择负责人',
    },
  },
  // 审核状态
  audit_status: {
    type: 'select',
    key: 'audit_status',
    labelAttrs: {
      label: '审核状态',
    },
    formAttrs: {
      placeholder: '请选择审核状态',
      clearable: true,
    },
    options: objToArr(AUDIT_STATUS),
  },
  // 创建人
  create_admin_id: {
    type: 'selectServer',
    key: 'create_admin_id',
    labelAttrs: {
      label: '创建人',
    },
    formAttrs: {
      class: 'mw150 w',
      listApi: `/admin/index`,
      searchKey: 'name',
      labelKey: 'name',
      placeholder: '请搜索/选择创建人',
    },
  },
  // 编辑人
  edit_admin_id: {
    type: 'selectServer',
    key: 'edit_admin_id',
    labelAttrs: {
      label: '编辑人',
    },
    formAttrs: {
      class: 'mw150 w',
      listApi: `/admin/index`,
      searchKey: 'name',
      labelKey: 'name',
      placeholder: '请搜索/选择最后编辑人',
    },
  },
  // 生产计划状态
  pro_plan_status: {
    type: 'select',
    key: 'status',
    value: '',
    labelAttrs: {
      label: '状态',
    },
    formAttrs: {
      searchKey: 'title',
      clearable: true,
      placeholder: '请选择状态',
    },
    options: objToArr(PRODUCTION_PLAN_STATUS),
  },
  // 供应商
  supplier_id: {
    type: 'selectServer',
    key: 'supplier_id',
    labelAttrs: {
      label: '供应商',
    },
    formAttrs: {
      class: 'mw150 w',
      listApi: `/supplier/index`,
      valueType: 'number',
      multiple: false,
      searchKey: 'search',
      placeholder: '请搜索/选择供应商',
    },
  },
  // 价格
  price: {
    type: 'inputNumber',
    key: 'price',
    value: null,
    labelAttrs: {
      label: '价格',
    },
    formAttrs: {
      class: 'w',
      placeholder: '请输入价格',
      clearable: true,
    },
  },
  // 币种
  currency: {
    type: 'select',
    key: 'currency',
    value: '',
    labelAttrs: {
      label: '货币币种',
    },
    formAttrs: {
      placeholder: '请选择币种',
      class: 'w',
    },
    options: objToArr(CURRENCY, false),
  },
  // 数量
  num: {
    type: 'inputNumber',
    key: 'num',
    value: null,
    labelAttrs: {
      label: '数量',
    },
    formAttrs: {
      class: 'w',
      placeholder: '请输入数量',
      clearable: true,
    },
  },
  // 优先级
  priority: {
    type: 'select',
    key: 'priority',
    value: '',
    labelAttrs: {
      label: '优先级',
    },
    formAttrs: {
      placeholder: '请选择生产优先级',
      clearable: true,
    },
    options: objToArr(PRODUCTION_PLAN_PRIORITY),
  },
  // 创建时间
  create_time: {
    type: 'datePickerRange',
    key: 'date',
    startKey: 'create_time|>=',
    endKey: 'create_time|<=',
    value: [],
    labelAttrs: {
      label: '创建时间',
    },
    formAttrs: {
      class: 'w280',
      type: 'daterange',
      placeholder: '请选择开始日期',
      format: 'YYYY-MM-DD',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      // clearable: true,
    },
  },
  // 数据范围
  visible: {
    type: 'select',
    key: 'visible',
    value: '',
    labelAttrs: {
      label: '范围',
    },
    formAttrs: {
      // class: 'w150',
      searchKey: 'title',
      placeholder: '请选择数据范围',
    },
    options: objToArr(ROLE_VISIBLE_RANGE),
  },
  // 生产工单状态
  pro_order_status: {
    type: 'select',
    key: 'status',
    value: '',
    labelAttrs: {
      label: '状态',
    },
    formAttrs: {
      searchKey: 'title',
      clearable: true,
      placeholder: '请选择生产状态',
    },
    options: objToArr(PRODUCTION_RECEIPT_STATUS),
  },
  // 委外状态
  entrust_status: {
    type: 'select',
    key: 'status',
    value: '',
    labelAttrs: {
      label: '状态',
    },
    formAttrs: {
      searchKey: 'title',
      clearable: true,
      placeholder: '请选择委外状态',
    },
    options: objToArr(ENTRUST_STATUS),
  },
  // 工序状态
  process_status: {
    type: 'select',
    key: 'status',
    value: '',
    labelAttrs: {
      label: '状态',
    },
    formAttrs: {
      searchKey: 'title',
      clearable: true,
      placeholder: '请选择工序状态',
    },
    options: objToArr(PRODUCTION_RECEIPT_PROCESS_STATUS),
  },
  // 三方店铺
  thirdShopStore: {
    type: 'selectServer',
    key: 'channel_id',
    labelAttrs: {
      label: '店铺',
    },
    formAttrs: {
      listApi: `/channel/mayPullList`,
      labelKey: 'title',
      searchKey: 'title',
      placeholder: '请选择三方店铺',
      // exLabelKeyFun: (item) => {
      //   return `${item.title}（${THIRD_SHOP_TYPE
      //   [item.type]}）`
      // }
    },
  },
  // 关联状态
  relevance_status: {
    type: 'select',
    key: 'product_id',
    value: '',
    labelAttrs: {
      label: '关联状态',
    },
    formAttrs: {
      clearable: true,
      placeholder: '请选择关联状态',
    },
    options: [
      { label: '未关联', value: 0 },
      { label: '已关联', value: 1 },
    ],
  },
  // 电子面单模板
  expressSheet: {
    type: 'selectServer',
    key: 'express_sheet_id',
    labelAttrs: {
      label: '面单模板',
    },
    formAttrs: {
      listApi: `/express-sheet/index`,
      labelKey: 'title',
      searchKey: 'title',
      placeholder: '请选择电子面单模板',
      exParamsFun: (row) => {
        return row?.id
          ? {
              company_code: row.express_com,
            }
          : {}
      },
      exLabelKeyFun: (item) => {
        return `${item.title || ''} - ${item.company_name}`
      },
    },
  },
  // 快递打印类型
  printType: {
    type: 'select',
    key: 'printType',
    value: 'NON',
    labelAttrs: {
      label: '打印类型',
    },
    formAttrs: {
      clearable: false,
      placeholder: '请选择打印类型',
    },
    options: [
      { label: '只下单不打印', value: 'NON' },
      { label: '生成图片短链', value: 'IMAGE' },
      { label: '快递100云打印机', value: 'CLOUD' },
      { label: '生成html短链', value: 'HTML' },
    ],
  },
  printDevice: {
    type: 'selectServer',
    key: 'printer_id',
    labelAttrs: {
      label: '打印机',
    },
    formAttrs: {
      listApi: `/printer/index`,
      labelKey: 'title',
      searchKey: 'title',
      clearable: false,
      placeholder: '请选择打印机',
      defaultSelect: true,
      emitSelect: true,
      refSearchKey: 'type', // 搜索数据的key
      refKey: 'template_id', // 关联数据key
      tips: '配置>硬件配置>打印机 中添加',
      tipsCls: 'g9 fs12',
      exLabelKeyFun: (item) => {
        return `${item.title}（${PRINTER_TYPE[item.type]}）`
      },
    },
  },
  // 客户来源
  channel_id: {
    type: 'cascaderServer',
    key: 'channel_id',
    value: '',
    labelAttrs: {
      label: '客户来源',
    },
    formAttrs: {
      placeholder: '请搜索/选择客户来源',
      clearable: true,
      filterable: true,
      multiple: false,
      'show-all-levels': true,
      listApi: '/channel/tree',
      class: 'w',
      props: {
        label: 'title',
        value: 'id',
        checkStrictly: false,
        multiple: false,
      },
    },
  },
  // 家具销售点
  pos: {
    type: 'cascaderServer',
    key: 'channel_id',
    value: '',
    labelAttrs: {
      label: '销售点',
    },
    formAttrs: {
      placeholder: '请搜索/选择销售点',
      clearable: true,
      filterable: true,
      multiple: false,
      'show-all-levels': true,
      listApi: '/channel/tree',
      class: 'w',
      props: {
        label: 'title',
        value: 'id',
        checkStrictly: false,
        multiple: false,
      },
    },
  },
  // 家具区域
  MHL_HONGKONG_DISTRICT: {
    type: 'select',
    key: 'area',
    value: '',
    labelAttrs: {
      label: '区域',
    },
    formAttrs: {
      // class: 'w150',
      searchKey: 'title',
      placeholder: '请选择区域',
    },
    options: objToArr(MHL_HONGKONG_DISTRICT, false),
  },
  // 家具区域
  furnitureDistrict: {
    type: 'select',
    key: 'sale_order~district',
    value: '',
    labelAttrs: {
      label: '区域',
    },
    formAttrs: {
      // class: 'w150',
      multiple: true,
      multipleToArr: true,
      searchKey: 'title',
      clearable: true,
      placeholder: '请选择区域',
    },
    options: objToArr(MHL_HONGKONG_DISTRICT, false),
  },
  // 家具发货状态
  furnitureDeliveryStatus: {
    type: 'select',
    key: 'status',
    value: '',
    labelAttrs: {
      label: '货物状态',
    },
    formAttrs: {
      // class: 'w150',
      clearable: true,
      searchKey: 'title',
      placeholder: '请选择货物状态',
    },
    options: objToArr(MHL_DELIVERY_PLAN_STATUS),
  },
  // 客户名称
  customer_id: {
    type: 'selectServer',
    key: 'customer_id',
    labelAttrs: {
      label: '客户名称',
    },
    formAttrs: {
      class: 'mw150 w',
      clearable: true,
      filterable: true,
      listApi: `/customer/index`,
      searchKey: 'title',
      placeholder: '请搜索/选择客户',
    },
  },
  // 账期
  bill_period: {
    type: 'datePickerRange',
    key: 'period',
    value: '',
    labelAttrs: {
      label: '账期',
    },
    formAttrs: {
      type: 'month',
      placeholder: '请选择账期日期',
      format: 'YYYY-MM',
      valueFormat: 'YYYY-MM',
      // clearable: true,
    },
  },
  // 账期类型
  bill_period_type: {
    type: 'select',
    key: 'ref_type',
    value: '',
    labelAttrs: {
      label: '账期类型',
    },
    formAttrs: {
      // class: 'w150',
      searchKey: 'title',
      placeholder: '请选择账期类型',
    },
    options: objToArr(BILL_SHEET_TYPE),
  },
  is_show: {
    type: 'select',
    key: 'is_show',
    value: '',
    labelAttrs: {
      label: '上下架状态',
    },
    formAttrs: {
      placeholder: '请搜索/选择产品上下架状态',
      clearable: true,
    },
    options: objToArr(SHOP_PRODUCT_STATUS),
  },
  // 是否有清单
  has_detail: {
    type: 'select',
    key: 'has_detail',
    labelAttrs: {
      label: '是否有清单',
    },
    formAttrs: {
      class: 'w120',
      clearable: true,
    },
    options: objToArr(YES_NO),
  },
  // 备注
  admin_remark: {
    type: 'input',
    key: 'admin_remark',
    value: '',
    labelAttrs: {
      label: '备注',
    },
    formAttrs: {
      type: 'textarea',
      placeholder: '请输入备注',
      clearable: true,
    },
  },
  // 备注
  remark: {
    type: 'input',
    key: 'remark',
    value: '',
    labelAttrs: {
      label: '备注',
    },
    formAttrs: {
      type: 'textarea',
      placeholder: '请输入备注',
      clearable: true,
    },
  },
  // 插槽
  slot: {
    type: 'slot',
    key: 'admin_remark',
    value: '',
    labelAttrs: {},
    formAttrs: {},
  },
  select: {
    type: 'selectServer',
    key: 'select',
    labelAttrs: {
      label: '选择',
    },
    formAttrs: {
      class: 'mw150 w',
      listApi: `/supplier/index`,
      multiple: false,
      searchKey: 'search',
      placeholder: '请选择',
    },
  },
  img: {
    type: 'uploadImg',
    key: 'image',
    labelAttrs: {
      label: '图片',
    },
    formAttrs: {
      uploadParam: { module: 'product' },
      copyImgUploade: true,
      limitSize: 1024 * 10,
    },
  },
  imgs: {
    type: 'uploadImg',
    key: 'image',
    labelAttrs: {
      label: '图片',
    },
    formAttrs: {
      multiple: true,
      copyImgUploade: true,
      uploadParam: { module: 'product' },
      limitSize: 1024 * 10,
    },
  },
}
/**
 * 通用搜索
 * @param {*} type
 * @param {*} filedList 字段列表
 * @returns
 */
export function commonSearchConfig(type = '', filedList = []) {
  console.log('type :>> commonSearchConfig', type)
  const searchPlaceholder = {
    baseList: '产品编码/名称关键字',
    orderList: '产品编码/名称关键字',
    furnitureOrder: '輸入產品/單號/手機',
  }
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 4,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: searchPlaceholder[type] || '请输入关键词',
          clearable: true,
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  if (type === 'channel' || type === 'rivalChannel') {
    const channelTypeObj = {
      channel: {
        key: 'channel_id',
        url: '/channel/tree',
      },
      rivalChannel: {
        key: 'enemy_channel_id',
        url: '/enemy-channel/tree',
      },
    }
    defaultConfig.formList.unshift({
      type: 'select',
      key: 'visible',
      value: '',
      labelAttrs: {
        label: '数据权限',
      },
      formAttrs: {
        placeholder: '请选择数据权限',
        clearable: true,
        filterable: true,
      },
      options: objToArr(ROLE_VISIBLE_RANGE),
    })
    defaultConfig.formList.unshift({
      type: 'cascaderServer',
      key: channelTypeObj[type].key,
      value: '',
      labelAttrs: {
        label: '渠道名称',
      },
      formAttrs: {
        placeholder: '请搜索/选择渠道名称',
        clearable: true,
        filterable: true,
        multiple: false,
        'show-all-levels': true,
        listApi: channelTypeObj[type].url,
        class: 'w',
        props: {
          label: 'title',
          value: 'id',
          checkStrictly: false,
          multiple: false,
        },
      },
    })
  }
  if (type === 'orderList') {
    defaultConfig.formList.splice(1, 0, {
      type: 'select',
      key: 'status',
      value: '',
      labelAttrs: {
        label: '发货状态',
      },
      formAttrs: {
        placeholder: '请选择发货状态',
        clearable: true,
      },
      options: objToArr(SALE_ORDER_DETAIL_STATUS),
    })
  }
  if (filedList.length) {
    const pushArr = []
    for (let i = 0; i < filedList.length; i++) {
      const filed = filedList[i]
      if (isObject(filed)) {
        const { key, rules, label, name, defaultVal, api, options } = filed
        const filedInfo = cloneDeep(commonFiledObj[key])
        if (rules) {
          filedInfo.labelAttrs.rules = rules
        }
        if (label) {
          filedInfo.labelAttrs.label = label
        }
        if (name) {
          filedInfo.key = name
        }
        if (defaultVal) {
          filedInfo.value = defaultVal
        }
        if (api) {
          filedInfo.formAttrs.listApi = api
        }
        if (options) {
          filedInfo.options = options
        }
        if (filedInfo) {
          pushArr.push(filedInfo)
        }
      } else if (commonFiledObj[filed]) {
        pushArr.push({ ...commonFiledObj[filed] })
      }
    }
    defaultConfig.formList.splice(1, 0, ...pushArr)
  }
  if (type === 'noSearch') {
    defaultConfig.formList.splice(0, 1)
  }
  return defaultConfig
}
/**
 * 产品添加侧边
 * @param {*} type
 */
export function productAddSearchConfig(type, info = {}) {
  console.log('type', type)
  // 工艺模块
  if (type === 'technologyList') {
    return commonSearchConfig()
  }
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'input',
        key: 'product-search',
        value: type === 'thirdShop' ? info.sku : '',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '名称/编码/OEM编码',
          clearable: true,
        },
        layoutAttrs: {
          span: 8,
        },
      },
      {
        type: 'cascaderServer',
        key: 'product-category_id',
        labelAttrs: {
          label: '产品分类',
        },
        formAttrs: {
          placeholder: '请选择产品分类',
          clearable: true,
          filterable: true,
          'show-all-levels': true,
          listApi: '/category/tree',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
          },
          exParams: {
            sorts: {
              pid: 'asc',
              rank: 'desc',
            },
          },
        },
        layoutAttrs: {
          span: 8,
        },
      },
      {
        type: 'cascaderServer',
        key: 'repository_id',
        value: info?.repository_id || info?.out_repository_id || '',
        labelAttrs: {
          label: '仓库',
        },
        formAttrs: {
          placeholder: '请选择仓库',
          clearable: true,
          filterable: true,
          multiple: false,
          showAllLevels: true,
          listApi: '/repository/tree',
          exParams: {
            sort: 'title',
            order: 'asc',
          },
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
            multiple: false,
          },
        },
        layoutAttrs: {
          span: 8,
        },
      },
      {
        type: 'cascaderServer',
        key: 'product-vehicle_ids',
        value: '',
        labelAttrs: {
          label: '车型',
        },
        formAttrs: {
          placeholder: '请选择车型',
          clearable: true,
          filterable: true,
          multiple: false,
          searchKey: 'title',
          'show-all-levels': true,
          listApi: '/vehicle/tree',
          exParams: {
            sort: 'title',
            order: 'asc',
          },
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: false,
            multiple: false,
          },
        },
        layoutAttrs: {
          span: 8,
        },
      },
      {
        type: 'select',
        key: 'nature',
        value: 0,
        labelAttrs: {
          label: '产品类别',
        },
        formAttrs: {
          clearable: true,
          multiple: true,
          placeholder: '请选择产品类别',
        },
        options: objToArr(PRODUCT_NATURE).slice(1),
      },
      {
        type: 'selectServer',
        key: 'product-vehicle_parts_id',
        value: '',
        labelAttrs: {
          label: '部件',
        },
        formAttrs: {
          listApi: `vehicle-part/index`,
          valueType: 'number',
          multiple: false,
          placeholder: '请选择部件',
        },
        layoutAttrs: {
          span: 8,
        },
      },
      {
        type: 'input',
        key: 'product-codes',
        value: '',
        labelAttrs: {
          label: '编码',
        },
        formAttrs: {
          class: 'w',
          type: 'textarea',
          placeholder: '编码超级搜索，多个编码换行隔开',
          clearable: true,
        },
        layoutAttrs: {
          span: 8,
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
        layoutAttrs: {
          span: 8,
        },
      },
    ],
  }

  // =============== 家行业 ===============
  const furnitureProfession = ['furnitureOrder', 'furnitureStockup']
  if (furnitureProfession.includes(type)) {
    // 家具
    defaultConfig.formList.splice(3, 3)
  } else if (type === 'equipmentGroupDetail') {
    // 模具组添加模具
    return commonSearchConfig()
  }
  return defaultConfig
}
/**
 * 产品搜索
 * @param {*} type
 * @returns
 */
export function prodcutSearchConfig(
  type = '',
  isAutoParts = true,
  isShopMenu = false,
  isMateriel = false
) {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    onlyData: true,
    showNum: 4,
    formList: [
      // {
      //   type: 'input',
      //   key: 'search',
      //   labelAttrs: {
      //     label: '查询',
      //   },
      //   formAttrs: {
      //     type: 'text',
      //     style: 'width:250px',
      //     placeholder: '名称/编码/OEM编码',
      //     clearable: true,
      //   },
      // },
      {
        type: 'inputSelect',
        key: 'search',
        // selectVal: "search",
        selectVal: ['search'],
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          style: 'width:250px',
          placeholder: '名称/编码/OEM编码',
          clearable: true,
        },
        formSelectAttrs: {
          multiple: true,
          style: {
            width: '120px',
          },
        },
        options: productSearchConfig,
      },
      {
        type: 'cascaderServer',
        key: 'category_id',
        labelAttrs: {
          label: '产品分类',
        },
        formAttrs: {
          placeholder: '请搜索/选择产品分类',
          clearable: true,
          filterable: true,
          'show-all-levels': true,
          listApi: '/category/tree',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
          },
          exParams: {
            sorts: {
              pid: 'asc',
              rank: 'desc',
            },
          },
        },
      },
      {
        type: isAutoParts ? 'cascaderServer' : 'slot',
        key: 'product-vehicle_ids',
        value: '',
        labelAttrs: {
          label: '车型',
        },
        formAttrs: {
          placeholder: '请选择车型',
          clearable: true,
          filterable: true,
          multiple: false,
          searchKey: 'title',
          'show-all-levels': true,
          listApi: '/vehicle/tree',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
            multiple: false,
          },
        },
      },
      {
        type: isMateriel ? 'select' : 'slot',
        key: 'nature',
        value: isMateriel ? [1, 2, 3] : 0,
        labelAttrs: {
          label: '产品类别',
        },
        formAttrs: {
          // clearable: true,
          multiple: true,
          placeholder: '请选择产品类别',
        },
        condition: () => {
          return isMateriel
        },
        options: objToArr(PRODUCT_NATURE).slice(1),
      },
      {
        type: 'datePicker',
        key: 'year',
        startKey: 'year',
        value: '',
        labelAttrs: {
          label: '年份',
        },
        formAttrs: {
          type: 'year',
          placeholder: '请选择年份',
          format: 'YYYY',
          valueFormat: 'YYYY',
          // clearable: true,
        },
      },
      {
        type: isAutoParts ? 'selectServer' : 'slot',
        key: 'product-vehicle_parts_id',
        value: '',
        labelAttrs: {
          label: '部件',
        },
        formAttrs: {
          listApi: `vehicle-part/index`,
          valueType: 'number',
          multiple: false,
          placeholder: '请搜索/选择部件',
        },
      },
      {
        type: 'cascaderServer',
        key: 'target_area',
        value: '',
        labelAttrs: {
          label: '目标市场',
        },
        formAttrs: {
          placeholder: '请选择目标市场',
          clearable: true,
          filterable: true,
          searchKey: 'title',
          'show-all-levels': true,
          // listApi: '/area/tree',
          class: 'w',
          props: {
            multiple: false,
            label: 'title',
            value: 'id',
            checkStrictly: true,
          },
          options: localAreaTree,
        },
        layoutAttrs: {
          span: 24,
        },
      },
      {
        type: 'select',
        key: 'source',
        value: '',
        labelAttrs: {
          label: '产品货源',
        },
        formAttrs: {
          placeholder: '请搜索/选择产品货源',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 6,
          lg: 6,
          xl: 6,
        },
        options: objToArr(PRODUCT_SOURCE),
      },
      {
        type: isAutoParts ? 'selectServer' : 'slot',
        key: 'supplier_id',
        labelAttrs: {
          label: '供应商',
        },
        formAttrs: {
          listApi: `/supplier/index`,
          valueType: 'number',
          multiple: false,
          searchKey: 'search',
          placeholder: '请搜索/选择供应商',
        },
      },
      {
        type: isAutoParts ? 'baseRate' : 'slot',
        key: 'urgency_star|>=',
        value: 0,
        labelAttrs: {
          label: '紧急程度',
        },
        formAttrs: {
          type: 'text',
          icon: 'fire-fill',
          max: 3,
          voidIcon: 'fire-line',
          color: '#ff4d4f',
        },
      },
      {
        type: isAutoParts ? 'baseRate' : 'slot',
        key: 'retail_suitable|>=',
        value: 0,
        labelAttrs: {
          label: '零售适配',
        },
        formAttrs: {
          type: 'text',
          max: 3,
        },
      },
      {
        type: 'slider',
        key: 'product_dynamic',
        startKey: 'product_dynamic~score|>=',
        endKey: 'product_dynamic~score|<=',
        value: [0, 100],
        labelAttrs: {
          label: '产品评分',
        },
        formAttrs: {
          class: 'w200',
          range: true,
          size: 'small',
          isRange: true,
        },
      },
      {
        type: 'slider',
        key: 'product_dynamic_supplier_score',
        startKey: 'product_dynamic~supplier_score|>=',
        endKey: 'product_dynamic~supplier_score|<=',
        value: [0, 100],
        labelAttrs: {
          label: '供应链评分',
        },
        formAttrs: {
          class: 'w200',
          range: true,
          size: 'small',
          isRange: true,
        },
      },
      {
        type: isAutoParts ? 'select' : 'slot',
        key: 'is_pivotal',
        labelAttrs: {
          label: '是否KP',
        },
        formAttrs: {
          class: 'w150',
          clearable: true,
        },
        options: objToArr(YES_NO),
      },
      {
        type: 'selectServer',
        key: 'label_ids',
        labelAttrs: {
          label: '产品标签',
        },
        formAttrs: {
          clearable: true,
          filterable: true,
          multiple: true,
          valueType: 'number',
          listApi: `/label/index`,
          searchKey: 'title',
          placeholder: '请选择标签',
          exParams: {
            type: ['common', 'product'],
          },
          addApi: '/label/add',
        },
        layoutAttrs: {},
      },
      {
        type: 'selectServer',
        key: 'create_admin_id',
        labelAttrs: {
          label: '创建人',
        },
        formAttrs: {
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择创建人',
        },
      },
      {
        type: 'selectServer',
        key: 'edit_admin_id',
        labelAttrs: {
          label: '编辑人',
        },
        formAttrs: {
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择最后编辑人',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  // 电商
  if (isShopMenu) {
    defaultConfig.formList.splice(1, 0, {
      type: 'select',
      key: 'is_show',
      value: 1,
      labelAttrs: {
        label: '上下架状态',
      },
      formAttrs: {
        placeholder: '请搜索/选择产品上下架状态',
        clearable: true,
      },
      options: objToArr(SHOP_PRODUCT_STATUS),
    })
  }
  return defaultConfig
}

/**
 * 客户搜索
 * @param {*} type
 * @returns
 */
export function customerSearchConfig(type = '') {
  console.log('type :>> ', type)
  const userInfo = store.getters['user/userInfo']
  const defaultValue = []
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 5,
    formList: [
      {
        type: 'select',
        key: 'visible',
        value: '',
        labelAttrs: {
          label: '范围',
        },
        formAttrs: {
          class: 'w150',
          searchKey: 'title',
          placeholder: '请选择数据范围',
        },
        options: objToArr(ROLE_VISIBLE_RANGE),
      },
      // {
      //   type: 'input',
      //   key: 'search',
      //   labelAttrs: {
      //     label: '关键词',
      //   },
      //   formAttrs: {
      //     type: 'text',
      //     placeholder: '请输入关键词搜索',
      //     clearable: true,
      //   },
      // },
      {
        type: 'inputSelect',
        key: 'search',
        // selectVal: "search",
        selectVal: ['search'],
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          style: 'width:250px',
          placeholder: '请输入关键词搜索',
          clearable: true,
        },
        formSelectAttrs: {
          multiple: true,
          style: {
            width: '120px',
          },
        },
        options: labelSearchConfig,
      },
      {
        type: 'cascaderServer',
        key: 'area_id',
        value: '',
        labelAttrs: {
          label: '客户地区',
        },
        formAttrs: {
          placeholder: '请选择地区',
          clearable: true,
          filterable: true,
          searchKey: 'title',
          'show-all-levels': true,
          // listApi: '/area/tree',
          class: 'w',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
          },
          options: localAreaTree,
        },
      },
      {
        type: 'select',
        key: 'is_public',
        value: '',
        labelAttrs: {
          label: '客户类型',
        },
        formAttrs: {
          placeholder: '请选择客户类型',
          clearable: true,
        },
        options: objToArr(CUSTOMER_PUBLIC),
      },
      {
        type: userInfo.visible_range ? 'selectServer' : 'slot',
        key: 'owner_admin_id',
        labelAttrs: {
          label: '负责人',
        },
        formAttrs: {
          class: 'w130',
          listApi: `/admin/index?__customer_num=1`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择负责人',
          exLabelKeyFun: (item) => {
            return `${item.name || item.realname} (${item.customer_num})`
          },
        },
      },
      {
        type: 'selectServer',
        key: 'customer_level_id',
        labelAttrs: {
          label: '客户等级',
        },
        formAttrs: {
          class: 'w150',
          listApi: `/customer-level/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择客户等级',
          exParams: { status: 1, order: 'desc', __customer_num: 1 },
          exLabelKeyFun: (item) => {
            return `${item.title} (${item.customer_num})`
          },
        },
      },
      {
        type: 'selectServer',
        key: 'customer_trade_id',
        labelAttrs: {
          label: '客户行业',
        },
        formAttrs: {
          class: 'w150',
          listApi: `/customer-trade/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择客户行业',
          exParams: { status: 1 },
        },
      },
      // {
      //   type: 'selectServer',
      //   key: 'customer_source_id',
      //   labelAttrs: {
      //     label: '客户来源',
      //   },
      //   formAttrs: {
      //     class: 'w150',
      //     listApi: `/customer-source/index`,
      //     searchKey: 'title',
      //     placeholder: '请搜索/选择客户来源',
      //     exParams: { status: 1, order: 'asc' },
      //   },
      // },
      {
        type: 'cascaderServer',
        key: 'channel_id',
        value: '',
        labelAttrs: {
          label: '客户来源',
        },
        formAttrs: {
          placeholder: '请搜索/选择客户来源',
          clearable: true,
          filterable: true,
          multiple: false,
          'show-all-levels': true,
          listApi: '/channel/tree',
          class: 'w',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: false,
            multiple: false,
          },
        },
      },
      {
        type: 'input',
        key: 'code',
        labelAttrs: {
          label: '客户编码',
        },
        formAttrs: {
          type: 'text',
          placeholder: '客户编码',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'interest',
        labelAttrs: {
          label: '感兴趣产品',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入感兴趣的产品',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'contact',
        labelAttrs: {
          label: '联系人',
        },
        formAttrs: {
          type: 'text',
          placeholder: '联系人',
          clearable: true,
        },
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'create_time|>=',
        endKey: 'create_time|<=',
        value: defaultValue,
        labelAttrs: {
          label: '创建时间',
        },
        formAttrs: {
          class: 'w280',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'select',
        key: 'is_pivotal',
        labelAttrs: {
          label: '是否KA',
        },
        formAttrs: {
          clearable: true,
        },
        options: objToArr(YES_NO),
      },
      {
        type: 'selectServer',
        key: 'label_ids',
        labelAttrs: {
          label: '客户标签',
        },
        formAttrs: {
          clearable: true,
          filterable: true,
          multiple: true,
          valueType: 'number',
          listApi: `/label/index`,
          searchKey: 'title',
          placeholder: '请选择标签',
          exParams: {
            type: ['common', 'customer'],
          },
          addApi: '/label/add',
        },
        layoutAttrs: {},
      },
      {
        type: 'select',
        key: 'is_payment_period',
        value: '',
        labelAttrs: {
          label: '账期客户',
        },
        formAttrs: {
          clearable: true,
        },
        options: objToArr(YES_NO),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  return defaultConfig
}

/**
 * 客户线索搜索
 * @param {*} type
 * @returns
 */
export function customerClueSearchConfig(type = '') {
  console.log('type :>> ', type)
  const userInfo = store.getters['user/userInfo']
  const defaultValue = []
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 5,
    formList: [
      {
        type: 'select',
        key: 'visible',
        value: type === 'private' ? 0 : '',
        labelAttrs: {
          label: '范围',
        },
        formAttrs: {
          class: 'w150',
          searchKey: 'title',
          placeholder: '请选择数据范围',
        },
        options: objToArr(ROLE_VISIBLE_RANGE),
      },
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '关键词',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词搜索',
          clearable: true,
        },
      },
      {
        type: 'cascaderServer',
        key: 'area_id',
        value: '',
        labelAttrs: {
          label: '客户地区',
        },
        formAttrs: {
          placeholder: '请选择地区',
          clearable: true,
          filterable: true,
          searchKey: 'title',
          'show-all-levels': true,
          // listApi: '/area/tree',
          class: 'w',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
          },
          options: localAreaTree,
        },
      },
      {
        type: 'select',
        key: 'stage',
        value: '',
        labelAttrs: {
          label: '线索阶段',
        },
        formAttrs: {
          clearable: true,
          filterable: true,
          searchKey: 'title',
          placeholder: '请选择线索阶段',
        },
        options: objToArr(BUSINESS_OPPORTUNITY_STAGE),
      },
      {
        type: 'select',
        key: 'end_stage',
        value: type === 'private' ? 0 : '',
        labelAttrs: {
          label: '线索状态',
        },
        formAttrs: {
          clearable: true,
          searchKey: 'title',
          placeholder: '请选择线索状态',
        },
        options: objToArr(CLUE_END_STAGE),
      },
      {
        type: userInfo.visible_range ? 'selectServer' : 'slot',
        key: 'owner_admin_id',
        labelAttrs: {
          label: '负责人',
        },
        formAttrs: {
          class: 'w130',
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择负责人',
        },
      },
      {
        type: 'selectServer',
        key: 'customer_level_id',
        labelAttrs: {
          label: '客户等级',
        },
        formAttrs: {
          class: 'w150',
          listApi: `/customer-level/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择客户等级',
          exParams: { status: 1, order: 'asc' },
        },
      },
      {
        type: 'selectServer',
        key: 'customer_trade_id',
        labelAttrs: {
          label: '客户行业',
        },
        formAttrs: {
          class: 'w150',
          listApi: `/customer-trade/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择客户行业',
          exParams: { status: 1 },
        },
      },
      {
        type: 'cascaderServer',
        key: 'channel_id',
        value: '',
        labelAttrs: {
          label: '客户来源',
        },
        formAttrs: {
          placeholder: '请搜索/选择客户来源',
          clearable: true,
          filterable: true,
          multiple: false,
          'show-all-levels': true,
          listApi: '/channel/tree',
          class: 'w',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: false,
            multiple: false,
          },
        },
      },
      {
        type: 'input',
        key: 'code',
        labelAttrs: {
          label: '客户编码',
        },
        formAttrs: {
          type: 'text',
          placeholder: '客户编码',
          clearable: true,
        },
      },
      // {
      //   type: 'input',
      //   key: 'interest',
      //   labelAttrs: {
      //     label: '感兴趣产品',
      //   },
      //   formAttrs: {
      //     type: 'text',
      //     placeholder: '请输入感兴趣的产品',
      //     clearable: true,
      //   },
      // },
      {
        type: 'input',
        key: 'contact',
        labelAttrs: {
          label: '联系人',
        },
        formAttrs: {
          type: 'text',
          placeholder: '联系人',
          clearable: true,
        },
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'create_time|>=',
        endKey: 'create_time|<=',
        value: defaultValue,
        labelAttrs: {
          label: '创建时间',
        },
        formAttrs: {
          class: 'w280',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  return defaultConfig
}
/**
 * 商机搜索
 * @param {*} type
 * @returns
 */
export function businessOpportunityListSearchConfig(type = '') {
  console.log('type :>> ', type)
  const userInfo = store.getters['user/userInfo']
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 4,
    formList: [
      {
        type: 'select',
        key: 'visible',
        value: '',
        labelAttrs: {
          label: '范围',
        },
        formAttrs: {
          class: 'w150',
          searchKey: 'title',
          placeholder: '请选择数据范围',
        },
        options: objToArr(ROLE_VISIBLE_RANGE),
      },
      {
        type: 'input',
        key: 'title',
        labelAttrs: {
          label: '商机名称',
        },
        formAttrs: {
          type: 'text',
          placeholder: '商机名称',
          clearable: true,
        },
      },
      {
        type: 'select',
        key: 'is_public',
        value: '',
        labelAttrs: {
          label: '商机属性',
        },
        formAttrs: {
          class: 'w100',
          searchKey: 'title',
          placeholder: '请选择商机属性',
        },
        options: objToArr(BUSINESS_TYPE),
      },
      {
        type: userInfo.visible_range ? 'selectServer' : 'slot',
        key: 'owner_admin_id',
        labelAttrs: {
          label: '负责人',
        },
        formAttrs: {
          class: 'w150',
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择负责人',
        },
      },
      {
        type: 'select',
        key: 'stage_id',
        labelAttrs: {
          label: '商机阶段',
        },
        formAttrs: {
          clearable: true,
          class: 'w150',
          // listApi: `/business-opportunity-stage/index`,
          searchKey: 'title',
          placeholder: '请选择商机阶段',
          exParams: { status: 1 },
        },
        options: objToArr(BUSINESS_OPPORTUNITY_STAGE),
      },
      {
        type: 'select',
        key: 'end_stage',
        labelAttrs: {
          label: '商机状态',
        },
        formAttrs: {
          class: 'w150',
          clearable: true,
          searchKey: 'title',
          placeholder: '请选择商机状态',
        },
        options: objToArr(BUSINESS_OPPORTUNITY_END_STAGE),
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'create_time|>=',
        endKey: 'create_time|<=',
        value: [],
        labelAttrs: {
          label: '创建时间',
        },
        formAttrs: {
          class: 'w280',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 报价单搜索
 * @param {*} type
 * @returns
 */
export function quoteListSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          style: 'width:250px',
          placeholder: '标题/编号',
          clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: 'customer_id',
        value: '',
        titleKey: '',
        labelAttrs: {
          label: '客户名称',
        },
        formAttrs: {
          listApi: 'customer/index',
          labelKey: 'title',
          searchKey: 'search',
          placeholder: '请搜索/选择客户',
        },
      },
      {
        type: 'select',
        key: 'status',
        labelAttrs: {
          label: '状态',
        },
        formAttrs: {
          class: 'w120',
          placeholder: '请选择状态',
          clearable: true,
        },
        options: objToArr(QUOTE_STATUS),
      },
      {
        type: 'select',
        key: 'audit_status',
        labelAttrs: {
          label: '审核状态',
        },
        formAttrs: {
          class: 'w150',
          placeholder: '请选择审核状态',
          clearable: true,
        },
        options: objToArr(QUOTE_AUDIT_STATUS),
      },
      {
        type: 'selectServer',
        key: 'owner_admin_id',
        labelAttrs: {
          label: '负责人',
        },
        formAttrs: {
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择负责人',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 订单搜索
 * @param {*} type
 * @returns
 */
export function orderSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '名称/单号/关键词',
          clearable: true,
        },
      },
      {
        type: 'select',
        key: 'audit_status',
        labelAttrs: {
          label: '审核状态',
        },
        formAttrs: {
          class: 'w150',
          placeholder: '请选择审核状态',
          clearable: true,
        },
        options: objToArr(ORDER_AUDIT_STATUS),
      },
      {
        type: 'select',
        key: 'pay_status',
        labelAttrs: {
          label: '付款状态',
        },
        formAttrs: {
          class: 'w150',
          placeholder: '请选择付款状态',
          clearable: true,
        },
        options: objToArr(ORDER_PAY_STATUS),
      },
      {
        type: 'select',
        key: 'delivery_status',
        labelAttrs: {
          label: '发货状态',
        },
        formAttrs: {
          class: 'w150',
          placeholder: '请选择发货状态',
          clearable: true,
        },
        options: objToArr(ORDER_DELIVERY_STATUS),
      },
      {
        type: 'selectServer',
        key: 'owner_admin_id',
        labelAttrs: {
          label: '负责人',
        },
        formAttrs: {
          listApi: `/admin/index?__customer_num=1`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择负责人',
          exLabelKeyFun: (item) => {
            return `${item.name || item.realname} (${item.customer_num})`
          },
        },
      },
      {
        type: 'selectServer',
        key: 'customer_id',
        labelAttrs: {
          label: '客户名称',
        },
        formAttrs: {
          clearable: true,
          filterable: true,
          class: 'w150',
          listApi: `/customer/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择客户',
        },
      },
      {
        type: 'cascaderServer',
        key: 'channel_id',
        value: '',
        labelAttrs: {
          label: '客户来源',
        },
        formAttrs: {
          placeholder: '请搜索/选择客户来源',
          clearable: true,
          filterable: true,
          multiple: false,
          'show-all-levels': true,
          listApi: '/channel/tree',
          class: 'w',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: false,
            multiple: false,
          },
        },
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'order_time|>=',
        endKey: 'order_time|<=',
        value: [],
        labelAttrs: {
          label: '下单日期',
        },
        formAttrs: {
          type: 'daterange',
          placeholder: '请选择下单日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          shortcuts: handleGetShortDate(),
          // clearable: true,
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 客诉搜索
 * @param {*} type
 * @returns
 */
export function customerFeedbackSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          style: 'width:250px',
          placeholder: '标题/编号',
          clearable: true,
        },
      },
      {
        type: 'select',
        key: 'type',
        labelAttrs: {
          label: '类型',
        },
        formAttrs: {
          placeholder: '请选择客诉类型',
          clearable: true,
        },
        options: objToArr(FEEDBACK_TYPE, false),
      },

      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}
/**
 * 采购订单搜索
 * @param {*} type
 * @returns
 */
export function purchaseOrderSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 4,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '关键词',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词',
          clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: 'supplier_id',
        value: '',
        titleKey: '',
        labelAttrs: {
          label: '供应商',
        },
        formAttrs: {
          listApi: 'supplier/index',
          labelKey: 'title',
          searchKey: 'search',
          placeholder: '请搜索/选择供应商',
        },
      },
      {
        type: 'select',
        key: 'audit_status',
        labelAttrs: {
          label: '审核状态',
        },
        formAttrs: {
          class: 'w150',
          placeholder: '请选择审核状态',
          clearable: true,
        },
        options: objToArr(ORDER_AUDIT_STATUS),
      },
      {
        type: 'select',
        key: 'pay_status',
        labelAttrs: {
          label: '付款状态',
        },
        formAttrs: {
          class: 'w150',
          placeholder: '请选择付款状态',
          clearable: true,
        },
        options: objToArr(ORDER_PAY_STATUS),
      },
      {
        type: 'select',
        key: 'stock_status',
        labelAttrs: {
          label: '入库状态',
        },
        formAttrs: {
          class: 'w150',
          placeholder: '请选择发货状态',
          clearable: true,
        },
        options: objToArr(ORDER_STOCK_STATUS),
      },
      {
        type: 'select',
        key: 'delivery_status',
        labelAttrs: {
          label: '发货状态',
        },
        formAttrs: {
          class: 'w150',
          placeholder: '请选择发货状态',
          clearable: true,
        },
        options: objToArr(ORDER_DELIVERY_STATUS),
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'create_time|>=',
        endKey: 'create_time|<=',
        value: [],
        labelAttrs: {
          label: '下单日期',
        },
        formAttrs: {
          type: 'daterange',
          placeholder: '请选择下单日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          shortcuts: handleGetShortDate(),
          // clearable: true,
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 供应商搜索
 * @param {*} type
 * @returns
 */
export function supplierListSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'inputSelect',
        key: 'search',
        // selectVal: "search",
        selectVal: ['search'],
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          style: 'width:250px',
          placeholder: '请输入关键词搜索',
          clearable: true,
        },
        formSelectAttrs: {
          multiple: true,
          style: {
            width: '120px',
          },
        },
        options: labelSearchConfig,
      },
      {
        type: 'selectServer',
        key: 'category_id',
        value: '',
        labelAttrs: {
          label: '分类',
        },
        formAttrs: {
          listApi: `/supplier-category/index`,
          valueType: 'number',
          multiple: false,
          placeholder: '请选择分类',
        },
      },
      {
        type: 'selectServer',
        key: 'label_ids',
        labelAttrs: {
          label: '标签',
        },
        formAttrs: {
          class: 'mw150 w',
          clearable: true,
          filterable: true,
          multiple: true,
          valueType: 'number',
          listApi: `/label/index`,
          searchKey: 'title',
          placeholder: '请选择标签',
          exParams: {
            type: ['common', 'supplier'],
          },
          addApi: '/label/add',
        },
        layoutAttrs: {},
      },
      {
        type: 'select',
        key: 'is_payment_period',
        value: '',
        labelAttrs: {
          label: '账期客户',
        },
        formAttrs: {
          clearable: true,
        },
        options: objToArr(YES_NO),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 我的绩效搜索
 * @param {*} type
 * @returns
 */
export function myListSearchConfig(type = '') {
  console.log('type :>> ', type)
  const defaultValue = [
    dayjs().subtract(1, 'M').format('YYYY-01'),
    dayjs().format('YYYY-MM'),
  ]
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'datePickerRange',
        key: 'create_time',
        startKey: 'start_time',
        endKey: 'end_time',
        value: defaultValue,
        labelAttrs: {
          label: '绩效月份',
        },
        formAttrs: {
          type: 'monthrange',
          class: 'w200',
          placeholder: '请选择绩效月份',
          format: 'YYYY-MM',
          'value-format': 'YYYY-MM',
          // clearable: true,
        },
      },
      {
        type: 'select',
        key: 'performance_type',
        labelAttrs: {
          label: '绩效类型',
        },
        formAttrs: {
          class: 'w150',
          placeholder: '请选择绩效类型',
          clearable: true,
        },
        options: objToArr(PERFORMANCE_TYPE),
      },
      {
        type: 'selectServer',
        key: 'customer_id',
        labelAttrs: {
          label: '客户名称',
        },
        formAttrs: {
          clearable: true,
          filterable: true,
          class: 'w150',
          listApi: `/customer/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择客户',
        },
      },
      {
        type: 'selectServer',
        key: 'product_id',
        labelAttrs: {
          label: '产品名称',
        },
        formAttrs: {
          class: 'w180',
          clearable: true,
          filterable: true,
          multiple: true,
          listApi: `/product/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择产品',
        },
      },
      {
        type: 'cascaderServer',
        key: 'category_id',
        labelAttrs: {
          label: '产品分类',
        },
        formAttrs: {
          placeholder: '请搜索/选择产品分类',
          clearable: true,
          filterable: true,
          'show-all-levels': true,
          listApi: '/category/tree',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
          },
          exParams: {
            sorts: {
              pid: 'asc',
              rank: 'desc',
            },
          },
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 团队绩效搜索
 * @param {*} type
 * @returns
 */
export function teamListSearchConfig(type = '') {
  console.log('type :>> ', type)
  const defaultValue = [
    dayjs().subtract(1, 'M').format('YYYY-01'),
    dayjs().format('YYYY-MM'),
  ]
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'datePickerRange',
        key: 'create_time',
        startKey: 'start_time',
        endKey: 'end_time',
        value: defaultValue,
        labelAttrs: {
          label: '绩效月份',
        },
        formAttrs: {
          type: 'monthrange',
          class: 'w200',
          placeholder: '请选择绩效月份',
          // clearable: true,
          'value-format': 'YYYY-MM',
        },
      },
      {
        type: 'select',
        key: 'performance_type',
        labelAttrs: {
          label: '绩效类型',
        },
        formAttrs: {
          class: 'w150',
          placeholder: '请选择绩效类型',
          clearable: true,
        },
        options: objToArr(PERFORMANCE_TYPE),
      },
      {
        type: 'selectServer',
        key: 'customer_id',
        labelAttrs: {
          label: '客户名称',
        },
        formAttrs: {
          class: 'w150',
          clearable: true,
          filterable: true,
          listApi: `/customer/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择客户',
        },
      },
      {
        type: 'selectServer',
        key: 'admin_id',
        labelAttrs: {
          label: '负责人',
        },
        formAttrs: {
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择负责人',
        },
      },
      {
        type: 'select',
        key: 'audit_status',
        labelAttrs: {
          label: '状态',
        },
        formAttrs: {
          placeholder: '请选择状态',
          clearable: true,
        },
        options: objToArr(AUDIT_STATUS),
      },
      {
        type: 'selectServer',
        key: 'product_id',
        labelAttrs: {
          label: '产品名称',
        },
        formAttrs: {
          class: 'w150',
          clearable: true,
          filterable: true,
          listApi: `/product/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择产品',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 核定绩效搜索
 * @param {*} type
 * @returns
 */
export function checkListSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词搜索',
          clearable: true,
        },
      },
      {
        type: 'datePickerRange',
        key: 'create_time',
        startKey: 'start_time',
        endKey: 'end_time',
        value: [dayjs().format('YYYY-MM'), dayjs().format('YYYY-MM')],
        labelAttrs: {
          label: '绩效月份',
        },
        formAttrs: {
          type: 'monthrange',
          placeholder: '请选择绩效月份',
          format: 'YYYY-MM',
          // clearable: true,
        },
      },
      {
        type: 'select',
        key: 'performance_type',
        labelAttrs: {
          label: '绩效类型',
        },
        formAttrs: {
          placeholder: '请选择绩效类型',
          clearable: true,
        },
        options: objToArr(PERFORMANCE_TYPE),
      },
      {
        type: 'selectServer',
        key: 'admin_id',
        labelAttrs: {
          label: '计提人',
        },
        formAttrs: {
          clearable: false,
          filterable: true,
          listApi: `/admin/index`,
          searchKey: 'search',
          labelKey: 'name',
          placeholder: '请选计提人',
        },
      },
      {
        type: 'select',
        key: 'audit_status',
        labelAttrs: {
          label: '状态',
        },
        formAttrs: {
          placeholder: '请选择状态',
          clearable: true,
        },
        options: objToArr(AUDIT_STATUS),
      },
      {
        type: 'selectServer',
        key: 'product_id',
        labelAttrs: {
          label: '产品名称',
        },
        formAttrs: {
          clearable: true,
          filterable: true,
          listApi: `/product/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择产品',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 发货计划搜索
 * @param {*} type
 * @returns
 */
export function deliveryPlanSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词搜索',
          clearable: true,
        },
      },
      // {
      //   type: 'select',
      //   key: 'pay_status',
      //   labelAttrs: {
      //     label: '付款状态',
      //   },
      //   formAttrs: {
      //     placeholder: '请选择付款状态',
      //     clearable: true,
      //   },
      //   options: objToArr(ORDER_PAY_STATUS),
      // },
      {
        type: 'select',
        key: 'delivery_status',
        labelAttrs: {
          label: '发货状态',
        },
        formAttrs: {
          placeholder: '请选择发货状态',
          clearable: true,
        },
        options: objToArr(ORDER_DELIVERY_STATUS),
      },
      {
        type: 'datePicker',
        key: 'plan_date',
        value: '',
        labelAttrs: {
          label: '计划日期',
        },
        formAttrs: {
          placeholder: '请选择计划日期',
          format: 'YYYY-MM-DD',
          valueFormat: 'YYYY-MM-DD',
          // clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: 'create_admin_id',
        labelAttrs: {
          label: '业务员',
        },
        formAttrs: {
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择业务员',
        },
      },
      // {
      //   type: 'select',
      //   key: 'audit_status',
      //   labelAttrs: {
      //     label: '审核状态',
      //   },
      //   formAttrs: {
      //     placeholder: '请选择审核状态',
      //     clearable: true,
      //   },
      //   options: objToArr(ORDER_AUDIT_STATUS),
      // },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 派货行程单搜索
 * @param {*} type
 * @returns
 */
export function deliveryGoodsSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词搜索',
          clearable: true,
        },
      },
      {
        type: 'select',
        key: 'status',
        labelAttrs: {
          label: '发货状态',
        },
        formAttrs: {
          placeholder: '请选择发货状态',
          clearable: true,
        },
        options: objToArr(MHL_DELIVERY_PLAN_STATUS),
      },
      {
        type: 'selectServer',
        key: 'create_admin_id',
        labelAttrs: {
          label: '业务员',
        },
        formAttrs: {
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择业务员',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}
/**
 * 收货单搜索
 * @param {*} type
 * @returns
 */
export function receiveDeliverySearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词搜索',
          clearable: true,
        },
      },
      {
        type: 'select',
        key: 'delivery_status',
        labelAttrs: {
          label: '发货状态',
        },
        formAttrs: {
          placeholder: '请选择发货状态',
          clearable: true,
        },
        options: objToArr(ORDER_DELIVERY_STATUS),
      },
      // {
      //   type: 'select',
      //   key: 'audit_status',
      //   labelAttrs: {
      //     label: '审核状态',
      //   },
      //   formAttrs: {
      //     placeholder: '请选择审核状态',
      //     clearable: true,
      //   },
      //   options: objToArr(ORDER_AUDIT_STATUS),
      // },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 商品退货搜索
 * @param {*} type
 * @returns
 */
export function salesReturnSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词搜索',
          clearable: true,
        },
      },
      // {
      //   type: 'select',
      //   key: 'delivery_status',
      //   labelAttrs: {
      //     label: '发货状态',
      //   },
      //   formAttrs: {
      //     placeholder: '请选择发货状态',
      //     clearable: true,
      //   },
      //   options: objToArr(ORDER_DELIVERY_STATUS),
      // },
      {
        type: 'selectServer',
        key: 'create_admin_id',
        labelAttrs: {
          label: '业务员',
        },
        formAttrs: {
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择业务员',
        },
      },
      {
        type: 'select',
        key: 'audit_status',
        labelAttrs: {
          label: '审核状态',
        },
        formAttrs: {
          placeholder: '请选择审核状态',
          clearable: true,
        },
        options: objToArr(ORDER_AUDIT_STATUS),
      },
      {
        type: 'select',
        key: 'status',
        labelAttrs: {
          label: '退货状态',
        },
        formAttrs: {
          placeholder: '请选择退货状态',
          clearable: true,
        },
        options: objToArr(SUPPLIER_SALE_RETURN_STATUS),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 快递寄货搜索
 * @param {*} type
 * @returns
 */
export function expressDeliverySearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '',
          clearable: true,
        },
      },
      {
        type: 'select',
        key: 'express_com',
        value: '',
        labelAttrs: {
          label: '快递公司',
        },
        formAttrs: {
          placeholder: '请选择快递公司',
        },
        options: objToArr(store.getters['sys/supportExpress'], false),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 库存报表搜索
 * @param {*} type
 * @returns
 */
export function repositoryReportFormsSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'cascaderServer',
        key: 'repository_id',
        value: '',
        labelAttrs: {
          label: '仓库',
        },
        formAttrs: {
          placeholder: '请选择仓库',
          clearable: true,
          filterable: true,
          multiple: false,
          showAllLevels: true,
          listApi: '/repository/tree',
          exParams: {
            sort: 'title',
            order: 'asc',
          },
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
            multiple: false,
          },
        },
      },
      {
        type: 'selectServer',
        key: 'product_id',
        value: '',
        labelAttrs: {
          label: '产品',
        },
        formAttrs: {
          listApi: `/product/index`,
          valueType: 'number',
          searchKey: 'search',
          multiple: false,
          placeholder: '请搜索/选择产品',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  // 收发明细
  if (type === 'detailList') {
    const defaultValue = [
      dayjs().subtract(1, 'M').format('YYYY-MM-DD'),
      dayjs().format('YYYY-MM-DD'),
    ]
    defaultConfig.formList.unshift({
      type: 'select',
      key: 'type',
      value: '',
      labelAttrs: {
        label: '业务类型',
      },
      formAttrs: {
        placeholder: '请选择业务类型',
        multiple: true,
        clearable: true,
      },
      options: objToArr(REPOSITORY_LOG_TYPE),
    })
    defaultConfig.formList.unshift({
      type: 'datePickerRange',
      key: 'date',
      startKey: 'start_date',
      endKey: 'end_date',
      value: '',
      labelAttrs: {
        label: '日期',
      },
      formAttrs: {
        type: 'daterange',
        placeholder: '请选择开始日期',
        format: 'YYYY-MM-DD',
        defaultValue: defaultValue,
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        // clearable: true,
      },
    })
  }
  return defaultConfig
}

/**
 * 单据列表搜索
 * @param {*} type
 * @returns
 */
export function repositoryReceiptListSearchConfig(type = '') {
  console.log('type :>> ', type)
  const receiptTypeList = objToArr(REPOSITORY_LOG_TYPE)
  const defaultValue =
    type === 'all'
      ? []
      : [
          dayjs().subtract(1, 'M').format('YYYY-MM-DD'),
          dayjs().add(2, 'M').format('YYYY-MM-DD'),
        ]
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '',
          clearable: true,
        },
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'date|>=',
        endKey: 'date|<=',
        value: defaultValue,
        labelAttrs: {
          label: '日期',
        },
        formAttrs: {
          class: 'w280',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: 'create_admin_id',
        labelAttrs: {
          label: '业务员',
        },
        formAttrs: {
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择业务员',
        },
      },
      {
        type: 'select',
        key: 'status',
        labelAttrs: {
          label: '状态',
        },
        formAttrs: {
          placeholder: '请选择状态',
          clearable: true,
        },
        options: objToArr(REPOSITORY_RECEIPT_STATUS),
      },
      {
        type: 'select',
        key: 'type',
        labelAttrs: {
          label: '入库类型',
        },
        formAttrs: {
          placeholder: '请选择类型',
          clearable: true,
        },
        options: receiptTypeList.slice(0, receiptTypeList.length / 2),
      },
      {
        type: 'selectServer',
        key: 'customer_id',
        value: '',
        titleKey: '',
        labelAttrs: {
          label: '客户名称',
        },
        formAttrs: {
          listApi: 'customer/index',
          labelKey: 'title',
          searchKey: 'search',
          placeholder: '请搜索/选择客户',
        },
      },
      {
        type: 'selectServer',
        key: 'supplier_id',
        value: '',
        titleKey: '',
        labelAttrs: {
          label: '供应商名称',
        },
        formAttrs: {
          listApi: 'supplier/index',
          labelKey: 'title',
          searchKey: 'search',
          placeholder: '请搜索/选择供应商',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  if (type === 'out') {
    defaultConfig.formList.splice(3, 1, {
      type: 'select',
      key: 'status',
      labelAttrs: {
        label: '出库类型',
      },
      formAttrs: {
        placeholder: '请选择类型',
        clearable: true,
      },
      options: receiptTypeList.slice(
        receiptTypeList.length / 2,
        receiptTypeList.length
      ),
    })
  } else if (type === 'all') {
    defaultConfig.formList.splice(3, 1, {
      type: 'select',
      key: 'status',
      labelAttrs: {
        label: '出入库类型',
      },
      formAttrs: {
        placeholder: '请选择类型',
        clearable: true,
      },
      options: receiptTypeList,
    })
    defaultConfig.formList.splice(1, 0, {
      type: 'radio',
      key: 'pm',
      formAttrs: {
        placeholder: '请选择出入库类型',
        clearable: true,
      },
      isButton: true,
      options: [
        { title: t('入库'), attrs: { label: 1 }, events: {} },
        { title: t('出库'), attrs: { label: 0 }, events: {} },
      ],
    })
  }

  return defaultConfig
}

/**
 * 库存盘点搜索
 * @param {*} type
 * @returns
 */
export function repositoryInventorySearchConfig(type = '') {
  console.log('type :>> ', type)
  // const defaultValue = dayjs().format("YYYY-MM-DD")
  const defaultValue = []
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '',
          clearable: true,
        },
      },
      {
        type: 'cascaderServer',
        key: 'repository_id',
        value: '',
        labelAttrs: {
          label: '仓库',
        },
        formAttrs: {
          placeholder: '请选择仓库',
          clearable: true,
          filterable: true,
          multiple: false,
          showAllLevels: true,
          listApi: '/repository/tree',
          exParams: {
            sort: 'title',
            order: 'asc',
          },
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
            multiple: false,
          },
        },
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'date|>=',
        endKey: 'date|<=',
        value: defaultValue,
        labelAttrs: {
          label: '日期',
        },
        formAttrs: {
          class: 'w280',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'select',
        key: 'audit_status',
        labelAttrs: {
          label: '审核状态',
        },
        formAttrs: {
          placeholder: '请选择审核状态',
          clearable: true,
        },
        options: objToArr(AUDIT_STATUS),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  if (type === 'transfer') {
    defaultConfig.formList.splice(
      0,
      2,
      {
        type: 'cascaderServer',
        key: 'out_repository_id',
        value: '',
        labelAttrs: {
          label: '调出仓库',
        },
        formAttrs: {
          placeholder: '请选择调出仓库',
          clearable: true,
          filterable: true,
          multiple: false,
          showAllLevels: true,
          listApi: '/repository/tree',
          exParams: {
            sort: 'title',
            order: 'asc',
          },
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
            multiple: false,
          },
        },
      },
      {
        type: 'cascaderServer',
        key: 'in_repository_id',
        value: '',
        labelAttrs: {
          label: '调入仓库',
        },
        formAttrs: {
          placeholder: '请选择调入仓库',
          clearable: true,
          filterable: true,
          multiple: false,
          showAllLevels: true,
          listApi: '/repository/tree',
          exParams: {
            sort: 'title',
            order: 'asc',
          },
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
            multiple: false,
          },
        },
      }
    )
  }

  return defaultConfig
}

/**
 * 产品组装拆卸列表搜索
 * @param {*} type
 * @returns
 */
export function productAssembleSearchConfig(type = 'all') {
  console.log('type :>> ', type)
  const defaultValue =
    type === 'all'
      ? []
      : [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '',
          clearable: true,
        },
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'create_time|>=',
        endKey: 'create_time|<=',
        value: defaultValue,
        labelAttrs: {
          label: '日期',
        },
        formAttrs: {
          class: 'w280',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'select',
        key: 'audit_status',
        labelAttrs: {
          label: '状态',
        },
        formAttrs: {
          placeholder: '请选择状态',
          clearable: true,
        },
        options: objToArr(AUDIT_STATUS),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  return defaultConfig
}

/**
 * 回款计划列表搜索
 * @param {*} type
 * @returns
 */
export function collectionPlanSearchConfig(type = '') {
  console.log('type :>> ', type)
  const defaultValue = type
    ? []
    : [
        dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ]
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 4,
    formList: [
      {
        type: 'select',
        key: 'visible',
        value: '',
        labelAttrs: {
          label: '范围',
        },
        formAttrs: {
          class: 'w150',
          searchKey: 'title',
          placeholder: '请选择数据范围',
        },
        options: objToArr(ROLE_VISIBLE_RANGE),
      },
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词',
          clearable: true,
        },
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'plan_date|>=',
        endKey: 'plan_date|<=',
        value: defaultValue,
        labelAttrs: {
          label: '回款日期',
        },
        formAttrs: {
          class: 'w260',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'select',
        key: 'status',
        value: type ? '' : [0, 1, 2],
        labelAttrs: {
          label: '状态',
        },
        formAttrs: {
          class: 'w300',
          placeholder: '请选择状态',
          clearable: true,
          multiple: true,
        },
        options: objToArr(BILL_PLAN_STATUS),
      },
      {
        type: 'select',
        key: 'pay_type',
        labelAttrs: {
          label: '回款方式',
        },
        formAttrs: {
          placeholder: '请选择回款方式',
          clearable: true,
        },
        options: objToArr(PAY_TYPE),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  return defaultConfig
}

/**
 * 回款明细搜索
 * @param {*} type
 * @returns
 */
export function financeReceivablesSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'select',
        key: 'visible',
        value: '',
        labelAttrs: {
          label: '范围',
        },
        formAttrs: {
          class: 'w150',
          searchKey: 'title',
          placeholder: '请选择数据范围',
        },
        options: objToArr(ROLE_VISIBLE_RANGE),
      },
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '',
          clearable: true,
        },
      },
      {
        type: 'select',
        key: 'pay_type',
        labelAttrs: {
          label: '回款方式',
        },
        formAttrs: {
          placeholder: '请选择回款方式',
          clearable: true,
        },
        options: objToArr(PAY_TYPE),
      },
      {
        type: 'select',
        key: 'audit_status',
        labelAttrs: {
          label: '审核状态',
        },
        formAttrs: {
          placeholder: '请选择审核状态',
          clearable: true,
        },
        options: objToArr(ORDER_AUDIT_STATUS),
      },
      {
        type: 'select',
        key: 'pm',
        labelAttrs: {
          label: '回款类型',
        },
        formAttrs: {
          placeholder: '请选择回款类型',
          clearable: true,
        },
        options: [
          { label: '退款', value: 0 },
          { label: '回款', value: 1 },
        ],
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'date|>=',
        endKey: 'date|<=',
        value: [],
        labelAttrs: {
          label: '回款日期',
        },
        formAttrs: {
          type: 'daterange',
          placeholder: '请选择回款日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: 'create_admin_id',
        labelAttrs: {
          label: '负责人',
        },
        formAttrs: {
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择负责人',
        },
      },
      {
        type: 'selectServer',
        key: 'bill_channel_id',
        value: '',
        labelAttrs: {
          label: '渠道',
        },
        formAttrs: {
          listApi: `/channel/index`,
          searchKey: 'title',
          labelKey: 'title',
          placeholder: '请搜索/选择渠道',
        },
      },
      {
        type: 'selectServer',
        key: 'customer_id',
        labelAttrs: {
          label: '客户名称',
        },
        formAttrs: {
          clearable: true,
          filterable: true,
          class: 'w150',
          listApi: `/customer/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择客户',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 付款计划列表搜索
 * @param {*} type
 * @returns
 */
export function paymentPlanSearchConfig(type = '') {
  console.log('type :>> ', type)
  const defaultValue = type
    ? []
    : [
        dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        dayjs().add(3, 'month').format('YYYY-MM-DD'),
      ]
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 4,
    formList: [
      {
        type: 'select',
        key: 'visible',
        value: '',
        labelAttrs: {
          label: '范围',
        },
        formAttrs: {
          class: 'w150',
          searchKey: 'title',
          placeholder: '请选择数据范围',
        },
        options: objToArr(ROLE_VISIBLE_RANGE),
      },
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词',
          clearable: true,
        },
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'plan_date|>=',
        endKey: 'plan_date|<=',
        value: defaultValue,
        labelAttrs: {
          label: '付款日期',
        },
        formAttrs: {
          class: 'w260',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },

      {
        type: 'select',
        key: 'status',
        value: type ? '' : [0, 1, 2],
        labelAttrs: {
          label: '状态',
        },
        formAttrs: {
          class: 'w300',
          placeholder: '请选择状态',
          clearable: true,
          multiple: true,
        },
        options: objToArr(BILL_PLAN_STATUS),
      },
      {
        type: 'select',
        key: 'pay_type',
        labelAttrs: {
          label: '付款方式',
        },
        formAttrs: {
          placeholder: '请选择付款方式',
          clearable: true,
        },
        options: objToArr(PAY_TYPE),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  return defaultConfig
}

/**
 * 付款款明细搜索
 * @param {*} type
 * @returns
 */
export function paymentDetailSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'select',
        key: 'visible',
        value: '',
        labelAttrs: {
          label: '范围',
        },
        formAttrs: {
          class: 'w150',
          searchKey: 'title',
          placeholder: '请选择数据范围',
        },
        options: objToArr(ROLE_VISIBLE_RANGE),
      },
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '',
          clearable: true,
        },
      },
      {
        type: 'select',
        key: 'pay_type',
        labelAttrs: {
          label: '付款方式',
        },
        formAttrs: {
          placeholder: '请选择付款方式',
          clearable: true,
        },
        options: objToArr(PAY_TYPE),
      },
      {
        type: 'select',
        key: 'audit_status',
        labelAttrs: {
          label: '审核状态',
        },
        formAttrs: {
          placeholder: '请选择审核状态',
          clearable: true,
        },
        options: objToArr(ORDER_AUDIT_STATUS),
      },
      {
        type: 'select',
        key: 'pm',
        labelAttrs: {
          label: '付款类型',
        },
        formAttrs: {
          placeholder: '请选择付款类型',
          clearable: true,
        },
        options: [
          { label: '退款', value: 0 },
          { label: '回款', value: 1 },
        ],
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'date|>=',
        endKey: 'date|<=',
        value: [],
        labelAttrs: {
          label: '付款日期',
        },
        formAttrs: {
          type: 'daterange',
          placeholder: '请选择付款日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: 'create_admin_id',
        labelAttrs: {
          label: '负责人',
        },
        formAttrs: {
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择负责人',
        },
      },
      {
        type: 'selectServer',
        key: 'channel_id',
        value: '',
        labelAttrs: {
          label: '渠道',
        },
        formAttrs: {
          listApi: `/channel/index`,
          searchKey: 'title',
          labelKey: 'title',
          placeholder: '请搜索/选择渠道',
        },
      },
      {
        type: 'selectServer',
        key: 'supplier_id',
        labelAttrs: {
          label: '供应商名称',
        },
        formAttrs: {
          clearable: true,
          filterable: true,
          class: 'w150',
          listApi: `/supplier/index`,
          searchKey: 'title',
          placeholder: '请搜索/选择供应商',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}
/**
 * 开票计划列表搜索
 * @param {*} type
 * @returns
 */
export function invoicePlanSearchConfig(type = '') {
  console.log('type :>> ', type)
  const defaultValue = type
    ? []
    : [
        dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ]
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      // {
      //   type: 'select',
      //   key: 'visible',
      //   value: '',
      //   labelAttrs: {
      //     label: '范围',
      //   },
      //   formAttrs: {
      //     class: 'w150',
      //     searchKey: 'title',
      //     placeholder: '请选择数据范围',
      //   },
      //   options: objToArr(ROLE_VISIBLE_RANGE),
      // },
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词',
          clearable: true,
        },
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'plan_date|>=',
        endKey: 'plan_date|<=',
        value: defaultValue,
        labelAttrs: {
          label: '开票日期',
        },
        formAttrs: {
          class: 'w260',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'select',
        key: 'status',
        labelAttrs: {
          label: '状态',
        },
        formAttrs: {
          placeholder: '请选择状态',
          clearable: true,
        },
        options: objToArr(INVOICE_PLAN_STATUS),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  return defaultConfig
}
/**
 * 任务搜索
 * @param {*} type
 * @returns
 */
export function taskSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 5,
    formList: [
      {
        type: 'select',
        key: 'visible',
        value: '',
        labelAttrs: {
          label: '范围',
        },
        formAttrs: {
          class: 'w150',
          searchKey: 'title',
          placeholder: '请选择数据范围',
        },
        options: objToArr(ROLE_VISIBLE_RANGE),
      },
      {
        type: 'select',
        key: 'status',
        labelAttrs: {
          label: '状态',
        },
        formAttrs: {
          placeholder: '请选择状态',
          clearable: true,
        },
        options: objToArr(TASK_STATUS),
      },
      {
        type: 'select',
        key: 'priority',
        labelAttrs: {
          label: '优先级',
        },
        formAttrs: {
          clearable: true,
          placeholder: '请选择优先级',
        },
        options: objToArr(PRIORITY_TYPE),
      },
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '关键词',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词',
          clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: 'customer_id',
        labelAttrs: {
          label: '客户',
        },
        formAttrs: {
          class: 'w150',
          listApi: `/customer/index`,
          searchKey: 'title',
          labelKey: 'title',
          placeholder: '请搜索/选择客户',
        },
      },
      {
        type: 'selectServer',
        key: 'main_admin_id',
        labelAttrs: {
          label: '负责人',
        },
        formAttrs: {
          class: 'w150',
          listApi: `/admin/index`,
          searchKey: 'name',
          labelKey: 'name',
          placeholder: '请搜索/选择负责人',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 轮播图搜索
 * @param {*} type
 * @returns
 */
export function shopBannerSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          style: 'width:250px',
          placeholder: '名称',
          clearable: true,
        },
      },
      // {
      //   type: 'select',
      //   key: 'source',
      //   value: '',
      //   labelAttrs: {
      //     label: '产品货源',
      //   },
      //   formAttrs: {
      //     class: 'w',
      //     placeholder: '请搜索/选择产品类型',
      //     clearable: true
      //   },
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      //   options: objToArr(PRODUCT_SOURCE),
      // },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 文章搜索
 * @param {*} type
 * @returns
 */
export function shopArticleSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          style: 'width:250px',
          placeholder: '名称',
          clearable: true,
        },
      },
      {
        type: 'select',
        key: 'type',
        value: '',
        labelAttrs: {
          label: '文章类型',
        },
        formAttrs: {
          placeholder: '请选择文章类型',
          clearable: true,
        },
        options: objToArr(ARTICLE_TYPE),
      },
      {
        type: 'select',
        key: 'is_show',
        value: '',
        labelAttrs: {
          label: '上下架状态',
        },
        formAttrs: {
          placeholder: '请选择上下架状态',
          clearable: true,
        },
        options: objToArr(SHOP_PRODUCT_STATUS),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 海关数据搜索
 * @param {*} type
 * @returns
 */
export function customsListSearchConfig(type = '') {
  console.log('type :>> ', type)

  const customsCountryList = store.getters['sys/customsCountryList']
  // console.log('customsCountryList :>> ', customsCountryList);
  const validateStringNum = (rule, value, callback) => {
    if (value) {
      if (!isKey(value)) {
        callback(new Error(t('需要以字母开头')))
      } else {
        callback()
      }
    } else callback()
  }
  console.log('validateStringNum :>> ', validateStringNum)
  const defaultValue = [
    dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ]
  let defaultConfig = {
    formAttrs: {
      inline: true,
      labelPosition: 'top',
    },
    showNum: 10,
    // beforeReq: (data) => {
    //   console.log('data :>> ', data);
    // },
    formList: [
      {
        type: 'input',
        key: 'product',
        value: '',
        labelAttrs: {
          label: '产品',
          rules: [
            {
              validator: validateStringNum,
              message: '只允许输入数字和字母',
              trigger: 'blur',
              require: false,
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入产品关键词',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'hs_code',
        value: '',
        labelAttrs: {
          label: 'HS编码',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入HS编码',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'supplier',
        value: '',
        labelAttrs: {
          label: '供应商',
          // rules: [
          //   {
          //     validator: validateStringNum,
          //     message: '只允许输入数字和字母',
          //     trigger: 'blur',
          //   },
          // ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入供应商名称',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'purchaser',
        value: '',
        labelAttrs: {
          label: '采购商',
          // rules: [
          //   {
          //     validator: validateStringNum,
          //     message: '只允许输入数字和字母',
          //     trigger: 'blur',
          //   },
          // ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入采购商名称',
          clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: 'export_country',
        value: null,
        labelAttrs: {
          label: '原产国',
        },
        formAttrs: {
          placeholder: '请选择原产国',
          clearable: true,
          valueKey: 'code',
          labelKey: 'title',
          options: customsCountryList,
        },
      },
      {
        type: 'selectServer',
        key: 'import_country',
        value: null,
        labelAttrs: {
          label: '目的国',
        },
        formAttrs: {
          placeholder: '请选择目的国',
          clearable: true,
          valueKey: 'code',
          labelKey: 'title',
          options: customsCountryList,
        },
      },
      {
        type: 'datePickerRange',
        key: 'date',
        startKey: 'start_time',
        endKey: 'end_time',
        value: defaultValue,
        labelAttrs: {
          label: '起始时间',
        },
        formAttrs: {
          class: 'w280',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        type: 'submit',
        labelAttrs: {
          label: '搜索',
        },
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 海关客户数据搜索
 * @param {*} type
 * @returns
 */
export function customsCustomSearchConfig(type = '') {
  console.log('type :>> ', type)

  const customsCountryList = store.getters['sys/customsCountryList']
  let defaultConfig = {
    formAttrs: {
      inline: true,
      labelWidth: '0px',
    },
    showNum: 10,
    beforeReq: (data) => {
      // if (!data.keyword) {
      //   ElMessage({
      //     showClose: true,
      //     message: '请输入关键词搜索',
      //     type: 'error',
      //   })
      //   return false
      // }
      return data
    },
    formList: [
      {
        type: 'input',
        key: 'keyword',
        value: '',
        labelAttrs: {
          // label: '产品',
          rules: [
            {
              require: true,
              message: type === 'company' ? '请输入公司名称' : '请输入产品名称',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder:
            type === 'company' ? '请输入公司名称搜索' : '请输入产品名称搜索',
          clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: 'country',
        value: null,
        // labelAttrs: {
        //   label: '',
        // },
        formAttrs: {
          placeholder: '请选择国家ISO编码',
          clearable: true,
          valueKey: 'code',
          labelKey: 'title',
          options: customsCountryList,
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  if (type === 'company') {
    defaultConfig.formList.splice(
      2,
      0,
      {
        type: 'select',
        key: 'status',
        value: null,
        // labelAttrs: {
        //   label: '',
        // },
        formAttrs: {
          placeholder: '请选择公司经营状态',
          clearable: true,
          valueKey: 'code',
          labelKey: 'title',
        },
        options: objToArr(COMPANY_STATUS, false),
      },
      {
        type: 'select',
        key: 'revenue',
        value: null,
        // labelAttrs: {
        //   label: '',
        // },
        formAttrs: {
          placeholder: '请选择公司营收范围',
          clearable: true,
          valueKey: 'code',
          labelKey: 'title',
        },
        options: objToArr(REVENUE_RANGE),
      },
      {
        type: 'select',
        key: 'employee',
        value: null,
        // labelAttrs: {
        //   label: '',
        // },
        formAttrs: {
          placeholder: '请选择公司人数',
          clearable: true,
          valueKey: 'code',
          labelKey: 'title',
        },
        options: objToArr(EMPLOYEE_RANGE),
      }
    )
  }
  return defaultConfig
}

/**
 * 店铺渠道数据搜索
 * @param {*} type
 * @returns
 */
export function shopChannelSearchConfig(type = '') {
  console.log('type :>> ', type)
  const options =
    type === 'sale'
      ? PRODUCT_SALE_CHANNEL_SUB_TYPE
      : PRODUCT_PROMOTION_CHANNEL_SUB_TYPE
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'title',
        value: '',
        labelAttrs: {
          label: '店铺',
        },
        formAttrs: {
          type: 'text',
          clearable: true,
        },
      },
      {
        type: 'select',
        key: 'sub_type',
        value: '',
        labelAttrs: {
          label: '渠道类型',
        },
        formAttrs: {
          placeholder: '请搜索/选择渠道类型',
          clearable: true,
        },
        options: objToArr(options),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  return defaultConfig
}

/**
 * 产品图册搜索
 * @param {*} type
 * @returns
 */
export function productAtlasSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
      isSearch: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          class: 'w',
          placeholder: '请输入关键词',
          clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: 'atla_id',
        labelAttrs: {
          label: '图册名称',
        },
        formAttrs: {
          clearable: true,
          filterable: true,
          listApi: `/atla/index`,
          searchKey: 'title',
          placeholder: '请选择图册名称',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 渠道搜索
 * @param {*} type
 * @returns
 */
export function channelSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = commonSearchConfig(type)
  const formList = [
    {
      type: 'cascaderServer',
      key: 'channel_type_id',
      labelAttrs: {
        label: '渠道类型',
      },
      formAttrs: {
        placeholder: '请搜索/选择渠道类型',
        clearable: true,
        filterable: true,
        'show-all-levels': true,
        listApi: '/channel-type/tree',
        props: {
          label: 'title',
          value: 'id',
          checkStrictly: true,
        },
      },
    },
    {
      type: 'select',
      key: 'visible',
      value: '',
      labelAttrs: {
        label: '数据权限',
      },
      formAttrs: {
        placeholder: '请选择数据权限',
        clearable: true,
        filterable: true,
      },
      options: objToArr(ROLE_VISIBLE_RANGE),
    },
  ]
  defaultConfig.formList.splice(1, 0, ...formList)

  return defaultConfig
}

/**
 * 员工搜索
 * @param {*} type
 * @returns
 */
export function staffSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'input',
        key: 'name',
        formAttrs: {
          type: 'text',
          placeholder: '昵称',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'tel',
        formAttrs: {
          type: 'text',
          placeholder: '电话号码',
          clearable: true,
        },
      },
      {
        type: 'selectServer',
        key: '__role_id',
        value: '',
        formAttrs: {
          listApi: 'role/index',
          labelKey: 'title',
          placeholder: '请选择角色',
        },
      },
      {
        type: 'select',
        key: 'status',
        value: 1,
        formAttrs: {
          // listApi: 'role/index',
          // labelKey: 'title',
          placeholder: '员工状态',
          clearable: true,
        },
        options: objToArr(STAFF_STATUS),
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  if (+type === 2) {
    defaultConfig.formList.splice(2, 1, {
      type: 'selectServer',
      key: 'supplier_id',
      labelAttrs: {
        label: '供应商',
      },
      formAttrs: {
        listApi: `/supplier/index`,
        clearable: true,
        valueType: 'number',
        placeholder: '请搜索/选择供应商',
      },
    })
  }

  return defaultConfig
}

/**
 * 仓储体积变化搜索
 * @param {*} type
 * @returns
 */
export function storageVolumeSearchConfig(type = '', info) {
  console.log('type :>> ', type)
  const fromSupplier = type === 'supplierList'
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    showNum: 3,
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '查询',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词',
          clearable: true,
        },
      },
      {
        type: fromSupplier ? 'slot' : 'selectServer',
        key: 'supplier_id',
        value: fromSupplier ? info?.id : '',
        labelAttrs: {
          label: '供应商',
        },
        formAttrs: {
          listApi: `/supplier/index`,
          valueType: 'number',
          multiple: false,
          searchKey: 'search',
          placeholder: '请搜索/选择供应商',
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }
  if (type === 'channel' || type === 'rivalChannel') {
    const channelTypeObj = {
      channel: {
        key: 'channel_id',
        url: '/channel/tree',
      },
      rivalChannel: {
        key: 'enemy_channel_id',
        url: '/enemy-channel/tree',
      },
    }
    defaultConfig.formList.unshift({
      type: 'cascaderServer',
      key: channelTypeObj[type].key,
      value: '',
      labelAttrs: {
        label: '渠道名称',
      },
      formAttrs: {
        placeholder: '请搜索/选择渠道名称',
        clearable: true,
        filterable: true,
        multiple: false,
        'show-all-levels': true,
        listApi: channelTypeObj[type].url,
        class: 'w',
        props: {
          label: 'title',
          value: 'id',
          checkStrictly: false,
          multiple: false,
        },
      },
    })
  }
  return defaultConfig
}
/**
 * 仓储体积变化搜索
 * @param {*} type
 * @returns
 */
export function customerSupplierProductSearchConfig(type = '', info) {
  console.log('type :>> ', type)
  const fromSupplier = type === 'supplier'
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: type === 'customerList' ? 'slot' : 'selectServer',
        key: 'supplier_id',
        value: type === 'supplier' ? info?.id : '',
        labelAttrs: {
          label: '供应商',
        },
        formAttrs: {
          disabled: type === 'supplier',
          placeholder: '请选择供应商',
          clearable: true,
          filterable: true,
          modelValueName: type === 'supplier' ? info?.title : '',
          listApi: '/supplier/index',
          props: {
            label: 'title',
            value: 'id',
          },
        },
      },
      {
        type: 'selectServer',
        key: 'product_id',
        value: type === 'product' ? info?.id : '',
        titleKey: 'title',
        labelAttrs: {
          label: '产品',
        },
        formAttrs: {
          disabled: type === 'product',
          listApi: fromSupplier ? '/supplier-product/index' : `/product/index`,
          valueType: 'number',
          searchKey: 'search',
          multiple: false,
          exLabelKeyFun: fromSupplier
            ? (item) => {
                return item.product_info.title
              }
            : '',
          valueKey: fromSupplier ? 'product_id' : 'id',
          modelValueName: type === 'product' ? info?.title : '',
          placeholder: '请输入编码/选择产品',
          exParams: fromSupplier
            ? {
                supplier_id: info.id,
              }
            : {},
        },
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 家具出入库单搜索
 * @param {*} type
 * @returns
 */
export function furnitureOrderSearchConfig(type = '', statusOptions = []) {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '关键词',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词',
          clearable: true,
        },
      },
      {
        type: 'slot',
        key: 'status',
        value: 10,
        name: 'searchStatus',
        formAttrs: {
          placeholder: '请选择在仓状态',
          clearable: true,
        },
        isButton: true,
        options: statusOptions,
      },
      {
        type: 'selectServer',
        key: 'supplier_id',
        value: '',
        titleKey: '',
        labelAttrs: {
          label: '供应商',
        },
        formAttrs: {
          listApi: 'supplier/index',
          labelKey: 'title',
          searchKey: 'search',
          placeholder: '请搜索/选择供应商',
        },
      },

      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}
/**
 * 模具组（项目开发）搜索
 * @param {*} type
 * @returns
 */
export function equipmentGroupSearchConfig(type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    formAttrs: {
      inline: true,
    },
    formList: [
      {
        type: 'input',
        key: 'search',
        labelAttrs: {
          label: '关键词',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入关键词',
          clearable: true,
        },
      },

      {
        type: 'select',
        key: 'status',
        value: '',
        labelAttrs: {
          label: '状态',
        },
        formAttrs: {
          placeholder: '请选择状态',
        },
        options: objToArr(EQUIPMENT_GROUP_STATUS),
        layoutAttrs: {
          span: 8,
        },
      },
      {
        type: 'select',
        key: 'sample_status',
        value: '',
        labelAttrs: {
          label: '样品状态',
        },
        formAttrs: {
          placeholder: '请选择状态',
        },
        options: objToArr(EQUIPMENT_GROUP_SAMPLE_STATUS),
        layoutAttrs: {
          span: 8,
        },
      },
      {
        type: 'select',
        key: 'priority',
        value: '',
        labelAttrs: {
          label: '优先级',
        },
        formAttrs: {
          placeholder: '请选择状态',
        },
        options: objToArr(EQUIPMENT_GROUP_PRIORITY),
        layoutAttrs: {
          span: 8,
        },
      },
      {
        type: 'selectServer',
        key: 'label_ids',
        labelAttrs: {
          label: '标签',
        },
        formAttrs: {
          clearable: true,
          filterable: true,
          multiple: true,
          valueType: 'number',
          listApi: `/label/index`,
          searchKey: 'title',
          placeholder: '请选择标签',
          exParams: {
            type: ['common', 'equipment_group'],
          },
        },
        layoutAttrs: {},
      },
      {
        type: 'submit',
        submit: {
          title: '搜索',
          attrs: {
            type: 'primary',
          },
        },
        reset: {
          title: '重置',
        },
      },
    ],
  }

  return defaultConfig
}
