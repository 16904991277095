<!--
 * @Author: WGL
 * @Date: 2023-04-04 16:30:24
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-11 09:46:32
 * @Description:
-->
<template>
  <div class="system-user-container base-info-tabs-wrapper">
    <!-- 适用车型 -->
    <div v-if="productInfo?.vehicles?.length && params?.type != 'common'">
      <DesHeader :title="t('适用车型')" />
      <el-table border :data="productInfo.vehicles" style="width: 100%">
        <el-table-column :label="t('品牌')" prop="make" width="200" />
        <el-table-column :label="t('车型')" prop="vehicle" width="300" />
        <el-table-column :label="t('配置')" prop="edition" width="200" />
        <el-table-column :label="t('年份')" prop="year">
          <template #default="{ row }">
            <!-- <el-tag
              v-for="(year, index) in row?.year?.split(',')"
              :key="index"
              disable-transitions
            >
              {{ year }}
            </el-tag> -->
            <el-tag v-if="row.start_year || row.end_year">
              {{ row.start_year }} - {{ row.end_year }}
            </el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 基本信息 -->
    <BaseDesTable
      class="mt15"
      :config="desTableConfig"
      :detail-info="productInfo"
      :params="params"
    >
      <template #category_titles="{ scope }">
        <template v-if="scope.category_titles">
          <el-tag
            v-for="(category, index) in scope.category_titles.split('、')"
            :key="index"
            class="mb4"
            disable-transitions
          >
            {{ category }}
          </el-tag>
        </template>
        <span v-else>-</span>
      </template>
      <!-- 默认仓库 -->
      <template #default_repository_id="{ scope }">
        <el-tag>
          {{ scope.default_repository_info?.title || '-' }}
        </el-tag>
      </template>
      <!-- 货源 -->
      <template #source="{ scope }">
        <div class="fx">
          <el-tag :type="PRODUCT_SOURCE_COLOR[scope.source]">
            {{ PRODUCT_SOURCE[scope.source] }}
          </el-tag>
          <el-tooltip
            v-if="!scope.source"
            :content="t('紧急程度')"
            effect="dark"
            :persistent="false"
            placement="bottom"
          >
            <jwt-rate-icon
              v-model="scope.urgency_star"
              class="ml5"
              color="#ff4d4f"
              disabled
              icon="fire-fill"
              void-icon="fire-line"
            />
          </el-tooltip>
        </div>
      </template>
      <!-- 紧急程度 -->
      <template #urgency_star="{ scope }">
        <jwt-rate-icon
          v-model="scope.urgency_star"
          color="#ff4d4f"
          disabled
          icon="fire-fill"
          void-icon="fire-line"
        />
      </template>
      <!-- 内容 -->
      <template #content="{ scope }">
        <div class="content-wrapper" v-html="scope.content || '-'"></div>
      </template>
      <!-- 目标市场 -->
      <template #target_area="{ scope }">
        <div>
          <el-tag
            v-for="(item, index) in showTargetAreaText(scope?.target_area_list)"
            :key="index"
            class="mb4"
          >
            {{ item }}
          </el-tag>
        </div>
      </template>
      <!-- 产品证书 -->
      <template #certificate="{ scope }">
        <div class="wbba">
          <a
            v-if="scope.certificate"
            download
            :href="fillUrl(scope.certificate, '', '', '')"
          >
            {{ fillUrl(scope.certificate, '', '', '') }}
          </a>
          <span v-else>-</span>
        </div>
      </template>
    </BaseDesTable>
    <ExtraField
      class="mt15"
      :info="productInfo"
      :params="extraFieldParams"
      show-header
    />

    <!-- 图片信息 -->
    <el-descriptions
      v-if="params?.type != 'common'"
      border
      class="margin-top mt15"
      :column="1"
      :title="t('图片信息')"
    >
      <el-descriptions-item
        class-name="rel"
        :label="t('封面图')"
        label-class-name="w100"
      >
        <div class="abs_rt" style="z-index: 1000">
          <base-header-btn
            :config="exportBtnConfig"
            :detail-info="info"
            @header-action="
              (action) => {
                handleDowload('single', action)
              }
            "
          />
        </div>
        <!-- <el-button
          v-if="productInfo.image"
          class="abs_rt"
          size="default"
          type="primary"
          @click="handleDowload('single')"
        >
          {{ t('导出原图') }}
        </el-button> -->
        <base-image class="w300" :src="productInfo.image" />
      </el-descriptions-item>
      <el-descriptions-item
        class-name="rel"
        :label="t('图集')"
        label-class-name="w100"
      >
        <div class="abs_rt" style="z-index: 1000">
          <base-header-btn
            :config="exportBtnConfig"
            :detail-info="info"
            @header-action="
              (action) => {
                handleDowload('batch', action)
              }
            "
          />
        </div>
        <!-- <el-button
          v-if="productInfo.slider_image"
          class="abs_rt"
          size="default"
          style="z-index: 1000"
          type="primary"
          @click="handleDowload('batch')"
        >
          {{ t('导出原图') }}
        </el-button> -->
        <base-image class="w300" multiple :src="productInfo.slider_image" />
      </el-descriptions-item>
    </el-descriptions>
    <!-- 视频信息 -->
    <el-descriptions
      v-if="params?.type != 'common'"
      border
      class="margin-top mt15"
      :column="1"
      :title="t('视频信息')"
    >
      <el-descriptions-item :label="t('视频')" label-class-name="w100">
        <video
          v-if="productInfo.video"
          class="w240"
          controls
          :src="fillUrl(productInfo.video, '', '', '')"
        ></video>
        <div v-else class="g6">{{ t('暂无信息~') }}</div>
      </el-descriptions-item>
    </el-descriptions>
    <PosterDesign ref="PosterDesignRef" @saveImg="handleSaveImg" />
    <SelectProductImgChannel
      ref="SelectProductImgChannelRef"
      @success="handleSaveImgChannel"
    />
  </div>
</template>

<script>
  import {
    defineComponent,
    onBeforeMount,
    computed,
    ref,
    reactive,
    toRefs,
  } from 'vue'
  import { ElLoading } from 'element-plus'
  import { getImageOrg } from '@/api/tool'
  // import ProductPriceStrategyList from './productPriceStrategyList.vue' // 价格策略
  import ExtraField from '@/components/ExtraField' // 扩展信息
  import SelectProductImgChannel from './selectProductImgChannel.vue'

  import { t } from '@/utils/i18n'
  import {
    PRODUCT_SOURCE_COLOR,
    PRODUCT_SOURCE,
  } from '@/config/variable.config'
  import { fillUrl, downloadIamge } from '@/common/js/utils'
  import { productDesTable } from '@/utils/desTableConfig'
  import { saveAs } from 'file-saver'
  import JSZip from 'jszip'

  const MODULE = 'product' // 模块
  export default defineComponent({
    name: 'ProductDetailBase',
    components: {
      // ProductPriceStrategyList,
      ExtraField,
      SelectProductImgChannel,
    },
    props: {
      info: { type: Object, default: () => {} },
      params: { type: Object, default: () => {} },
    },
    setup(props) {
      const refProductPriceStrategyList = ref(null)
      const productInfo = computed(() => {
        return props.params.product || props.info
      })
      const state = reactive({
        imageType: 'single', // 单图
      })
      let loading = null
      const PosterDesignRef = ref(null)
      const SelectProductImgChannelRef = ref(null)
      const exportBtnConfig = {
        list: ['exportOrgImg', 'imgDesign'],
      }

      // 添加价格策略
      const btnConfig = {
        addProductPricePolicy: {
          type: 'editBox',
        },
        list: ['addProductPricePolicy'],
      }
      const desTableConfig = computed(() => {
        return productDesTable('', props?.params?.type || '')
      })
      // 价格配置
      const desTablePriceConfig = computed(() => {
        return productDesTable('', 'price')
      })

      const priceStrategyParams = computed(() => {
        return {
          from: 'productList',
          refIdKey: 'product_id',
          searchKey: 'product_id',
          typeVal: 1,
        }
      })

      const extraFieldParams = computed(() => {
        return {
          module: MODULE,
        }
      })
      onBeforeMount(() => {})

      const handleDowloadBatch = (urlArr) => {
        console.log('urlArr', urlArr)
        const zip = new JSZip()
        const promises = []
        for (let i = 0; i < urlArr.length; i++) {
          const item = urlArr[i]
          if (item) {
            const promise = fetch(item)
              .then((response) => {
                if (!response.ok) {
                  throw new Error('Network response was not ok')
                }
                // 读取响应内容并返回一个 Promise，其中包含响应内容的文本数据

                // return response.arrayBuffer()
                return response.blob()
              })
              .then((data) => {
                const nameType = item.split('?').shift().split('.').pop()
                console.log('nameType', nameType)
                zip.file(`${i}.${nameType || 'png'}`, data, { binary: true })
              })
            promises.push(promise)
          }
        }
        Promise.allSettled(promises)
          .then((result) => {
            console.log('result :>> ', result)
            zip.generateAsync({ type: 'blob' }).then((content) => {
              loading.close()
              saveAs(content, `${productInfo.value.title}-${t('图集')}.zip`)
            })
          })
          .catch((err) => {
            console.log('err', err)
          })
      }

      const handleGetImageOriginal = (urls, type) => {
        const listUrl = urls.split(',')
        const params = {
          urls: listUrl,
          product_id: productInfo.value.id,
        }
        getImageOrg(params).then((res) => {
          console.log('res', res)
          if (type === 'single') {
            downloadIamge(
              res[0],
              `${productInfo.value.title}-${t('封面图')}.png`
            )
          } else if (type === 'batch') {
            loading = ElLoading.service({
              lock: true,
              text: 'Loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })
            handleDowloadBatch(res)
          }
        })
      }
      // 导出图片
      const handleDowload = (type, action) => {
        let urls = ''
        state.imageType = type
        if (type === 'single') {
          urls = productInfo.value.image
        } else if (type === 'batch') {
          urls = productInfo.value.slider_image
          // handleDowloadBatch()
        }
        if (action.code === 'exportOrgImg') {
          handleGetImageOriginal(urls, type)
        } else {
          handleHeaderAction(action)
        }
      }

      const showCountryText = (data, result = []) => {
        if (data?.parent_info) {
          result.push(data.title)
          showCountryText(data.parent_info, result)
        } else {
          result.push(data?.title)
        }
        return result.join('/')
      }

      // 目标市场
      const showTargetAreaText = (data, result = []) => {
        result = data?.map((item) => {
          return showCountryText(item)
        })
        return result
      }

      const handleHeaderAction = (action, selectionRow, drawerInfo) => {
        console.log('action :>> ', action)
        console.log('selectionRow :>> ', selectionRow)
        console.log('drawerInfo :>> ', drawerInfo)
        if (action.code === 'addProductPricePolicy') {
          console.log(
            'refProductPriceStrategyList :>> ',
            refProductPriceStrategyList
          )
          refProductPriceStrategyList.value.tableRef.jwtTableRef.handleEdit(
            { ...props.info, mode: 1 },
            btnConfig.addProductPricePolicy
          )
        } else if (action.code === 'imgDesign') {
          // 图片设计
          const listUrl =
            state.imageType === 'single'
              ? [productInfo.value.image]
              : productInfo.value.slider_image.split(',')
          const params = {
            urls: listUrl,
            product_id: productInfo.value.id,
          }
          getImageOrg(params).then((res) => {
            PosterDesignRef.value.showPosterDesign({
              ...productInfo.value,
              slider_image:
                state.imageType === 'single' ? res[0] : res.join(','),
            })
          })
        }
      }

      const handleSaveImg = (data) => {
        if (state.imageType === 'single') {
          PosterDesignRef.value.closePosterDesign()
        }
        SelectProductImgChannelRef.value.showDialogVisible({
          openData: productInfo.value,
          imageType: state.imageType,
          data,
        })
        console.log('data :>> handleSaveImg', data)
      }
      return {
        refProductPriceStrategyList,
        PRODUCT_SOURCE_COLOR,
        PRODUCT_SOURCE,
        productInfo,
        desTableConfig,
        desTablePriceConfig,
        priceStrategyParams,
        extraFieldParams,
        btnConfig,
        exportBtnConfig,
        PosterDesignRef,
        SelectProductImgChannelRef,
        ...toRefs(state),
        fillUrl,
        handleDowload,
        showTargetAreaText,
        t,
        handleHeaderAction,
        handleSaveImg,
      }
    },
  })
</script>
<style lang="scss" scoped>
  .system-user-container {
    :deep() {
      .content-wrapper {
        img {
          max-width: 80%;
          max-height: 500px;
          text-align: center;
        }
      }
    }
  }
</style>
