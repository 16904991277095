<!--
 * @Author: WGL
 * @Date: 2023-05-19 18:20:23
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-11 09:30:13
 * @Description:
-->
<template>
  <el-card
    :body-style="{
      paddingTop: '10px',
      paddingLeft: '30px',
      paddingBottom: '10px',
    }"
    shadow="none"
    v-bind="attrs"
  >
    <div class="step-opt-wrapper fx">
      <div
        v-for="(item, index) in options"
        :key="index"
        class="step-opt-item"
        :class="{
          active: index <= appModel + 0,
          'next-active': index === appModel + 1 && !endStageStatus,
          'disabled-active': endStageStatus,
          'last-step':
            endStageStatus &&
            endStageStatus === 'success' &&
            options.length - 1 === index,
        }"
        @click="handleChangeStep(item, index)"
      >
        <el-popover
          v-if="index === options.length - 1 && !endStage"
          :persistent="false"
          placement="bottom"
          trigger="hover"
        >
          <template #reference>
            <div class="step-opt-item-text">
              <jwt-icon icon="arrow-down-s-line" />
              <span>{{ item.label }}</span>
            </div>
          </template>
          <div>
            <div
              v-for="(v, i) in endOptions"
              :key="i"
              class="fxmj ptb5 hover cp"
              @click="handleSelectEndStep(v)"
            >
              <div>
                <jwt-icon v-if="v.icon" :class="v.class" :icon="v.icon" />
                <span>{{ v.label }}</span>
              </div>
              <div>{{ v.subTitle }}</div>
            </div>
          </div>
        </el-popover>
        <div v-else class="step-opt-item-text">
          <jwt-icon
            v-if="index === options.length - 1 && endStageIcon"
            :icon="endStageIcon"
          />
          {{ item.label }}
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
  import { defineComponent, onMounted, computed, reactive, toRefs } from 'vue'
  import { ElMessageBox } from 'element-plus'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'StepOpt',
    components: {},
    inheritAttrs: false,
    props: {
      modelValue: { type: [String, Number], default: 0 },
      attrs: { type: Object, default: () => {} },
      options: { type: Array, default: () => [] }, // 选项
      endOptions: { type: Array, default: () => [] },
      endStage: { type: [String, Number], default: null },
    },
    emits: ['update:modelValue', 'change', 'select'],
    setup(props, { emit }) {
      const state = reactive({})
      const appModel = computed({
        get: () => {
          const index = props.options.findIndex(
            (item) => item.value === +props.modelValue
          )
          console.log('index :>> ', index)
          return index >= 0 ? index : 0
        },
        set: (value) => {
          emit('update:modelValue', value)
        },
      })
      const endStageStatus = computed(() => {
        if (props.endStage) {
          if (+props.endStage === props?.endOptions[0]?.value) {
            return 'success'
          } else {
            return 'fail'
          }
        }
        return ''
      })
      const endStageIcon = computed(() => {
        if (props.endStage) {
          const stage = props?.endOptions?.find((item) => {
            return item.value === +props.endStage
          })
          console.log('stage :>> ', stage)
          return stage?.icon || ''
        }
        return ''
      })

      const handleChangeStep = (item, index) => {
        if (index === props?.options?.length - 1 || props.endStage) {
          return
        }
        console.log('item :>> ', item)
        console.log('index :>> ', index)
        ElMessageBox.confirm(
          `${t('确定进入')}${item.label}${t('阶段')}？`,
          t('提示'),
          {
            confirmButtonText: t('确定'),
            cancelButtonText: t('取消'),
            type: 'warning',
          }
        )
          .then(() => {
            emit('change', item.value)
          })
          .catch(() => {})
      }

      const handleSelectEndStep = (item) => {
        console.log('item :>> ', item)
        emit('select', item.value)
      }

      onMounted(() => {})
      return {
        ...toRefs(state),
        appModel,
        endStageStatus,
        endStageIcon,
        handleSelectEndStep,
        handleChangeStep,
      }
    },
  })
</script>

<style lang="scss" scoped>
  $height: 38px;
  $primaryColor: var(--el-color-primary);
  .step-opt-wrapper {
    .step-opt-item {
      position: relative;
      color: var(--el-text-color-primary);
      margin: 5px 5px 5px 0;
      height: $height;
      border-top: 2px solid var(--el-border-color);
      border-bottom: 2px solid var(--el-border-color);
      // box-sizing: border-box;
      background-color: #fff;
      cursor: pointer;

      &:last-child {
        z-index: 0;
        &::after {
          border-radius: 19px;
          transform: rotate(45deg);
          right: -15px;
          z-index: -1;
          background-color: transparent;
        }
        // &.disabled-active {
        //   background-color: var(--el-border-color);
        // }
      }

      &.disabled-active {
        background-color: var(--el-border-color);
        border-color: var(--el-border-color);
        &::after,
        &::before {
          border-color: var(--el-border-color);
          // background-color: var(--el-border-color);
        }
        &::after {
          background-color: var(--el-border-color);
        }
      }
      &.active,
      &.last-step {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
        &::after {
          background-color: $primaryColor;
          border-color: $primaryColor;
        }
        &::before {
          border-color: $primaryColor;
        }
      }
      &:first-child {
        &::before {
          background-color: $primaryColor;
          border-color: $primaryColor;
          border-radius: 19px;
          transform: rotate(45deg);
        }
      }
      &.next-active {
        border-color: $primaryColor;
        color: $primaryColor;
        &::after,
        &::before {
          border-color: $primaryColor;
        }
      }

      &::after,
      &::before {
        position: absolute;
        content: '';
        top: -2px;
        width: $height;
        height: $height;
        box-sizing: border-box;
        border-top: 2px solid var(--el-border-color);
        border-right: 2px solid var(--el-border-color);
        transform: rotate(45deg) scale(0.707);
      }
      &::before {
        left: -19px;
        background-color: #fff;
      }
      &::after {
        right: -19px;
        // background-color: $primaryColor;
        z-index: 2;
        background-color: #fff;
      }
      .step-opt-item-text {
        padding: 10px 20px;
        margin-top: -2px;
      }
    }
  }
</style>
