<!--
 * @Author: WGL
 * @Date: 2023-06-19 09:06:21
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-10 14:24:22
 * @Description: 
-->
<template>
  <div :class="[isScrollbarBlodCompany ? 'scrollbar-blod-company' : '']">
    <jwt-table
      ref="jwtTableRef"
      :config="tableConfig"
      :freeze-list="freezeList"
      :page-url="pageUrl"
      :ref-menu-id="refMenuId"
      :route="route"
      :router="router"
      :table-column="tableColumn"
      :table-height="tableHeight === 'auto' ? null : tableHeight"
      :user-field-list="userFieldList"
      :user-menus-auth="userMenusAuthObj"
      @add-handle="handleAddHandle"
      @add-success="handleAddSuccess"
      @asynExport="handleAsynExportFile"
      @change-table-column="handleChangeTableColumn"
      @click-cell="handleClickRow"
      @delete-success="handleDeleteSuccess"
      @des-edit-success="hadnleDesEditSuccess"
      @dialog-close="handleDialogClose"
      @edit-handle="handleEditHandle"
      @edit-success="handleEditSuccess"
      @event-name="handleComponentEvent"
      @form-item-change="handleItemChange"
      @get-detail-success="handleGetDetailSuccess"
      @header-action="handleHeaderAction"
      @list-change="handleListChange"
      @refresh-draw="handleRefreshDraw"
      @refresh-table="handleRefreshTable"
      @search-result="handleSearchResult"
      @selection-change="handleSelectionChange"
      @table-action="handleTableAction"
      @table-current-change="handleTableCurrentChange"
      @tablelist-change="handleTablelistChange"
      @upload-btn="handleUploadBtn"
    >
      <template
        v-for="k in Object.keys(slots)"
        #[k]="{ scope, model, data, list, index }"
        :key="k"
      >
        <slot
          :data="data"
          :index="index"
          :list="list"
          :model="model"
          :name="k"
          :scope="scope"
        ></slot>
      </template>
      <template #drawerCoustom>
        <el-button
          class="close-btn"
          :icon="CloseBold"
          type="danger"
          @click="handleCloseDrawer"
        />
      </template>
    </jwt-table>
  </div>
  <dialog-comment
    v-if="config?.editBox?.form?.api?.bindModule"
    :dialog-title="dialogCommentTitle"
    :dialog-visible="dialogCommentVisible"
    :edit-content="editCommentContent"
    :ref-id="refId"
    :ref-type="refType"
    :source-id="rowId"
    @addSuccessStory="handleBindStory"
    @close="handleDialogCommentClose"
  >
    <slot name="dialog-comment-header" :row="openRow"></slot>
  </dialog-comment>
  <!-- <dialog-material
      v-if="showDialogMaterial"
      ref="dialogMaterialRef"
      :multiple="dialogMaterialInfo?.formAttrs?.multiple"
      @change="handleSelectMaterial"
    /> -->
  <el-dialog
    v-model="showDialogMaterial"
    center
    destroy-on-close
    :title="translateTitle('素材选择')"
    top="7vh"
    width="80%"
  >
    <jwt-material
      ref="dialogMaterialRef"
      :config="materialConfig"
      :multiple="dialogMaterialInfo?.formAttrs?.multiple || false"
      :request="request"
      :translate-title="translateTitle"
      used
      @on-delete="handelDelete"
      @select="handleSelectMaterial"
    />
  </el-dialog>
  <excel-template-box
    v-if="excelTemplateBoxDefaultType"
    ref="excelTemplateBox"
    :api="excelTemplateBoxParams.tableExportApi"
    :default-type="excelTemplateBoxDefaultType"
    :params="excelTemplateBoxParams.tableListParams"
    :print-api="excelTemplateBoxParams.tablePrintApi"
  />
</template>

<script>
  import {
    defineComponent,
    ref,
    reactive,
    onBeforeMount,
    toRefs,
    computed,
    useSlots,
    onMounted,
    toRaw,
    nextTick,
    watch,
    onBeforeUnmount,
  } from 'vue'
  import request, { handleErrCode } from '@/utils/request'

  import { baseURL } from '@/config'
  import { useStore } from 'vuex'
  import {
    SCROLLBAR_BLOD_COMPANY,
    SCROLLBAR_BLOD_PHONE,
  } from '@/config/variable.config'
  import { uploadUrl, uploadRichTextUrl } from '@/config/index'
  import { useRouter, useRoute } from 'vue-router'
  // import AppForm from '@/components/AppForm'
  import dayjs from 'dayjs'
  import { translateTitle } from '@/utils/i18n'
  import { CloseBold } from '@element-plus/icons-vue'
  import { handleAsynExportFiles, getMemoryUsage } from '@/utils/business'
  // import { ElMessage } from 'element-plus'
  import { getDeepVal } from '@/utils/index'
  import ExcelTemplateBox from '@/views/setting/templateSetting/components/excelTemplateBox.vue'
  import { isFunction } from 'lodash-es'

  export default defineComponent({
    name: 'BaseTable',
    components: {
      ExcelTemplateBox,
    },
    props: {
      config: { require: true, type: Object, default: () => {} },
      tableHeight: { type: [String, Number], default: '73vh' },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
      pageUrl: { type: String, default: '' }, // 关联的菜单Url
      tableColumn: { type: Array, default: () => [] }, // 表格列
      freezeList: { type: Boolean, default: true },
    },
    emits: [
      'selection-change',
      'list-change',
      'header-action',
      'table-action',
      'add-handle',
      'edit-handle',
      'add-success',
      'edit-success',
      'delete-success',
      'table-current-change',
      'import-goods',
      'tablelist-change',
      'click-func',
      'get-detail-success',
      'des-edit-success',
      'event-name',
      'click-cell',
      'search-result',
      'dialog-close',
      'form-item-change',
    ],
    setup(props, { emit }) {
      let timer = null
      const jwtTableRef = ref(null)
      const dialogMaterialRef = ref(null)
      const excelTemplateBox = ref(null)
      const excelTemplateBoxParams = ref({})
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const slots = useSlots()
      const userInfo = computed(() => store.getters['user/userInfo'])
      console.log('userInfo', userInfo.value)
      const userMenusAuthObj = computed(() => store.getters['routes/menusObj'])
      console.log('userMenusAuthObj :>> ', userMenusAuthObj)
      // 用户的列表字段
      const userFieldList = computed(() => store.getters['sys/userFieldList'])
      // 公共drawer是否打开
      const openDrawer = computed(() => store.getters['sys/hasOpenDrawer'])
      // 是否需要刷新列表
      const refreshTable = computed(() => store.getters['sys/refreshTable'])

      // 配置
      const tableConfig = computed(() => {
        return {
          beforeExportFun: beforeExportFun,
          tableButtonNum: 3,
          useField: true, // 是否使用配置字段显示列表数据
          keepAliveTabs: false, // 是否缓存tabs标签页内容
          tableExportPathKey: 'http_path',
          uploadSuccessCb: (res) => {
            handleErrCode(res.code, res.msg, res)
          },
          ...props.config,
        }
      })

      // 需要滚动条加粗的公司 | 电话
      const isScrollbarBlodCompany = computed(() => {
        if (
          userInfo.value?.tel &&
          SCROLLBAR_BLOD_PHONE.includes(userInfo.value?.tel)
        ) {
          return true
        }
        return SCROLLBAR_BLOD_COMPANY.includes(userInfo.value?.company_id)
      })
      // 文件导出之前处理函数
      const beforeExportFun = (url, rowAction) => {
        console.log('url :>> ', url)
        console.log('rowAction :>> ', rowAction)
        // const exportIndex = asynExportFileList.value.findIndex(
        //   (item) => item.url === url
        // )
        // if (exportIndex >= 0) {
        //   ElMessage.error(translateTitle('文件已存在下载队列中！'))
        // }
        // return exportIndex >= 0
        return false
      }

      const { query } = route

      if (tableConfig.value?.tableFileImport?.action) {
        tableConfig.value.tableFileImport.action =
          baseURL + tableConfig.value.tableFileImport.action
      }

      const state = reactive({
        dialogCommentTitle: '',
        dialogCommentVisible: false,
        refType: null,
        refId: null,
        rowId: null,
        editCommentContent: false,
        openRow: {},
        loading: false,
        submitLoading: false,
        dialogMaterialInfo: {},
        showDialogMaterial: false,
        searchData: {},
        excelTemplateBoxDefaultType: '',
      })

      let materialConfig = reactive({
        categoryApi: '/material-category/tree', // 素材分类接口
        categoryAdd: '/material-category/add', // 素材分类添加接口
        categoryEdit: '/material-category/edit', // 素材分类编辑接口
        categoryDelete: '/material-category/delete', // 素材分类删除接口
        listApi: '/material-file/index', // 素材资源列表接口
        showPasteUpload: true, // 是否显示粘贴上传
        deleteApi: '/material-file/delete', // 删除接口
        updateApi: '/material-file/edit', // 编辑接口
        uploadApi: '/material-file/uploadFile', // 上传接口
        mattingApi: '', // 抠图接口
        urlKey: 'file_path',
        searchKey: 'name',
        tabList: ['img'],
      })
      // 当前table的配置数据
      const appConfig = computed(() => jwtTableRef.value.appConfig)

      // 绑定故事
      const handleBindStory = (result) => {
        const { bindModule } = appConfig.value.editBox.form.api
        if (!bindModule) return
        const { id } = result
        const data = {
          id: state.rowId,
          story_id: id,
        }
        request({
          url: `/${bindModule}/bindStory`,
          method: 'post',
          data,
        }).then(() => {
          onSearchSubmit()
        })
      }

      const handleDialogCommentClose = () => {
        state.dialogCommentVisible = false
      }

      /**
       * 提交搜索
       */
      const onSearchSubmit = () => {
        jwtTableRef.value.onSearchSubmit()
      }

      /**
       * 重置搜索
       */
      const handleSearchFormReset = () => {
        jwtTableRef.value.handleSearchFormReset()
      }
      /**
       * 勾选列表回调
       * @param val
       */
      const handleSelectionChange = (val) => {
        emit('selection-change', val)
      }

      // 列表数据改变
      const handleListChange = () => {
        emit('list-change')
      }

      // 页头按钮事件
      const handleHeaderAction = (
        action,
        selectionRow,
        drawerInfo = {},
        rowAction = {}
      ) => {
        getMemoryUsage(2)
        console.log('action :>> handleHeaderAction', action)
        console.log('rowAction :>> handleHeaderAction', rowAction)
        console.log('handleHeaderAction tableConfig', tableConfig.value)
        const { code } = action
        const exParams = isFunction(rowAction?.params)
          ? rowAction?.params?.(drawerInfo, selectionRow)
          : rowAction?.params || {}
        excelTemplateBoxParams.value = {
          tableExportApi:
            rowAction?.api?.url || tableConfig.value.tableExportApi,
          tableListParams: {
            ...(tableConfig.value.tableListParams || {}),
            ...(state.searchData || {}),
            ...exParams,
          },
          tablePrintApi: rowAction?.api?.url || tableConfig.value.tablePrintApi,
        }
        let currentModule =
          tableConfig?.value?.tableListApi?.split('/')[1] || ''
        if (currentModule?.endsWith('-detail')) {
          currentModule = currentModule.substring(0, currentModule.length - 7)
        }
        console.log('proprsdefaultType currentModule', currentModule)
        if (code === 'authorize') {
          request({
            url: '/shop/getAuthorizeUrl',
            method: 'get',
          }).then((res) => {
            window.open(
              res.url,
              '_blank',
              'top=300,left=300,width=800,height=500,menubar=no,toolbar=no,titlebar=no,status=no,scrollbars=yes,location=no'
            )
          })
        } else if (code === 'Print') {
          state.excelTemplateBoxDefaultType = currentModule
          nextTick(() => {
            excelTemplateBox.value.action = 'print'
            excelTemplateBox.value.handleShowDialog()
          })
        } else if (code.startsWith('Export')) {
          state.excelTemplateBoxDefaultType = currentModule
          nextTick(() => {
            excelTemplateBox.value.action = 'export'
            excelTemplateBox.value.handleShowDialog()
          })
        } else if (rowAction.type === 'openDrawerEdit') {
          // 打开弹窗
          handleOpenDraw(drawerInfo, rowAction)
        }
        emit('header-action', action, selectionRow, drawerInfo)
      }

      // 列表按钮事件
      const handleTableAction = (action, row, rowAction, data) => {
        getMemoryUsage(2)
        const { code } = action
        switch (code) {
          case 'click':
            //自定义按钮点击事件
            emit('click-func', { row: row })
            break
          case 'story':
            if (row?.story_info[0]?.id) {
              state.dialogCommentTitle = row?.story_info[0]?.title || ''
              state.refId = row?.story_info[0]?.id
              state.rowId = row.id
              const editCommentContent =
                row?.story_info[0]?.user_list?.includes(userInfo.value.id) ||
                userInfo.value.id === row?.story_info[0]?.user_id ||
                row?.story_info[0]?.user_id === 0
              state.editCommentContent = editCommentContent // 能否编辑
            } else {
              console.log('story first')
              state.dialogCommentTitle = '新增故事'
              state.refId = ''
              state.rowId = row.id
              state.editCommentContent = false
            }
            state.dialogCommentVisible = true
            state.openRow = row
            break
          case 'linkDetail':
            // 查看
            if (rowAction.publicDrawerConfig) {
              if (
                rowAction.publicDrawerConfig?.api?.valueKey &&
                !row[rowAction.publicDrawerConfig?.api?.valueKey]
              ) {
                return
              }
              handleOpenDraw(row, rowAction)
            }
            break
          default:
            if (
              rowAction?.type === 'openDrawerEdit' ||
              rowAction?.publicDrawerConfig
            ) {
              // 打开弹窗
              handleOpenDraw(row, rowAction)
            }
            emit('table-action', action, row, rowAction, data)
            break
        }
      }

      // 单元格点击
      const handleClickRow = async (row, item) => {
        getMemoryUsage(2)
        if (item.publicDrawerConfig) {
          if (
            item.publicDrawerConfig?.api?.valueKey &&
            !getDeepVal(item.publicDrawerConfig?.api?.valueKey, row)
          ) {
            return
          }
          const open = () => {
            handleOpenDraw(row, item)
          }
          if (
            item.publicDrawerConfig.beforeOpenFun &&
            isFunction(item.publicDrawerConfig.beforeOpenFun)
          ) {
            item.publicDrawerConfig.beforeOpenFun(row, item, open)
            return
          }
          open()
        } else if (item.linkConfig) {
          const config = item.linkConfig(row)
          router.push({
            path: config.url,
            query: { ...(config.query || {}), fromJump: 'page' },
          })
        }
        emit('click-cell', row, item)
      }

      // 添加点击事件
      const handleAddHandle = (selectionRow) => {
        emit(`add-handle`, selectionRow)
      }

      // 编辑点击事件
      const handleEditHandle = (row) => {
        emit(`edit-handle`, row)
      }

      // 添加请求
      const handleAddSuccess = (data) => {
        emit('add-success', data)
      }

      // 编辑请求
      const handleEditSuccess = (data) => {
        emit('edit-success', data)
      }

      // 删除请求
      const handleDeleteSuccess = () => {
        emit('delete-success')
      }

      /**
       * 表格选择单行数据时
       * @param val
       */
      const handleTableCurrentChange = (currentRow) => {
        // console.log('currentRow', currentRow)
        emit('table-current-change', currentRow)
      }

      const handleTablelistChange = (data) => {
        emit('tablelist-change', data)
      }

      const hadnleDesEditSuccess = (res, code) => {
        emit('des-edit-success', res, code)
      }

      // 获取详情
      const handleGetDetailSuccess = (data) => {
        emit('get-detail-success', data)
      }

      // 表格数据
      const tableData = computed(() => jwtTableRef.value.tableData)
      const jwtTableRefs = computed(() => jwtTableRef.value.tableRef)
      // 当前编辑弹窗数据
      const editBoxRowTemp = computed(() => jwtTableRef.value.editBoxRefModel)

      const initSearch = (param = {}) => {
        jwtTableRef.value.initSearch(param)
      }

      const getList = (param) => {
        jwtTableRef.value.getList(param)
      }
      const handleCloseDrawer = () => {
        jwtTableRef.value.handleCloseDrawer()
      }

      const handleRefreshDrawerDetail = (id = '') => {
        jwtTableRef.value.handleRefreshDrawerDetail(id)
      }
      // 上传按钮
      const handleUploadBtn = (item) => {
        state.showDialogMaterial = true
        state.dialogMaterialInfo = item
        if (item.type === 'uploadVideo') {
          materialConfig.tabList = ['video']
        } else if (item.type === 'uploadImg') {
          materialConfig.tabList = ['img']
        }
        console.log('handleUploadBtn item :>> ', item)
        // dialogMaterialRef.value.dialogVisible = true
      }

      const handleSelectMaterial = (data) => {
        const {
          key,
          formAttrs: { multiple },
        } = state.dialogMaterialInfo
        const result = data.map((item) => item.file_path)
        handleSetFormModelVal(key, (model) => {
          let val = result.join(',')
          if (multiple && model[key]) {
            val = model[key]?.length ? `${model[key]},${val}` : val
          }
          return val
        })
        state.showDialogMaterial = false
        dialogMaterialRef.value.handleCancelSelect()
      }

      // 图片删除
      const handelDelete = () => {}

      const handleComponentEvent = (...args) => {
        emit('event-name', ...args)
      }

      // 动态组件的实例
      const dynamicsTabsInstance = computed(() => {
        if (jwtTableRef.value) {
          return jwtTableRef.value?.dynamicsTabsInstance
        }
        return null
      })

      // 动态组件表格重置
      const dynamicsTabsTableInitSearch = () => {
        dynamicsTabsInstance.value.tableRef?.initSearch()
      }
      // 改变tabs
      const handleChangeTab = (name) => {
        jwtTableRef.value?.componentTabsRef?.handleChangeTab(name)
      }

      const setFormModelVal = (key, val) => {
        jwtTableRef.value.setFormModelVal(key, val)
      }

      const handleSetFormModelVal = (key, callBack) => {
        jwtTableRef.value.handleSetFormModelVal(key, callBack)
      }

      const handleSetSearchModelVal = (key, val) => {
        jwtTableRef.value.handleSetSearchModelVal(key, val)
      }

      // 打开侧边栏详情
      const handleOpenDrawerDetail = () => {
        // if (query.__id) return
        if (query.__id && !store.getters['sys/hasOpenDrawer']) {
          const tableColumnItemDrawer = tableConfig.value.tableColumn.find(
            (item) => item.drawerConfig
          )
          // console.log('tableColumnItemDrawer :>> ', tableColumnItemDrawer)
          jwtTableRef.value.handleClickRow(
            {
              id: query.__id,
            },
            tableColumnItemDrawer
          )
          store.commit('sys/setOpenDrawer', true)
        }
      }

      /**
       * 打开侧边抽屉
       * @param {*} row
       * @param {*} item
       */
      const handleOpenDraw = (row, item) => {
        store.commit('sys/setOpenDrawer', true)
        const newItem = {
          ...item,
        }
        if (
          item?.publicDrawerConfig?.openName &&
          isFunction(item?.publicDrawerConfig?.openName)
        ) {
          newItem.publicDrawerConfig.openName =
            item?.publicDrawerConfig?.openName(row)
        }
        // 抽屉属性配置
        nextTick(() => {
          store.commit('sys/setOpenPageDrawerConfig', {
            row: toRaw(row),
            item: toRaw(newItem),
          })
        })
      }

      // 文件导出异步
      const handleAsynExportFile = (url, data) => {
        handleAsynExportFiles(url, data)
        // console.log('url :>> handleAsynExportFile', url)
        // console.log('data :>> handleAsynExportFile', data)
        // console.log('handleAsynExportFile :>> ', asynExportFileList.value)
        // store.commit('sys/setAsynExportFileList', [
        //   {
        //     url,
        //     ...data,
        //   },
        //   ...asynExportFileList.value,
        // ])
      }

      const handleSearchResult = (data) => {
        state.searchData = data
        emit('search-result', data)
      }

      // 控制列表可显示字段
      const handleChangeTableColumn = (type, data) => {
        console.log('type :>> ', type)
        console.log('data :>> ', data)
        let params = {}
        let url = `/admin-field/edit`
        if (type === 'toggle') {
          params.field_id = data.id
          params.is_show = data.checked ? 0 : 1
        } else if (type === 'fixed') {
          params.field_id = data.id
          params.is_fixed = data.is_fixed ? 0 : 1
        } else if (type === 'width') {
          params.field_id = data?.column?.id
          params.width = data.width
        } else if (type === 'draggable') {
          url = '/admin-field/ranks'
          const result = []
          data.map((item, index) => {
            if (!item.isOperate) {
              result.push({
                field_id: item.id,
                rank: index,
              })
            }
          })
          params.ranks = result
        }
        request({
          url,
          method: 'post',
          data: params,
        }).then(() => {
          store.dispatch('sys/getUserFieldList')
        })
      }

      const handleDialogClose = () => {
        emit('dialog-close')
      }

      // 获取form提交数据
      const getSubmitData = () => {
        return jwtTableRef.value.getSubmitData()
      }

      const handleDoLayoutTable = () => {
        jwtTableRef.value?.handleDoLayoutTable()
      }

      /**
       * 刷新详情弹窗
       */
      const handleRefreshDraw = () => {
        store.commit('sys/setRefreshDrawerDetail', Math.random() * 100)
      }

      /**
       * 刷新列表
       */
      const handleRefreshTable = () => {
        if (!openDrawer.value) return
        console.log('openDrawer :>> handleRefreshTable')
        // 防止数据一直为true时不刷新
        store.commit('sys/setRefreshTable', false)
        nextTick(() => {
          store.commit('sys/setRefreshTable', true)
        })
      }

      const handleItemChange = (val, item, model) => {
        emit('form-item-change', val, item, model)
      }
      onBeforeMount(() => {})

      onMounted(() => {
        timer = setTimeout(() => {
          handleOpenDrawerDetail()
        }, 300)
      })

      const stopWatching = watch(openDrawer, (newValue, oldValue) => {
        console.log('openDrawer :>> watch', newValue, oldValue)
        // 弹窗关闭并且需要刷新页面
        if (oldValue && !newValue && refreshTable.value) {
          initSearch()
          store.commit('sys/setRefreshTable', false) // 刷新
        }
      })

      onBeforeUnmount(() => {
        clearTimeout(timer)
        stopWatching()
      })

      return {
        tableConfig,
        request,
        uploadUrl,
        uploadRichTextUrl,
        appConfig,
        jwtTableRef,
        jwtTableRefs,
        dayjs,
        slots,
        route,
        router,
        userMenusAuthObj,
        tableData,
        CloseBold,
        materialConfig,
        editBoxRowTemp,
        dialogMaterialRef,
        dynamicsTabsInstance,
        excelTemplateBox,
        excelTemplateBoxParams,
        userFieldList,
        isScrollbarBlodCompany,
        translateTitle,
        ...toRefs(state),
        handleSelectionChange,
        handleListChange,
        handleTableCurrentChange,
        handleHeaderAction,
        handleTableAction,
        handleAddHandle,
        handleEditHandle,
        handleEditSuccess,
        handleAddSuccess,
        handleDeleteSuccess,
        handleTablelistChange,
        handleGetDetailSuccess,
        handleDialogCommentClose,
        handleBindStory,
        getList,
        initSearch,
        handleCloseDrawer,
        hadnleDesEditSuccess,
        handleUploadBtn,
        handleSelectMaterial,
        handelDelete,
        handleRefreshDrawerDetail,
        handleComponentEvent,
        dynamicsTabsTableInitSearch,
        handleChangeTab,
        setFormModelVal,
        handleSetFormModelVal,
        handleClickRow,
        handleAsynExportFile,
        handleSearchResult,
        handleChangeTableColumn,
        handleDialogClose,
        handleSearchFormReset,
        getSubmitData,
        handleDoLayoutTable,
        handleRefreshDraw,
        handleSetSearchModelVal,
        handleOpenDraw,
        handleRefreshTable,
        handleItemChange,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .close-btn {
    position: absolute;
    top: 153px;
    left: -40px;
    z-index: 1;
    height: 50px;
    width: 40px;
    font-size: 25px;
  }
</style>
